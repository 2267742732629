import React from 'react';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tooltip,
  IconButton,
} from '@mui/material';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead } from '../sections/@dashboard/user';

const TABLE_HEAD = [
  { id: 'id', label: 'Project Id', alignRight: false },
  { id: 'name', label: 'Project Name', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

export default function Projects({ projects, projectID, handleProjectChange}) {
  return (
    <Container>
      <Card>
        <Scrollbar>
          <TableContainer>
            <Table>
              <UserListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {projects.map((x) => (
                  <TableRow key={x.id} hover tabIndex={-1} selected={x.id == projectID}>
                    <TableCell align="left">
                      {x.id}
                      <Tooltip title='Copy' placement="top">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(`${x.id}`);
                          }}
                        >
                          <Iconify icon="clarity:copy-solid" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">
                      {x.name}
                    </TableCell><TableCell>
                      <div style={{ display: 'flex' }}>
                        {x.id != projectID && (
                          <Button onClick={() => handleProjectChange(x.id)} sx={{ pl: 0 }} variant="text">
                            Switch to this project
                          </Button>
                        )}
                        {x.id == projectID && (
                          <Button disabled sx={{ pl: 0 }} variant="text">
                            Currently selected
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}
