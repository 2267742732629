import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Button,
  Alert,
  Typography,
  FormControl,
  Container,
  CircularProgress,
  Box,
  Stack,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Tooltip,
  IconButton,
} from '@mui/material';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionIcon from '@mui/icons-material/Help';
import { getDnsRecords } from '../utils/utils';
import { SubscriptionPlansIds } from '../utils/sphere';

export default function MyRpcs({
  allowedDomains,
  allowedIPs,
  allowedCIDRs,
  apiKey,
  endpoint,
  setAllowedCIDRs,
  setAllowedDomains,
  setAllowedIPs,
  projectID,
  projectsList,
  billingPlan,
}) {
  if (apiKey == undefined) {
    return 'Please go to the home page and generate an API key';
  }
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState('');
  const [dialogValue, setDialogValue] = useState([]);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [dashboardURL, setDashboardURL] = useState(null);
  const [dnsRecords, setDnsRecords] = useState(() => getDnsRecords(projectsList));

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const addField = () => {
    setDialogValue([...dialogValue, '']);
  };

  const removeField = (index) => {
    let data = [...dialogValue];
    data.splice(index, 1);
    setDialogValue(data);
  };

  const handleFormChange = (index, event) => {
    let data = [...dialogValue];
    data[index] = event?.target?.value;
    setDialogValue(data);
  };

  useEffect(() => {
    const fetchDashboard = async () => {
      let url = `https://${process.env.REACT_APP_ADMIN_WORKER_DOMAIN}/dashboard/project/${localStorage.getItem(
        'helius-project',
      )}`;
      const config = {
        headers: {
          'X-Api-Key': localStorage.getItem('last-api-key'),
        },
      };
      try {
        const { data } = await axios.get(url, config);
        setDashboardURL(data?.dashboardUrl);
      } catch (e) {
        console.error(e);
      }
    };
    fetchDashboard();
  }, [label]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let overrideLabel;

      const payload = {
        allowCIDR: allowedCIDRs,
        allowIP: allowedIPs,
        allowDomain: allowedDomains,
      };

      if (label == 'Allowed IP') {
        overrideLabel = 'allowIP';
      } else if (label == 'Allowed CIDR') {
        overrideLabel = 'allowCIDR';
      } else if (label == 'Allowed Domain') {
        overrideLabel = 'allowDomain';
      }

      const actualPayload = { ...payload, [overrideLabel]: dialogValue };

      await axios.patch(
        `${endpoint}/projects/${projectID}/keys/${apiKey}`,
        { ...actualPayload },
        { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } },
      );

      if (label == 'Allowed IP') {
        setAllowedIPs(dialogValue);
      } else if (label == 'Allowed CIDR') {
        setAllowedCIDRs(dialogValue);
      } else if (label == 'Allowed Domain') {
        setAllowedDomains(dialogValue);
      }
    } catch (e) {
      setErrMsg(
        `Something went wrong! Please try again or contact us on Discord for urgent support. Error: ${e?.response?.data}`,
      );
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const handleOpen = (type) => {
    setErrMsg(null);

    if (type == 'ip') {
      setLabel('Allowed IP');
      setDialogValue(allowedIPs);
    } else if (type == 'cidr') {
      setLabel('Allowed CIDR');
      setDialogValue(allowedCIDRs);
    } else if (type == 'domain') {
      setLabel('Allowed Domain');
      setDialogValue(allowedDomains);
    } else {
      console.log('this shouldnt happen');
    }

    setOpen(true);
  };
  const handleClose = () => {
    setErrMsg(null);
    setOpen(false);
  };

  const showGeyserWebsocket = (isMainNet) => {
    switch (billingPlan) {
      case SubscriptionPlansIds.Free:
      case SubscriptionPlansIds.DeveloperV4:
        return <>Upgrade to Business plan or above to enable the Geyser WebSocket URL</>;
      default:
        return (
          <>
            {(isMainNet ? 'wss://atlas-mainnet' : 'wss://atlas-devnet') +
              `.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`}
          </>
        );
    }
  };

  return (
    <Page title="RPCs">
      <Container maxWidth="xl">
        <Typography variant="h4" paragraph gutterBottom sx={{ mb: 2 }}>
          RPC Control Panel
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Manage your RPC access, keys, and monitoring here. Please contact us on{' '}
          <a href="https://discord.gg/HjummjUXgq">Discord</a> for support.
        </Typography>

        <Grid item xs={12} md={12} lg={12} sx={{ mb: 5 }}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h5" gutterBottom>
              Helius RPC URLs
            </Typography>

            {dnsRecords[projectID] && (
              <Typography sx={{ color: 'text.secondary' }} gutterBottom>
                General purpose URLs are regular RPC URLs that work for all cases. SecureRPC URLs are specifically
                masked and IP rate-limited at 5 TPS, so you can use them directly from your frontend applications —
                without worrying about people draining your credits.
              </Typography>
            )}
            <Typography variant="h6" gutterBottom>
              Mainnet
            </Typography>
            <Stack direction="row" sx={{ border: '1px #E84125 solid', padding: 2, borderRadius: '10px', mb: 3 }}>
              <Typography variant="subtitle1">
                <Stack spacing={1} direction="column">
                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>RPC URL:</strong>&nbsp; https://mainnet.{process.env.REACT_APP_RPC_DOMAIN}/?api-key={apiKey}
                  </Box>
                  {dnsRecords[projectID] && dnsRecords[projectID].some((record) => record.network == 'mainnet') && (
                    <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                      <strong>Secure RPC URL:</strong>&nbsp;
                      <span>https://{dnsRecords[projectID].find((record) => record.network == 'mainnet').dns}</span>
                      <Tooltip
                        arrow
                        sx={{ mt: -0.5 }}
                        title="This url hides your API key and is limited to 5 tps per IP. This is ideal for using in frontend applications where you don’t want to worry about leaking your API key"
                      >
                        <IconButton sx={{ p: 0, ml: 1 }}>
                          <QuestionIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>WebSocket URL:</strong>&nbsp; wss://mainnet.{process.env.REACT_APP_RPC_DOMAIN}/?api-key=
                    {apiKey}
                  </Box>
                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>Geyser WebSocket URL:</strong>&nbsp;
                    {showGeyserWebsocket(true)}
                  </Box>
                </Stack>
              </Typography>
              <Stack spacing={1} direction="column" sx={{ ml: 2 }}>
                <Button
                  size="small"
                  sx={{ lineHeight: '24px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://mainnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                    );
                  }}
                  variant="contained"
                  to="#"
                  startIcon={<Iconify icon="clarity:copy-solid" />}
                >
                  Copy
                </Button>
                {dnsRecords[projectID] && dnsRecords[projectID].some((record) => record.network == 'mainnet') && (
                  <Button
                    size="small"
                    sx={{ lineHeight: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://${dnsRecords[projectID].find((record) => record.network == 'mainnet').dns}`,
                      );
                    }}
                    variant="contained"
                    to="#"
                    startIcon={<Iconify icon="clarity:copy-solid" />}
                  >
                    Copy
                  </Button>
                )}
                <Button
                  size="small"
                  sx={{ lineHeight: '24px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `wss://mainnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                    );
                  }}
                  variant="contained"
                  to="#"
                  startIcon={<Iconify icon="clarity:copy-solid" />}
                >
                  Copy
                </Button>
                {billingPlan != SubscriptionPlansIds.Free && (
                  <Button
                    size="small"
                    sx={{ lineHeight: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `wss://atlas-mainnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                      );
                    }}
                    variant="contained"
                    to="#"
                    startIcon={<Iconify icon="clarity:copy-solid" />}
                  >
                    Copy
                  </Button>
                )}
              </Stack>
            </Stack>
            <Typography variant="h6" gutterBottom>
              Devnet
            </Typography>
            <Stack direction="row" sx={{ border: '1px #E84125 solid', padding: 2, borderRadius: '10px' }}>
              <Typography variant="subtitle1">
                <Stack spacing={1} direction="column">
                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>RPC URL:</strong>&nbsp; https://devnet.{process.env.REACT_APP_RPC_DOMAIN}/?api-key={apiKey}
                  </Box>
                  {dnsRecords[projectID] && dnsRecords[projectID].some((record) => record.network == 'devnet') && (
                    <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                      <strong>Secure RPC URL:</strong>&nbsp;
                      <span>https://{dnsRecords[projectID].find((record) => record.network == 'devnet').dns}</span>
                      <Tooltip
                        arrow
                        sx={{ mt: -0.5 }}
                        title="This url hides your API key and is limited to 5 tps per IP. This is ideal for using in frontend applications where you don’t want to worry about leaking your API key"
                      >
                        <IconButton sx={{ p: 0, ml: 1 }}>
                          <QuestionIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>WebSocket URL:</strong>&nbsp; wss://devnet.{process.env.REACT_APP_RPC_DOMAIN}/?api-key=
                    {apiKey}
                  </Box>

                  <Box sx={{ p: 0.5, display: 'flex', alignItems: 'center' }}>
                    <strong>Geyser WebSocket URL:</strong>&nbsp;
                    {showGeyserWebsocket(false)}
                  </Box>
                </Stack>
              </Typography>

              <Stack spacing={1} direction="column" sx={{ ml: 2 }}>
                <Button
                  size="small"
                  sx={{ lineHeight: '24px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://devnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                    );
                  }}
                  variant="contained"
                  to="#"
                  startIcon={<Iconify icon="clarity:copy-solid" />}
                >
                  Copy
                </Button>
                {dnsRecords[projectID] && dnsRecords[projectID].some((record) => record.network == 'devnet') && (
                  <Button
                    size="small"
                    sx={{ lineHeight: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `https://${dnsRecords[projectID].find((record) => record.network == 'devnet').dns}`,
                      );
                    }}
                    variant="contained"
                    to="#"
                    startIcon={<Iconify icon="clarity:copy-solid" />}
                  >
                    Copy
                  </Button>
                )}
                <Button
                  size="small"
                  sx={{ lineHeight: '24px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `wss://devnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                    );
                  }}
                  variant="contained"
                  to="#"
                  startIcon={<Iconify icon="clarity:copy-solid" />}
                >
                  Copy
                </Button>
                {billingPlan != SubscriptionPlansIds.Free && (
                  <Button
                    size="small"
                    sx={{ lineHeight: '24px' }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `wss://atlas-devnet.${process.env.REACT_APP_RPC_DOMAIN}/?api-key=${apiKey}`,
                      );
                    }}
                    variant="contained"
                    to="#"
                    startIcon={<Iconify icon="clarity:copy-solid" />}
                  >
                    Copy
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Grid>

        {dashboardURL != null && (
          <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Metrics
            </Typography>
            <Typography sx={{ color: 'text.secondary', mb: 2 }}>
              To view your dashboard in <strong>full screen</strong>, click{' '}
              <a target="_blank" href={dashboardURL}>
                here
              </a>
              .
            </Typography>
            {!iframeLoaded && <div>Loading...</div>}
            <iframe
              onLoad={handleIframeLoad}
              style={{
                display: iframeLoaded ? 'block' : 'none', // Hide iframe while loading
                marginLeft: '-30px',
              }}
              src={dashboardURL}
              width="1200"
              height="1600"
              allowFullScreen
              frameBorder="0"
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
          <Typography variant="h5">RPC Access Control Rules</Typography>
          <Typography sx={{ color: 'text.secondary' }}>Set access rules for your RPC.</Typography>

          <Stack direction="row">
            <Typography variant="h6" sx={{ mt: 2 }}>
              Allowed Domains
            </Typography>
            <Button size="small" sx={{ mt: 2 }} onClick={() => handleOpen('domain')}>
              Edit
            </Button>
          </Stack>
          <Typography sx={{ color: 'text.secondary' }}>
            Setting up allowed domains allows you to restrict access for your RPCs to the domains that you enter here.
          </Typography>
          {allowedDomains != undefined && allowedDomains?.length != 0 && (
            <Box sx={{ border: '1px red solid', padding: 2, mt: 2, borderRadius: '10px' }}>
              {allowedDomains?.join(', ')}
            </Box>
          )}

          <Stack direction="row">
            <Typography variant="h6" sx={{ mt: 2 }}>
              Allowed IPs
            </Typography>
            <Button size="small" sx={{ mt: 2 }} onClick={() => handleOpen('ip')}>
              Edit
            </Button>
          </Stack>
          <Typography sx={{ color: 'text.secondary' }}>
            Setting up allowed IPs allows you to restrict access for your RPCs to the IP Addresses that you enter here.
          </Typography>
          {allowedIPs != undefined && allowedIPs?.length != 0 && (
            <Box sx={{ border: '1px red solid', padding: 2, mt: 2, borderRadius: '10px' }}>
              {allowedIPs?.join(', ')}
            </Box>
          )}

          <Stack direction="row">
            <Typography variant="h6" sx={{ mt: 2 }}>
              Allowed CIDRs
            </Typography>
            <Button size="small" sx={{ mt: 2 }} onClick={() => handleOpen('cidr')}>
              Edit
            </Button>
          </Stack>
          <Typography sx={{ color: 'text.secondary' }}>
            Setting up allowed CIDRs allows you to restrict access for your RPCs to the CIDR-notation IPs that you enter
            here.
          </Typography>
          {allowedCIDRs != undefined && allowedCIDRs?.length != 0 && (
            <Box sx={{ border: '1px red solid', padding: 2, mt: 2, borderRadius: '10px' }}>
              {allowedCIDRs?.join(', ')}
            </Box>
          )}
        </Grid>

        <Dialog fullWidth open={open} onClose={handleClose}>
          <DialogTitle sx={{ mb: 2 }}>Allowed {label.split(' ')[1]}s</DialogTitle>
          {errMsg && <Alert severity="error">{errMsg}</Alert>}
          <DialogContent>
            {dialogValue.map((v, i) => {
              return (
                <Stack key={i} direction="row" sx={{ mt: 1 }}>
                  <FormControl fullWidth required>
                    <TextField
                      sx={{ minWidth: '100%', mt: -1 }}
                      label={label.split(' ')[1]}
                      value={v}
                      onChange={(e) => handleFormChange(i, e)}
                      required
                      type="text"
                      fullWidth
                      variant="standard"
                    />
                  </FormControl>
                  <Button sx={{ mt: 2 }} onClick={() => removeField(i)} endIcon={<DeleteIcon />}></Button>
                </Stack>
              );
            })}

            <Button sx={{ mt: 4 }} onClick={addField} variant="contained" endIcon={<AddIcon />}>
              Add {label.split(' ')[1]}
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>
              {isLoading && <CircularProgress />}
              {!isLoading && 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
