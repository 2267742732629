import { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { useOutletContext } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';
import Page from '../components/Page';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function WebhookLogs({ endpoint, userID }) {
  const [isLoading, setLoading] = useState(true);
  const [alignment, setAlignment] = useState('5m');
  const [logs, setLogs] = useState([]);
  const { id } = useParams();
  const [error, setError] = useState(null);

  const fetchLogs = async (startUnix, endUnix) => {
    try {
      setError(null);
      const { data } = await axios.post(
        `${endpoint}/logs`,
        {
          webhookId: id,
          walletId: userID,
          startTime: startUnix,
          endTime: endUnix,
        },
        { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } }
      );
      let { data: resp } = await axios.post(
        `${endpoint}/logsresult`,
        { queryId: data.queryId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } }
      );
      while (resp?.status == 'Running' || resp?.status == 'Scheduled') {
        const { data: resp2 } = await axios.post(
          `${endpoint}/logsresult`,
          { queryId: data.queryId },
          { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } }
        );
        resp = resp2;
      }
      setLogs(resp.results);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (_, newVal) => {
    setLoading(true);
    setAlignment(newVal);
    const now = DateTime.now();

    if (newVal == '5m') {
      fetchLogs(now.minus({ minutes: 5 }).toMillis(), now.toMillis());
    } else if (newVal == '30m') {
      fetchLogs(now.minus({ minutes: 30 }).toMillis(), now.toMillis());
    } else if (newVal == '60m') {
      fetchLogs(now.minus({ minutes: 120 }).toMillis(), now.toMillis());
    } else {
      setError(e);
    }
  };

  useEffect(() => {
    if (userID != undefined) {
      const now = DateTime.now();
      fetchLogs(now.minus({ minutes: 5 }).toMillis(), now.toMillis());
    }
  }, []);

  if (isLoading) {
    return (
      <Page title="Webhook Logs">
        <Container>
          <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </ContentStyle>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Webhook Logs">
      <Container>
        <Typography variant="h4">Webhooks Logs</Typography>
        <Typography color="text.secondary" variant="subtitle2" sx={{ mb: 2 }}>
          Note: we currently only show logs for failed webhook posts.
        </Typography>

        {error != null && (
          <Alert severity="error">
            <b>{error}</b>
          </Alert>
        )}

        <>
          <ToggleButtonGroup exclusive value={alignment} onChange={handleChange}>
            <ToggleButton value="5m">5m</ToggleButton>
            <ToggleButton value="30m">30m</ToggleButton>
            <ToggleButton value="60m">60m</ToggleButton>
          </ToggleButtonGroup>
          <TableContainer component={Paper}>
            <TableHead>
              <TableRow>
                <TableCell>Timestamp</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.length == 0 && (
                <Typography sx={{ ml: 2 }} color="text.secondary">
                  No logs found for this webhook or time range.
                </Typography>
              )}

              {logs.map((x, i) => (
                <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {x.timestamp}
                  </TableCell>
                  <TableCell align="right">{x.message}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        </>

        {/* <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button sx={{ margin: 2 }}>Load More Logs</Button>
                </Box> */}
      </Container>
    </Page>
  );
}
