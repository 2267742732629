import { Typography, Card, CardContent, CardActionArea, Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

export default function WaveCard() {
  return (
    <Card>
      <CardActionArea sx={{ height: '100%' }}>
        <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
      </CardActionArea>
    </Card>
  );
}
