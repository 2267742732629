// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

export const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:home-fill'),
    gated: false,
    hidden: false,
  },
  {
    title: 'Webhooks',
    path: '/webhooks/my',
    icon: getIcon('eva:flash-fill'),
    gated: true,
    hidden: false,
  },
  {
    title: 'RPCs',
    path: '/rpcs/my',
    icon: getIcon('eva:hard-drive-fill'),
    gated: true,
    hidden: false,
  },
  {
    title: 'Dedicated Nodes',
    path: '/dedicated-nodes/my',
    icon: getIcon('solar:server-bold'),
    gated: true,
    hidden: false,
  },
  {
    title: 'Usage',
    path: '/usage/my',
    icon: getIcon('mdi:chart-line'),
    gated: true,
    hidden: false,
  },
  // {
  //   title: 'Prepaid Credits',
  //   path: '/prepaid-credits',
  //   icon: getIcon('ion:card-outline'),
  //   gated: true,
  //   hidden: false,
  // },
  {
    title: 'Status Page',
    path: 'https://helius.statuspage.io/#',
    icon: getIcon('eva:external-link-fill'),
    gated: false,
    hidden: false,
  },
  // {
  //   title: 'Web Sockets (coming soon)',
  //   path: '/websockets/main',
  //   icon: getIcon('eva:activity-fill'),
  //   gated: true,
  //   hidden: false,
  // }
];

export default navConfig;
