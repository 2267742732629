export const auth0Config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/dashboard/app`,
};

export const LoginTypes = {
  None: 'none',
  Auth0: 'auth0',
  Wallet: 'wallet',
};

export const UserRoles = {
  Owner: 'Owner',
  Admin: 'Admin',
  ReadOnly: 'ReadOnly',
};

export const hasAccess = (projectsList = [], projectID, userID) => {
  const project = projectsList.find((proj) => proj.id === projectID);
  if (project && project.users) {
    const user = project.users.find((usr) => usr.id === userID);
    if (user && user.role !== UserRoles.ReadOnly) {
      return true;
    }
  }
  return false;
};

export const getDnsRecords = (projectsList) => {
  const result = projectsList.reduce((acc, item) => {
    item.dnsRecords.forEach((record) => {
      if (!acc[record.projectId]) {
        acc[record.projectId] = [];
      }
      acc[record.projectId].push(record);
    });
    return acc;
  }, {});
  return result;
};

export const DRAWER_WIDTH = 220;



export const ignoreCryptoError = (error) => {
  if(error?.error?.message) {
    const EXCLUDE_ERRORS = ['Approval Denied', 'Transaction cancelled', 'You canceled the transaction', 'User rejected the request']
    return EXCLUDE_ERRORS.some(err => error.error.message.includes(err));
  }
  return false
}

export const delayExecution = (ms) => new Promise((res) => setTimeout(res, ms));
