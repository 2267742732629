import { useState, useEffect } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebar from '../dashboard/DashboardSidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function WebhookLayout({
  endpoint,
  publicKey,
  isLoading,
  setLoading,
  apiKey,
  apiKeys,
  billingPlan,
  billingRange,
  webhookUsage,
  open,
  billingStart,
  setOpen,
  setCurrentApiKey,
  loginType,
  onLogout,
  projectID,
  projectsList,
  setCurrentProject,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
}) {
  const [webhooks, setWebhooks] = useState(null);

  useEffect(() => {
    if (publicKey != null && apiKey != undefined) {
      fetchWebhooks();
    }
  }, [publicKey, apiKey]);

  async function fetchWebhooks() {
    try {
      const { data } = await axios.get(`${endpoint}/projects/${projectID}/webhooks`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
      setWebhooks(data);
    } catch (e) {
      console.error('Failed to fetch webhooks');
    }
  }

  const handleDelete = async (webhookID) => {
    setLoading(true);
    try {
      await axios.delete(`${endpoint}/webhooks/${webhookID}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
    } catch (e) {
      console.error(e);
    } finally {
      fetchWebhooks();
      setLoading(false);
    }
  };

  const handleTest = async (webhookID) => {
    setLoading(true);
    try {
      await axios.post(`${endpoint}/webhooks/${webhookID}/test`, null, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
    } catch (e) {
      console.error(e);
    } finally {
      fetchWebhooks();
      setLoading(false);
    }
  };

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        loginType={loginType}
        onLogout={onLogout}
        projectID={projectID}
        projectsList={projectsList}
        setCurrentProject={setCurrentProject}
      />
      {apiKey != undefined && publicKey != undefined && (
        <DashboardSidebar
          apiKey={apiKey}
          billingStart={billingStart}
          setCurrentApiKey={setCurrentApiKey}
          apiKeys={apiKeys}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          userID={publicKey}
          projectID={projectID}
          projectsList={projectsList}
          refetchAllProjects={refetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          endpoint={endpoint}
          setCurrentProject={setCurrentProject}
        />
      )}
      <MainStyle>
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <Outlet
            context={{
              endpoint,
              apiKey,
              publicKey,
              billingPlan,
              webhooks,
              setWebhooks,
              handleDelete,
              handleTest,
              billingRange,
              webhookUsage,
            }}
          />
        )}
      </MainStyle>
    </RootStyle>
  );
}
