import { useState, useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Container,
  Grid,
  Typography,
  Box,
  Stack,
  Tooltip,
  Alert,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Backdrop,
  Skeleton,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SelectCard from '../components/SelectCard';
import WaveCard from '../components/WaveCard';
import Page from '../components/Page';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import QuestionIcon from '@mui/icons-material/Help';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Flag from 'react-world-flags';
import axios from 'axios';
import { delayExecution } from '../utils/utils';

const productIcons = {
  'metal-7543p-1000gb': <StorageRoundedIcon color="primary" />,
  'metal-9254-768gb': <StorageRoundedIcon color="primary" />,
  'metal-7443p-256gb': <DnsRoundedIcon color="primary" />,
};

const solanaClients = [
  {
    id: 'solana-labs',
    name: 'Solana Labs',
    description:
      'Original Solana validator management client, developed by Solana Labs for efficient network operations.',
  },
  {
    id: 'jito',
    name: 'Jito Labs',
    description: "Fork of Solana's original client by Jito Labs, enhances validator rewards and network efficiency.",
  },
];

const plugins = [
  {
    id: 'none',
    name: 'None',
    description: 'Choose this option if you only need RPC features',
    price: 'Free',
  },
  {
    id: 'yellowstone-grpc',
    name: 'Yellowstone gRPC',
    description: 'A gRPC service for streaming slots, blocks, transactions, and account update notifications',
    price: 'Free',
  },
  {
    id: 'jito-grpc',
    name: 'Jito gRPC',
    description: 'A lightweight gRPC service for streaming account and slot updates to subscribers',
    price: 'Free',
  },
  // {
  //   id: 'custom',
  //   name: 'Custom plugin',
  //   description: 'Use your own custom Geyser plugin.',
  //   price: '+$300/month',
  // },
];

const paymentProviders = [
  {
    id: 'sphere',
    name: 'Crypto (USDC)',
    description: 'Pay with crypto',
    company: 'Sphere',
  },
  {
    id: 'stripe',
    name: 'Fiat',
    description: 'Pay with credit card',
    company: 'Stripe',
  },
];

export default function NewDedicatedNode({ projectID }) {
  const { endpoint, apiKey } = useOutletContext();

  const [open, setOpen] = useState(false);
  const [locations, setLocations] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLocations, setIsLoadingLocations] = useState(false);
  const [productConfigs, setProductConfigs] = useState({});
  const [searchParams, setSearchParams] = useSearchParams({
    type: null,
    solanaClient: 'solana-labs',
    location: null,
    plugin: 'none',
    payment: 'sphere',
  });
  const [checked, setChecked] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {}, []);

  const getProductConfigs = async () => {
    try {
      setIsLoadingLocations(true);
      let { data } = await axios.get(`${endpoint}/dedicated-node-configs`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });

      if (data) {
        setProductConfigs(data);
      }
    } catch (e) {
    } finally {
      setIsLoadingLocations(false);
    }
  };

  useEffect(() => {
    if (Object.keys(productConfigs).length > 0) {
      fetchLocations();
    }
  }, [productConfigs]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getLocation = (locations, region_code) => {
    if (searchParams.get('type') in locations) {
      return locations[searchParams.get('type')].find((location) => location.region_code === region_code);
    }

    return null;
  };

  const setFirstLocation = (data) => {
    if (data) {
      let type = searchParams.get('type');
      if (type === 'null' || !data[type]?.length) {
        type = productConfigs.hostTypes?.find((obj) => data[obj.id]?.length)?.id;
      }
      let firstLocation = null;

      if (data[type]) {
        // Select first item in the locations
        firstLocation = data[type][0];
      }

      if (firstLocation) {
        searchParams.set('location', firstLocation.region_code);
        searchParams.set('type', type);
        setSearchParams(searchParams);
        setCount(firstLocation.count);
      }
    }
  };

  const handleTypeChange = (type) => (e) => {
    searchParams.set('type', type);
    setSearchParams(searchParams);
    setFirstLocation(locations);
  };

  const typeProps = {
    selectedId: searchParams.get('type'),
    onChange: handleTypeChange,
  };

  const handleSolanaClientChange = (value) => (e) => {
    searchParams.set('solanaClient', value);
    setSearchParams(searchParams);
  };

  const solanaClientProps = {
    selectedId: searchParams.get('solanaClient'),
    onChange: handleSolanaClientChange,
  };

  const handleLocationChange = (region_code) => (e) => {
    searchParams.set('location', region_code);
    setSearchParams(searchParams);

    const location = getLocation(locations, region_code);
    setCount(location.count);
  };

  const locationProps = {
    selectedId: searchParams.get('location'),
    onChange: handleLocationChange,
  };

  const handlePluginChange = (value) => (e) => {
    searchParams.set('plugin', value);
    setSearchParams(searchParams);
  };

  const pluginProps = {
    selectedId: searchParams.get('plugin'),
    onChange: handlePluginChange,
  };

  const handlePaymentChange = (value) => (e) => {
    searchParams.set('payment', value);
    setSearchParams(searchParams);
  };

  const paymentProps = {
    selectedId: searchParams.get('payment'),
    onChange: handlePaymentChange,
  };

  const handleOrderNow = async (e) => {
    setOpen(false);
    setIsLoading(true);
    let checkoutEndpoint = `${endpoint}/dedicated-node-stripe-checkout`;
    if (searchParams.get('payment') === 'sphere') {
      checkoutEndpoint = `${endpoint}/dedicated-node-sphere-payment-link`;
    }

    const { data } = await axios
      .post(
        checkoutEndpoint,
        {
          projectId: projectID,
          metal_type: productConfigs.hostTypes?.find((type) => type.id === searchParams.get('type')).id,
          solanaType: searchParams.get('solanaClient'),
          location: searchParams.get('location'),
          plugin: searchParams.get('plugin'),
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
        },
      )
      .catch((error) => {
        // TODO display error
        console.error('Error with fetching ..., details: ', error);
        setIsLoading(false);
      });

    if (data && data.url) {
      window.location.replace(data.url);
    } else {
      // TODO display error
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    searchParams.set('plugin', 'none');
    setSearchParams(searchParams);
  };

  const sortLocations = (a, b) => {
    return b.count - a.count || b.country.localeCompare(a.country) || a.city.localeCompare(b.city);
  };

  const removeItemsWithZeroCount = (data) => {
    return data.filter((item) => item.count !== 0);
  };

  // initialize an AbortController instance
  const abortController = new AbortController();

  const fetchLocations = async () => {
    let retry = 3;
    while (retry) {
      setIsLoadingLocations(true);
      try {
        let { data } = await axios.get(`${endpoint}/dedicated-node-inventory`, {
          signal: abortController.signal,
          headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
        });

        let locationsData = {};

        if (data) {
          Object.keys(data).forEach((k) => {
            if (k !== 'metal-9254-768gb') {
              locationsData[k] = removeItemsWithZeroCount(data[k]).sort(sortLocations);
            } else {
              locationsData[k] = data[k].sort(sortLocations);
            }
          });

          setLocations(locationsData);
          setFirstLocation(locationsData);
        }
        retry = 0;
      } catch (e) {
        if (axios.isCancel(e)) {
          retry = 0;
          // Request canceled by unmount no need to log error.
        } else if (e?.code === 'ERR_NETWORK') {
          await delayExecution(5000);
          retry--;
        } else {
          retry = 0;
        }
      }
      setIsLoadingLocations(false);
    }
  };

  useEffect(() => {
    getProductConfigs();
    return () => {
      // Abort the fetchLocation request on unmount
      abortController.abort();
    };
  }, []);

  if (apiKey == undefined) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid sx={{ mt: -30 }} item xs={3}>
          <Typography variant="h6">Sign in to start building with Helius.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Page title="Dedicated Nodes">
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Node Policies</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            Our policy does not allow for refunds on cancelled nodes as we place the order to external vendors on your
            behalf. Nodes might take anywhere from a few minutes to 14 days to be provisioned depending on global node
            availability. You can always reach out to us 24/7 on{' '}
            <a href="https://discord.gg/HjummjUXgq" target="_blank">
              Discord
            </a>
            .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOrderNow} autoFocus sx={{ textTransform: 'none' }}>
            I understand, proceed to order
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={() => {
          setChecked(false);
        }}
        open={
          checked &&
          productConfigs.hostTypes?.find((type) => type.id === searchParams.get('type')).resourceIntensive === false
        }
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Resource-intensive RPC calls
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            To use any of the following resource-intensive RPC calls, you will need a node with{' '}
            <strong>at least 512GB of RAM</strong>:
          </Typography>
          <List dense={true}>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary="getProgramAccounts"
                sx={{ my: 0.2 }}
                primaryTypographyProps={{
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary="getTokenLargestAccounts"
                sx={{ my: 0.2 }}
                primaryTypographyProps={{
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary="getTokenAccountsByDelegate"
                sx={{ my: 0.2 }}
                primaryTypographyProps={{
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary="getTokenAccountsByOwner"
                sx={{ my: 0.2 }}
                primaryTypographyProps={{
                  letterSpacing: 0,
                }}
              />
            </ListItem>
            <ListItem sx={{ p: 0 }}>
              <ListItemText
                primary="getLargestAccounts"
                sx={{ my: 0.2 }}
                primaryTypographyProps={{
                  letterSpacing: 0,
                }}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setChecked(false);
            }}
          >
            I understand
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={handleClose} open={searchParams.get('plugin') === 'custom'}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Custom plugin
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            We require additional information about your plugin before we can continue. Please submit the form below,
            and we will contact you shortly.
          </Typography>
          <Button
            target="_blank"
            rel="noopener noreferrer"
            href={`https://w2hh5e4o0ga.typeform.com/geyser-plugin#project_id=${projectID}&type=${searchParams.get(
              'type',
            )}&location=${searchParams.get('location')}&solana_client=${searchParams.get('solanaClient')}`}
          >
            Request Custom Plugin
          </Button>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          New Dedicated Node
        </Typography>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Choose Type
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            mb: 2,
            gap: 3,
          }}
        >
          {isLoadingLocations || Object.keys(locations).length === 0 ? (
            <WaveCard />
          ) : (
            productConfigs.hostTypes?.map((type) => (
              <SelectCard
                {...typeProps}
                id={type.id}
                key={`type_${type.id}`}
                title={type.name}
                description={type.description}
                label={`$${type.price}/month`}
                icon={productIcons[type.id] ?? <StorageRoundedIcon color="primary" />}
                hidden={!(locations[type.id]?.length > 0)}
              />
            ))
          )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
            mb: 6,
          }}
        >
          <Box>
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label="Enable resource-intensive RPC calls such as getProgramAccounts"
            />
            <Tooltip
              arrow
              sx={{ mt: -0.5 }}
              title={
                <>
                  <Typography variant="subtitle1" component="div">
                    To use any of the following resource-intensive RPC calls, you will need a node with at least 512GB
                    of RAM:
                  </Typography>
                  <List dense={true}>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="getProgramAccounts"
                        sx={{ my: 0.2 }}
                        primaryTypographyProps={{
                          fontSize: 13,
                          letterSpacing: 0,
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="getTokenLargestAccounts"
                        sx={{ my: 0.2 }}
                        primaryTypographyProps={{
                          fontSize: 13,
                          letterSpacing: 0,
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="getTokenAccountsByDelegate"
                        sx={{ my: 0.2 }}
                        primaryTypographyProps={{
                          fontSize: 13,
                          letterSpacing: 0,
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="getTokenAccountsByOwner"
                        sx={{ my: 0.2 }}
                        primaryTypographyProps={{
                          fontSize: 13,
                          letterSpacing: 0,
                        }}
                      />
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <ListItemText
                        primary="getLargestAccounts"
                        sx={{ my: 0.2 }}
                        primaryTypographyProps={{
                          fontSize: 13,
                          letterSpacing: 0,
                        }}
                      />
                    </ListItem>
                  </List>
                </>
              }
            >
              <IconButton>
                <QuestionIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Choose Location
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            mb: 2,
            gap: 3,
          }}
        >
          {!isLoadingLocations &&
            locations[searchParams.get('type')]?.map((location) => (
              <SelectCard
                {...locationProps}
                id={`${location.region_code}`}
                key={`location_${location.region_code}`}
                title={location.city}
                description={`Availabilty: ${location.count === 0 ? 'ETA 4-14 days' : 'Now'}`}
                label={location.country}
                icon={<Flag code={location.country_code} width={40} />}
                type="small"
              />
            ))}

          {isLoadingLocations && (
            <>
              <Skeleton variant="rounded" width="100%" height={90} />
              <Skeleton variant="rounded" width="100%" height={90} />
              <Skeleton variant="rounded" width="100%" height={90} />
              <Skeleton variant="rounded" width="100%" height={90} />
              <Skeleton variant="rounded" width="100%" height={90} />
              <Skeleton variant="rounded" width="100%" height={90} />
            </>
          )}
        </Box>
        {!isLoadingLocations && (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
              },
              mb: 6,
            }}
          >
            {!locations[searchParams.get('type')] || locations[searchParams.get('type')].length === 0 ? (
              <Typography variant="body1" sx={{ mb: 2 }}>
                There are no dedicated nodes available at this time. Please try again later or select another type.
              </Typography>
            ) : (
              <>
                {count === 0 && (
                  <Alert severity="info">
                    This location is out of stock. Purchase the node today, and it will be provisioned within a maximum
                    of 4-14 days. You will be credited for the wait period once the node is available.
                  </Alert>
                )}
              </>
            )}
          </Box>
        )}

        <Typography variant="h5" sx={{ mb: 2 }}>
          Choose Solana Client
          <Tooltip
            arrow
            sx={{ mt: -0.5 }}
            title="
            Solana clients are software tools that allow interactions with the Solana blockchain for transactions and smart contracts. Developed by various entities, they enhance network performance, security, and diversity. Each offering distinctive features."
          >
            <IconButton>
              <QuestionIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            mb: 6,
            gap: 3,
          }}
        >
          {solanaClients.map((client) => (
            <SelectCard
              {...solanaClientProps}
              id={client.id}
              key={`SelectCard_${client.id}`}
              title={client.name}
              description={client.description}
              label={client.price}
              type="small"
            />
          ))}
        </Box>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Geyser Plugin (optional){' '}
          <Tooltip
            arrow
            sx={{ mt: -0.5 }}
            title="Geyser Plugins are modular components designed to transmit data about accounts, slots, blocks, and transactions to external data stores."
          >
            <IconButton>
              <QuestionIcon />
            </IconButton>
          </Tooltip>
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            mb: 2,
            gap: 3,
          }}
        >
          {plugins.map((plugin) => (
            <SelectCard
              {...pluginProps}
              id={plugin.id}
              key={`plugin_${plugin.id}`}
              title={plugin.name}
              description={plugin.description}
              label={plugin.price}
              type="small"
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
            mb: 6,
          }}
        >
          {searchParams.get('plugin') !== 'none' && searchParams.get('plugin') !== 'custom' && (
            <Alert severity="info">
              For optimal performance, we recommend using separate Dedicated Nodes for Geyser-related tasks and RPC
              operations. These can be ordered individually.
            </Alert>
          )}
        </Box>

        <Typography variant="h5" sx={{ mb: 2 }}>
          Choose Payment
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              xl: 'repeat(4, 1fr)',
            },
            mb: 2,
            gap: 3,
          }}
        >
          {paymentProviders.map((provider) => (
            <SelectCard
              {...paymentProps}
              id={provider.id}
              key={`provider_${provider.id}`}
              title={provider.name}
              description={provider.description}
              label={provider.company}
              type="small"
            />
          ))}
        </Box>

        <Stack direction="row" alignItems="center" justifyContent="start" mt={7} sx={{ gap: 2 }}>
          <LoadingButton
            variant="contained"
            size="large"
            onClick={() => {
              setOpen(true);
            }}
            loading={isLoading}
            disabled={isLoadingLocations || !(locations[searchParams.get('type')]?.length > 0)}
          >
            Order Now
          </LoadingButton>
        </Stack>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
            mb: 6,
          }}
        ></Box>
      </Container>
    </Page>
  );
}
