import { useEffect, useState } from 'react';
import { Button, Grid, Typography, Alert, CircularProgress, Box, Select, MenuItem, Card } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection } from '@solana/web3.js';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ignoreCryptoError, delayExecution } from '../../utils/utils';
import { SubscriptionPlans, SPHERE_PAY_URL, SPHERE_API_URL } from '../../utils/sphere';
import * as Logger from '../../utils/Logger';

import { VersionedTransaction } from '@solana/web3.js';
import qs from 'qs';

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const header = { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } };

export const CryptoExtendSub = ({
  id,
  name,
  endpoint,
  rpcEndpoint,
  processingSub,
  setProcessingSub,
  planExpiresAt,
  isSSOUser,
  setIsProcessingSSOCryptoSub,
  projectID,
  upgradePlansList = [],
  isUpgradingV1ToV2,
  fetchProjectDetails,
  prepaidCredits,
}) => {
  const connection = new Connection(rpcEndpoint, 'confirmed');
  const ctx = useWallet();
  const [txSig, setTxSig] = useState('');
  const [txStatus, setTxStatus] = useState('uninit');
  const [errMsg, setErrMsg] = useState(null);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const [isUpgradingPlan, setIsUpgradingPlan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [upgradeCost, setUpgradeCost] = useState(0);
  const [daysToExpire, setDaysToExpire] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showExtendPlan, setShowExtendPlan] = useState(true);

  const onExtendPlanClick = () => {
    setShowExtendPlan(true);
    onDialogClose();
    setTxStatus('uninit');
    setTxSig('');
    setErrMsg(null);
    setIsPaymentError(false);
    setProcessingSub(false);
    setSuccessMessage('');
  };

  const onClick = async () => {
    try {
      if (!ctx.publicKey) throw Error('wallet not connected');


      const resp = await axios.get(`${endpoint}/projects/${projectID}/payment-link-upgrade-crypto-plan?newPlan=${selectedProduct}`, header);
      
      let productId = resp.data.id;
      
      const paymentLinkResp = await axios.get(`${SPHERE_API_URL}/${productId}`);

      const paymentLink = paymentLinkResp?.data?.data?.paymentLink;
      setTxStatus('init');
      const response = await axios.post(
        `${SPHERE_API_URL}/pay/${productId}?${qs.stringify({
          referenceId: projectID,
        })}`,
        {
          account: ctx.publicKey.toBase58(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setTxStatus('tx');
      const txBuf = Buffer.from(response.data.transaction, 'base64');
      const tx = VersionedTransaction.deserialize(txBuf);

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext('confirmed');
      const signature = await ctx.sendTransaction(tx, connection, {
        maxRetries: 0,
        minContextSlot,
        skipPreflight: true,
      });
      const confirmation = await connection.confirmTransaction(
        { blockhash, lastValidBlockHeight, signature },
        'confirmed',
      );

      if (confirmation.value.err) {
        setErrMsg(confirmation.value.err);
        return;
      }

      await delay(3000); //3 seconds delay
      await fetchProjectDetails(projectID, false);

      setTxSig(signature);
      setTxStatus('settled');
      setErrMsg(null);
      setSuccessMessage(`Your plan has been successfully upgraded to ${selectedProductName}!`);
      setShowExtendPlan(false);
      onDialogClose();
    } catch (error) {
      await delayExecution(1000);
      console.error('Upgrading error while processing payment', error);
      let errMessage;
      if (error?.response?.data?.message && error?.response?.data?.message.includes('custom program error')) {
        errMessage =
          'Failed to send payment — there might be insufficient USDC in your wallet to purchase this plan or insufficient SOL to cover transaction fees. Please ensure you have enough and try again';
      } else if (!ignoreCryptoError(error)) {
        Logger.error(projectID, 'Upgrading error while processing payment', error);
      }
      setTxSig('');
      setTxStatus('uninit');
      const err = error?.error?.message;
      if (err && err.includes('no record of a prior credit')) {
        errMessage = 'Failed to send payment, not enough USDC or SOL in wallet';
      } else if (!errMessage) {
        errMessage = 'Failed to send payment, subscription not processed';
      }
      setErrMsg(errMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnUpgradeClick = () => {
    setIsUpgradingPlan(true);
  };

  const onDialogClose = () => {
    setUpgradeCost(0);
    setDaysToExpire(0);
    setSelectedProduct(null);
    setSelectedProductName(null);
    setIsLoading(false);
    setErrMsg(null);
    setIsPaymentError(false);
    setIsUpgradingPlan(false);
  };

  const handleConfirmClick = async () => {
    setErrMsg(null);
    setIsPaymentError(false);
    setIsLoading(true);
    await onClick();
  };

  const handlePlanChange = async (event) => {
    const productId = event.target.value;
    await calculateCostToPay(productId);
  };

  const calculateCostToPay = async (productId) => {
    try {
      setErrMsg(null);
      const resp = await axios.get(`${endpoint}/projects/${projectID}/upgrade-cost-crypto-plan?newPlan=${productId}`, header);
      setUpgradeCost(resp.data.upgradeCost.toLocaleString('en-US'));
      setDaysToExpire(resp.data.days);
      setSelectedProduct(productId);
      const product = upgradePlansList.find((o) => o.id === productId);
      setSelectedProductName(product.name);
    } catch(e) {
      setErrMsg('Failed to calculate upgrade cost, please try again in some time.')
    }
  };

  useEffect(() => {
    if (isSSOUser) {
      setIsProcessingSSOCryptoSub(true);
    } else {
      setIsProcessingSSOCryptoSub(false);
    }
  }, [isSSOUser]);

  useEffect(() => {
    if (isUpgradingPlan && upgradePlansList && upgradePlansList.length) {
      const businessProduct = upgradePlansList.find((o) => o.name === SubscriptionPlans.professional_v4);
      if (businessProduct) {
        calculateCostToPay(businessProduct.id);
      } else {
        calculateCostToPay(upgradePlansList[0].id);
      }
    }
  }, [isUpgradingPlan]);

  return (
    <Box
      component={Card}
      padding={4}
      borderRadius={2}
      width={1}
      height={1}
      style={{
        overflow: 'unset',
      }}
    >
      <Dialog sx={{ overflowY: 'hidden' }} open={isUpgradingPlan} onClose={onDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle>Upgrade Subscription</DialogTitle>
        <DialogContent>
          <div>
            {isUpgradingV1ToV2 && (
              <Alert severity="info" icon={false}>
                Heads up, our plans have changed! Please look at{' '}
                <a
                  target="_blank"
                  style={{
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  href="https://www.helius.dev/"
                >
                  https://helius.dev/
                </a>{' '}
                for the latest information.
              </Alert>
            )}
            <div
              style={{
                padding: '6px 14px',
              }}
            >
              <strong>Upgrade to:</strong>
              <Select
                style={{
                  width: '200px',
                  height: '50px',
                  marginLeft: '10px',
                }}
                onChange={handlePlanChange}
                value={selectedProduct}
                variant="standard"
              >
                {upgradePlansList.map((plan) => (
                  <MenuItem key={plan.id} value={plan.id}>{`${plan.name}`}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <br />
          {upgradeCost ? (
            <Alert severity="success" icon={false}>
              Pay <b>${upgradeCost}</b> USDC to upgrade your <b>{daysToExpire}</b> remaining days to{' '}
              <b>{selectedProductName}</b>. You can extend your new plan afterwards.
            </Alert>
          ) : (
            ''
          )}
          {errMsg && (
            <>
              {isPaymentError ? (
                <Alert severity="error">
                  An error occurred. Please check your wallet balance and try again. Contact{' '}
                  <a href="https://discord.gg/HjummjUXgq" target="_blank">
                    support
                  </a>{' '}
                  if the issue persists.
                </Alert>
              ) : (
                <Alert severity="error">{errMsg}</Alert>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{ mt: 5 }}
          style={{
            marginTop: '10px',
          }}
        >
          {isLoading ? <CircularProgress size={'20px'} /> : <Button onClick={handleConfirmClick}>Confirm</Button>}
          <Button onClick={onDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {!ctx.publicKey && upgradePlansList && upgradePlansList.length ? (
        <Alert
          style={{
            marginBottom: '10px',
          }}
          severity="info"
        >
          <strong>To upgrade your plan, please connect your wallet first.</strong>
        </Alert>
      ) : (
        ''
      )}
      <Box
        style={{
          float: 'right',
        }}
      >
        {showExtendPlan ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            {ctx.publicKey && upgradePlansList && upgradePlansList.length > 0 ? (
              <Button
                style={{
                  height: '48px',
                  lineHeight: '48px',
                  margin: '0 10px',
                  backgroundColor: '#103996',
                }}
                onClick={handleOnUpgradeClick}
                target="_blank"
                variant="contained"
                sx={{ mb: 3, mt: 2 }}
              >
                Upgrade Plan
              </Button>
            ) : (
              ''
            )}
            {isSSOUser && upgradePlansList && upgradePlansList.length > 0 && (
              <div>
                <WalletMultiButton />
              </div>
            )}
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
            }}
          >
            {
              <Button
                style={{
                  height: '48px',
                  lineHeight: '48px',
                  margin: '0 10px',
                }}
                onClick={() => onExtendPlanClick()}
                target="_blank"
                variant="contained"
                sx={{ mb: 3, mt: 2 }}
              >
                Extend Plan
              </Button>
            }
          </div>
        )}
      </Box>

      {prepaidCredits > 0 ? (
        <>
          <p className="w-1/2 mb-4">
            There are <strong>{prepaidCredits}</strong> prepaid credits available for use.
          </p>
          <p className="w-1/2 mb-4">You can use these prepaid credits when your monthly credit balance is empty</p>
        </>
      ) : (
        <p className="w-1/2 mb-4">You do not have any prepaid credits available for use.</p>
      )}
      <Button className="mb-4" href={'/prepaid-credits'} variant="outlined">
        Manage pre-paid credits
      </Button>

      {planExpiresAt && (
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>
            Expiration
          </Typography>
          <Typography variant="h7" gutterBottom>
            {new Date(planExpiresAt).toLocaleString()}
          </Typography>
        </Box>
      )}
      <Grid container spacing={3}>
        <Grid item xs={18} sm={9} md={6}>
          {!txSig ? (
            <>
              <Button href={`${SPHERE_PAY_URL}/${id}?reference_id=${projectID}`} variant="contained">
                Extend {name}
              </Button>
            </>
          ) : (
            <>
              <Grid container spacing={0} sx={{ mb: 3, maxWidth: 300 }}>
                {txStatus == 'settled' && (
                  <>
                    <Grid item xs={12} sm={6} md={4} sx={{ mt: 3, whiteSpace: 'nowrap' }}>
                      <Typography>
                        Click{' '}
                        <a
                          href={`https://xray.helius.xyz/tx/${txSig}`}
                          target="_blank"
                          style={{
                            color: 'blue',
                            textDecoration: 'underline',
                          }}
                        >
                          here
                        </a>
                        &nbsp;to view your transaction.
                      </Typography>
                      {!processingSub ? (
                        <Typography display={'flex'} alignItems={'center'} my={2}>
                          {successMessage}
                        </Typography>
                      ) : (
                        <>
                          <Box display={'inline-flex'} my={2} justifyContent={'space-between'}>
                            <Typography display={'flex'} alignItems={'center'} mr={4}>
                              Your subscription is processing
                            </Typography>
                            <CircularProgress />
                          </Box>
                          <Typography>
                            If your plan has not updated after 1 minute, please open a support ticket on discord.
                          </Typography>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {txStatus == 'tx' && (
                  <Grid item xs={12} sm={6} md={4} sx={{ mt: 4 }}>
                    <img src="https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif" width={25} />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
