import { Outlet } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebar from '../dashboard/DashboardSidebar';
import MyRpcs from 'src/pages/Rpcs';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
export default function RpcLayout({
  publicKey,
  endpoint,
  isLoading,
  setLoading,
  apiKey,
  open,
  setOpen,
  allowedCIDRs,
  allowedIPs,
  allowedDomains,
  apiKeys,
  setCurrentApiKey,
  setAllowedCIDRs,
  setAllowedDomains,
  setAllowedIPs,
  billingStart,
  loginType,
  onLogout,
  projectID,
  projectsList,
  setCurrentProject,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
  billingPlan,
}) {
  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        loginType={loginType}
        onLogout={onLogout}
        projectID={projectID}
        projectsList={projectsList}
        setCurrentProject={setCurrentProject}
      />
      {apiKey != undefined && publicKey != undefined && (
        <DashboardSidebar
          billingStart={billingStart}
          setCurrentApiKey={setCurrentApiKey}
          apiKeys={apiKeys}
          apiKey={apiKey}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          userID={publicKey}
          projectID={projectID}
          projectsList={projectsList}
          refetchAllProjects={refetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          endpoint={endpoint}
          setCurrentProject={setCurrentProject}
        />
      )}
      <MainStyle>
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <MyRpcs
            apiKey={apiKey}
            publicKey={publicKey}
            isLoading={isLoading}
            setLoading={setLoading}
            allowedCIDRs={allowedCIDRs}
            allowedDomains={allowedDomains}
            allowedIPs={allowedIPs}
            endpoint={endpoint}
            setAllowedCIDRs={setAllowedCIDRs}
            setAllowedDomains={setAllowedDomains}
            setAllowedIPs={setAllowedIPs}
            projectID={projectID}
            projectsList={projectsList}
            billingPlan={billingPlan}
          />
        )}
      </MainStyle>
    </RootStyle>
  );
}
