import { useState, useEffect } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';

import {
  Typography,
  Container,
  Grid,
} from '@mui/material';
import Page from '../components/Page';
import axios from 'axios';

export default function DedicatedNode({ userID }) {

  const { endpoint, dedicatedNodes, publicKey, apiKey, setDedicatedNodes, handleDelete, billingPlan, billingRange } =
    useOutletContext();

  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();
  const [dashboardURL, setDashboardURL] = useState(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const getApiKeyByHostId = (hostId) => {
    // Find the host object with the matching host_id
    const dedicatedNode = dedicatedNodes.find(dedicatedNode => dedicatedNode.host_id === hostId);

    // Return the api_key if found, otherwise return null or an error message
    return dedicatedNode ? dedicatedNode.api_key : '';
  }

  useEffect(() => {
    const fetchDashboard = async () => {
      let url = `https://${process.env.REACT_APP_ADMIN_WORKER_DOMAIN}/dashboard/project/${localStorage.getItem(
        'helius-project'
      )}`;
      const config = {
        headers: {
          'X-Api-Key': localStorage.getItem('last-api-key'),
        },
      };
      try {
        const { data } = await axios.get(url, config);

        setDashboardURL(`${data?.dashboardUrl}?tpl_var_apiKey%5B0%5D=${getApiKeyByHostId(id)}`);
      } catch (e) {
        console.error(e);
      }
    };
    fetchDashboard();
  }, []);

  if (apiKey == undefined) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid sx={{ mt: -30 }} item xs={3}>
          <Typography variant="h6">Sign in to start building with Helius.</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Page title="Dedicated Node">
      <Container>
        <Typography variant="h4">Dedicated Node {id} </Typography>
        <Grid item xs={12} md={12} lg={12} sx={{ mt: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Metrics
          </Typography>
          <Typography sx={{ color: 'text.secondary', mb: 2 }}>
            To view your dashboard in <strong>full screen</strong>, click{' '}
            <a target="_blank" href={dashboardURL}>
              here
            </a>
            .
          </Typography>
          {!iframeLoaded && <div>Loading...</div>}
          <iframe
            onLoad={handleIframeLoad}
            style={{
              display: iframeLoaded ? 'block' : 'none', // Hide iframe while loading
              marginLeft: '-30px',
            }}
            src={dashboardURL}
            width="1200"
            height="1600"
            allowFullScreen
            frameBorder="0"
          />
        </Grid>
      </Container>
    </Page>
  );
}
