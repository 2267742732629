import { Outlet, Link as RouterLink } from 'react-router-dom';
import { Grid, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import Iconify from '../../components/Iconify';
import { useWallet } from '@solana/wallet-adapter-react';
import { useAuth0 } from '@auth0/auth0-react';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout({
  publicKey,
  billingPlan,
  apiKey,
  apiKeys,
  billingStart,
  rpcCounts,
  setApiKey,
  createdAt,
  isLoading,
  setLoading,
  billingRange,
  isGenerating,
  open,
  setOpen,
  endpoint,
  setCurrentApiKey,
  customerEmail,
  planExpiresAt,
  cryptoSub,
  spherePlans,
  rpcEndpoint,
  processingSub,
  setProcessingSub,
  loginType,
  onLogout,
  projectID,
  projectsList,
  setCurrentProject,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
}) {
  const { _, signMessage } = useWallet();
  const { isAuthenticated: isUsingAuth0 } = useAuth0();
  if (!signMessage && !isUsingAuth0) {
    <RootStyle>
      <MainStyle>Please connect a wallet with signing capabilities (Phantom for example).</MainStyle>
    </RootStyle>;
  }

  if (isLoading) {
    return (
      <RootStyle>
        <DashboardNavbar
          onOpenSidebar={() => setOpen(true)}
          loginType={loginType}
          onLogout={onLogout}
          projectID={projectID}
          projectsList={projectsList}
          setCurrentProject={setCurrentProject}
        />
        <MainStyle>{isLoading && <LinearProgress />}</MainStyle>
      </RootStyle>
    );
  }

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        loginType={loginType}
        onLogout={onLogout}
        projectID={projectID}
        projectsList={projectsList}
        setCurrentProject={setCurrentProject}
      />
      {apiKey != undefined && publicKey != undefined && (
        <DashboardSidebar
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          apiKeys={apiKeys}
          apiKey={apiKey}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          userID={publicKey}
          projectID={projectID}
          projectsList={projectsList}
          refetchAllProjects={refetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          endpoint={endpoint}
          setCurrentProject={setCurrentProject}
        />
      )}
      <MainStyle>
        {!isLoading && apiKey != undefined && publicKey != null && (
          <Outlet
            context={{
              endpoint,
              publicKey,
              billingPlan,
              apiKey,
              rpcCounts,
              setApiKey,
              createdAt,
              isLoading,
              setLoading,
              billingRange,
              cryptoSub,
              planExpiresAt,
              customerEmail,
              spherePlans,
              rpcEndpoint,
              processingSub,
              setProcessingSub,
              projectID,
              projectConfig,
            }}
          />
        )}

        {publicKey == null && !isLoading && (
          <>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh' }}
            >
              <Grid sx={{ mt: -30 }} item xs={3}>
                <Typography variant="h6">Sign in to start building with Helius.</Typography>
              </Grid>
            </Grid>
          </>
        )}

        {apiKey == undefined && publicKey != null && !isLoading && (
          <>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ minHeight: '100vh' }}
            >
              {!isGenerating && <Typography variant="h6"> Start building on Solana.</Typography>}
              {isGenerating && <Typography variant="h6"> Forging your key...</Typography>}

              <Grid sx={{ mt: -30 }} item xs={3}>
                {!isGenerating && !isLoading && !apiKey && (
                  <Button
                    onClick={() => generateAPIKey()}
                    variant="contained"
                    size="large"
                    component={RouterLink}
                    to="#"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Generate Helius API Key
                  </Button>
                )}
                {isGenerating && <CircularProgress size="100px" color="primary" />}
              </Grid>
            </Grid>
          </>
        )}
      </MainStyle>
    </RootStyle>
  );
}
