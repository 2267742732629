import { useState, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import axios from 'axios';
import { DateTime, Interval } from 'luxon';
import ReCAPTCHA from 'react-google-recaptcha';

import DashboardLayout from './layouts/dashboard';
import WebhookLayout from './layouts/webhook';
import DedicatedNodesLayout from './layouts/dedicated-nodes';
import RpcLayout from './layouts/rpc';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import NotFound from './pages/Page404';
import { PageEmailVerification } from './pages/PageEmailVerification';
import DashboardApp from './pages/DashboardApp';
import MyWebhooks from './pages/Webhooks';
import MyDedicatedNodes from './pages/DedicatedNodes';
import NewDedicatedNode from './pages/NewDedicatedNode';
import MyDedicatedNode from './pages/DedicatedNode';
import WebhookLogs from './pages/WebhookLogs';
import { auth0Config, LoginTypes } from './utils/utils';
import { SubscriptionPlansIds } from './utils/sphere';

import MyRpc from './pages/Rpcs';
import Usage from './pages/Usage';
import { useWallet } from '@solana/wallet-adapter-react';
import { useAuth0 } from '@auth0/auth0-react';
import bs58 from 'bs58';
import PrepaidCreditsLayout from './layouts/prepaid-credits';
import UsageLayout from './layouts/usage';

const endpoint = process.env.REACT_APP_API_CLIENT;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const HELIUS_AUTH_TOKEN = 'helius-auth-token';
const HELIUS_AUTH_TOKEN_GEN_DATE = 'helius-auth-token-gen-date';
const HELIUS_AUTH_TOKEN_REFRESHED = 'helius-auth-token-refreshed';
const LAST_WALLET_USED = 'helius-wallet';
const LAST_API_KEY = 'last-api-key';
const LAST_PROJECT_USED = 'helius-project';
const USER_INVITE_CODE = 'invite-code';
const LAST_LOGIN_TYPE = 'login-type';

let rpcEndpoint = `https://portal.helius-rpc.com/`;

export default function Router({}) {
  const { publicKey, signMessage, disconnect, disconnecting } = useWallet();
  const { isLoading: ssoLoading, isAuthenticated: isUsingAuth0, getAccessTokenSilently, logout } = useAuth0();
  const [isLoading, setLoading] = useState(false);
  const [apiKey, setApiKey] = useState(null);
  const [apiKeys, setApiKeys] = useState([]);
  const [isGenerating, setGenerating] = useState(false);
  const [webhookUsage, setWebhookUsage] = useState(null);
  const [createdAt, setCreatedAt] = useState(null);
  const [billingRange, setBillingRange] = useState(null);
  const [billingPlan, setBillingPlan] = useState(null);
  const [rpcCounts, setRPCCounts] = useState([]);
  const [allowedDomains, setAllowedDomains] = useState([]);
  const [allowedIPs, setAllowedIPs] = useState([]);
  const [allowedCIDRs, setAllowedCIDRs] = useState([]);
  const [autoScaling, setAutoScaling] = useState(0);
  const [billingStart, setBillingStart] = useState(null);
  const [planExpiresAt, setPlanExpiresAt] = useState(null);
  const [cryptoSub, setCryptoSub] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [processingSub, setProcessingSub] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loginType, setLoginType] = useState(LoginTypes.None);
  const [currentProject, setCurrentProject] = useState(null);
  const [userProjects, setUserProjects] = useState([]);
  const [refreshProjectsDetails, setRefreshProjectsDetails] = useState(0);
  const [isProcessingSSOCryptoSub, setIsProcessingSSOCryptoSub] = useState(false);
  const [projectConfig, setProjectConfig] = useState(null);
  // const [meteringEntries, setMeteringEntries] = useState(null);
  const [portalConfigs, setPortalConfigs] = useState(null);
  const [isAccountVerified, setIsAccountVerified] = useState(true);
  const [projectCreditsUsage, setProjectCreditsUsage] = useState({});

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [signupSign, setSignupSign] = useState({});

  const [loop, setLoop] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (localStorage.getItem(LAST_LOGIN_TYPE) === LoginTypes.Auth0) {
      setLoginType(LoginTypes.Auth0);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem(LAST_LOGIN_TYPE) === LoginTypes.Auth0 && !isProcessingSSOCryptoSub) {
      setIsProcessingSSOCryptoSub(true);
      publicKey && disconnect();
    }
  }, [publicKey]);

  useEffect(() => {
    if (!ssoLoading && !isUsingAuth0 && localStorage.getItem(LAST_LOGIN_TYPE) === LoginTypes.Auth0) {
      onLogout();
    }
  }, [ssoLoading]);

  // use effect for polling new crypto sub details
  useEffect(() => {
    if (processingSub) {
      const interval = setInterval(() => {
        fetchCryptoSubDetails(currentProject);
      }, 500);
      setLoop(interval);

      setTimeoutId(
        setTimeout(() => {
          setProcessingSub(false);
        }, 1000 * 60),
      );
    } else {
      setLoop((interval) => {
        if (interval != null) {
          clearInterval(interval);
          return null;
        }
      });

      setTimeoutId((timeoutId) => {
        if (timeoutId != null) {
          clearTimeout(timeoutId);
          return null;
        }
      });
    }
  }, [processingSub]);

  useEffect(() => {
    if (!localStorage.getItem(LAST_LOGIN_TYPE) || localStorage.getItem(LAST_LOGIN_TYPE) === LoginTypes.None) {
      resetSession();
    }
  }, []);

  useEffect(() => {
    if (!isGenerating) {
      if (currentUser && currentProject) {
        setApiKey(null);
        setRefreshProjectsDetails(0);
        fetchAPIKeys(currentProject);
        fetchProjectConfigs(currentProject);
        localStorage.setItem(LAST_PROJECT_USED, currentProject);
        if (portalConfigs === null) {
          fetchDevPortalConfig();
        }
      }
    }
  }, [currentProject]);

  useEffect(() => {
    if (!isGenerating && refreshProjectsDetails > 0) {
      currentUser && currentProject && fetchAPIKeys(currentProject);
    }
  }, [refreshProjectsDetails]);

  // Reset if wallet changes
  useEffect(() => {
    if (!isProcessingSSOCryptoSub) {
      if (publicKey != null && localStorage.getItem(LAST_WALLET_USED) != publicKey) {
        localStorage.setItem(LAST_WALLET_USED, publicKey);
        localStorage.removeItem(HELIUS_AUTH_TOKEN);
        localStorage.removeItem(HELIUS_AUTH_TOKEN_GEN_DATE);
        localStorage.removeItem(HELIUS_AUTH_TOKEN_REFRESHED);
        setApiKey(null);
        setRefreshProjectsDetails(0);
        setLoading(true);
        setLoginType(LoginTypes.Wallet);
        setCurrentUser(publicKey.toString());
        localStorage.setItem(LAST_LOGIN_TYPE, LoginTypes.Wallet);
      } else if (!isGenerating) {
        if (publicKey) {
          setLoading(true);
          setLoginType(LoginTypes.Wallet);
          localStorage.setItem(LAST_LOGIN_TYPE, LoginTypes.Wallet);
          setCurrentUser(publicKey.toString());
          (async () => {
            try {
              await fetchAllProjects(publicKey.toString());
            } catch (error) {
              console.error('Failed to fetch projects:', error);
            }
          })();
        }
      }
      signMessage && authSign();
    }
  }, [publicKey]);

  useEffect(() => {
    if (isUsingAuth0) {
      // publicKey && disconnect();
      setLoginType(LoginTypes.Auth0);
      localStorage.setItem(LAST_LOGIN_TYPE, LoginTypes.Auth0);
      setLoading(true);
      getSSOAccessToken();
    }
  }, [isUsingAuth0]);

  useEffect(() => {
    if (disconnecting && !isProcessingSSOCryptoSub) {
      resetSession();
    }
  }, [disconnecting]);

  const getSSOAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        detailedResponse: true,
      });
      await getTokenFromAuth0(accessToken.access_token);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  const handleCurrentProjectChange = (proj) => {
    localStorage.setItem(LAST_PROJECT_USED, proj);
    setCurrentProject(proj);
  };

  const handleCaptchaChange = (recaptchaToken) => {
    setShowCaptcha(false);
    walletNewSignup(recaptchaToken);
  };

  const authSign = async () => {
    const tokenExp = localStorage.getItem(HELIUS_AUTH_TOKEN_GEN_DATE);
    // Can remove this by Jan 12th. We want to force refresh the token so that people start using the timestamp'd msg.
    const refreshed = localStorage.getItem(HELIUS_AUTH_TOKEN_REFRESHED);
    if (
      !tokenExp ||
      !refreshed ||
      Interval.fromDateTimes(DateTime.fromISO(tokenExp), DateTime.now()).length('days') > 5
    ) {
      if (!isUsingAuth0) {
        await delay(1000);
        const msgObj = { message: 'Sign-in to Helius!', timestamp: Date.now() };
        const msg = JSON.stringify(msgObj);
        const sig = await signMessage(new TextEncoder().encode(msg));
        try {
          const { data } = await axios.post(
            `${endpoint}/token/`,
            {
              userID: publicKey.toBase58(),
              signature: bs58.encode(sig),
              message: msg,
            },
            { headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` } },
          );
          if (data.newSignup) {
            setSignupSign({
              userID: publicKey.toBase58(),
              signature: bs58.encode(sig),
              message: msg,
            });
            setShowCaptcha(true);
            return;
          }

          if (data?.token) {
            localStorage.setItem(HELIUS_AUTH_TOKEN, data.token);
            localStorage.setItem(HELIUS_AUTH_TOKEN_GEN_DATE, DateTime.now());
            localStorage.setItem(HELIUS_AUTH_TOKEN_REFRESHED, true);
            setCurrentUser(publicKey.toString());
            await fetchAllProjects(publicKey.toString());
          }
        } catch (e) {
          if (e?.response?.status === 400) {
            alert('Some error ocurred. Please try again in sometime.');
            await onLogout();
            window.location.reload();
          }
        }
      } else {
        getSSOAccessToken();
      }
    }
  };

  const walletNewSignup = async (recaptchaToken) => {
    if (!isUsingAuth0) {
      try {
        const { data } = await axios.post(
          `${endpoint}/wallet-signup/`,
          {
            ...signupSign,
            captchaToken: recaptchaToken,
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` } },
        );

        if (data?.token) {
          localStorage.setItem(HELIUS_AUTH_TOKEN, data.token);
          localStorage.setItem(HELIUS_AUTH_TOKEN_GEN_DATE, DateTime.now());
          localStorage.setItem(HELIUS_AUTH_TOKEN_REFRESHED, true);
          setCurrentUser(publicKey.toString());
          await fetchAllProjects(publicKey.toString());
        }
      } catch (e) {
        if (e?.response?.status === 400) {
          alert('Some error ocurred. Please try again in sometime.');
          await onLogout();
          window.location.reload();
        }
      }
    } else {
      getSSOAccessToken();
    }
  };

  const getTokenFromAuth0 = async (token) => {
    try {
      const data = await axios.post(
        `${endpoint}/auth0-token`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      if (data?.data?.token && data?.data?.userInfo) {
        const email = data.data.userInfo.email;
        if (localStorage.getItem(LAST_WALLET_USED) != email) {
          resetSSOData();
        }

        localStorage.setItem(LAST_WALLET_USED, email);
        localStorage.setItem(HELIUS_AUTH_TOKEN, data?.data?.token);
        localStorage.setItem(HELIUS_AUTH_TOKEN_GEN_DATE, DateTime.now());
        localStorage.setItem(HELIUS_AUTH_TOKEN_REFRESHED, true);
        setLoginType(LoginTypes.Auth0);
        localStorage.setItem(LAST_LOGIN_TYPE, LoginTypes.Auth0);
        setLoading(true);
        setCurrentUser(email);
        await fetchAllProjects(email);
      }
    } catch (e) {
      throw e;
    }
  };

  const resetSSOData = () => {
    setCurrentUser(null);
    setApiKey(null);
    setRefreshProjectsDetails(0);
    setLoginType(LoginTypes.None);
    localStorage.removeItem(LAST_LOGIN_TYPE);
    setCurrentProject(null);
    localStorage.removeItem(LAST_PROJECT_USED);
    setUserProjects([]);
    setIsProcessingSSOCryptoSub(false);
  };

  const resetSession = () => {
    resetSSOData();
    localStorage.removeItem(LAST_WALLET_USED);
    localStorage.removeItem(LAST_PROJECT_USED);
    localStorage.removeItem(HELIUS_AUTH_TOKEN);
    localStorage.removeItem(HELIUS_AUTH_TOKEN_GEN_DATE);
    localStorage.removeItem(HELIUS_AUTH_TOKEN_REFRESHED);
  };

  const onLogout = async () => {
    publicKey && (await disconnect());
    resetSession();
    logout({ logoutParams: { returnTo: auth0Config.redirectUri } });
  };

  const generateAPIKey = async (p) => {
    setGenerating(true);
    try {
      await axios.post(
        `${endpoint}/projects/${p}/keys`,
        {
          userId: currentUser,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      await delay(1000);
      setRefreshProjectsDetails(refreshProjectsDetails + 1);
    } catch (e) {
      console.error('Failed to generate api key');
    } finally {
      setGenerating(false);
    }
  };

  async function fetchProjectConfigs(projectID) {
    if (projectID == null) {
      return;
    }
    try {
      const { data } = await axios.get(`${endpoint}/projects/${projectID}/config`, {
        headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` },
      });
      if (data) {
        setProjectConfig(data);
      }
    } catch (e) {
      console.error('Failed to fetch project configs, ', e);
    }
  }

  async function fetchAPIKeys(projectID) {
    if (projectID == null) {
      setLoading(false);
      return;
    }
    setLoading(true);
    if (signMessage || isUsingAuth0) {
      await authSign();
    }

    try {
      await fetchProjectDetails(projectID, true);
      const code = localStorage.getItem(USER_INVITE_CODE);
      if (code) {
        await addUserToInvitedProject(code);
      }
    } catch (e) {
      console.error('Failed to fetch api keys, ', e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchDevPortalConfig() {
    try {
      const data = await axios.get(`${endpoint}/dev-portal/configs`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
      setPortalConfigs(data.data);
    } catch (e) {
      console.error('Failed to fetch api keys, ', e);
    } finally {
      setLoading(false);
    }
  }

  async function fetchAllProjects(userID) {
    const { data } = await axios.get(`${endpoint}/projects/`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` },
    });
    let userCurrentProj = null;
    const lastProjectUsed = localStorage.getItem(LAST_PROJECT_USED);
    if (data && data.length && lastProjectUsed) {
      const project = data.find((proj) => proj.id === lastProjectUsed);
      if (project) {
        userCurrentProj = project.id;
      }
    }

    if (!userCurrentProj) {
      let defaultProjectID = null;
      let defaultProjectIDOwner = null;
      data?.forEach((project) => {
        (!defaultProjectID || !defaultProjectIDOwner) &&
          project.users?.forEach((usr) => {
            if (!defaultProjectID && usr.id === userID) {
              defaultProjectID = project.id;
            }
            if (!defaultProjectIDOwner && usr.id === userID && usr.role === 'Owner') {
              defaultProjectIDOwner = project.id;
            }
          });
      });
      userCurrentProj = defaultProjectIDOwner || defaultProjectID;
    }
    setUserProjects(data ? data : []);
    setCurrentUser(userID);
    handleCurrentProjectChange(userCurrentProj);
  }

  async function addUserToInvitedProject(code) {
    try {
      const res = await axios.post(
        `${endpoint}/projects/join`,
        {
          code: code,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` },
        },
      );
      localStorage.removeItem(USER_INVITE_CODE);
      if (res && res.data && res.data.project) {
        localStorage.setItem(LAST_PROJECT_USED, res.data.project);
      }
    } catch (e) {
      if (e?.response?.status === 403 && e?.response?.data !== 'access denied') {
        if (confirm(e.response.data)) {
          await onLogout();
        } else {
          localStorage.removeItem(USER_INVITE_CODE);
        }
      } else {
        alert('There was an error joining the project using invite link, try again later or contact support');
        localStorage.removeItem(USER_INVITE_CODE);
      }
    }
    await fetchAllProjects(currentUser);
  }

  async function fetchProjectDetails(p, autoGenerate) {
    const { data } = await axios.get(`${endpoint}/projects/${p}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` },
    });
    if (data.apiKeys?.length) {
      const {
        keyId,
        createdAt,
        billingAnchor,
        autoScalingCreditLimit,
        usagePlan,
        cryptoSub,
        planExpiresAt: expiration,
        customerEmail,
      } = data.apiKeys.sort((k) => k.createdAt)[0];

      if (data.verifiedEmail || usagePlan !== SubscriptionPlansIds.Free) {
        setIsAccountVerified(true);
      } else {
        setIsAccountVerified(false);
      }

      const s = DateTime.fromISO(data.billingCycle.start);
      const e = DateTime.fromISO(data.billingCycle.end);

      const br = `${s.toFormat('yyyy/MM/dd')} - ${e.toFormat('yyyy/MM/dd')}`;
      setApiKeys(data.apiKeys);
      const last_stored_key = localStorage.getItem(LAST_API_KEY);
      if (last_stored_key) {
        const validKey = data.apiKeys.find((k) => k.keyId == last_stored_key);
        if (validKey) {
          await setCurrentApiKey(validKey, s, false);
          setCreatedAt(new Date(validKey.createdAt).toDateString('en-US'));
        } else {
          await setCurrentApiKey(data.apiKeys.sort((k) => k.createdAt)[0], s, false);
          setCreatedAt(new Date(createdAt).toDateString('en-US'));
        }
      } else {
        await setCurrentApiKey(data.apiKeys.sort((k) => k.createdAt)[0], s, false);
        setCreatedAt(new Date(createdAt).toDateString('en-US'));
      }

      setBillingStart(s);
      setBillingRange(br);
      setBillingPlan(usagePlan);
      setPlanExpiresAt(expiration);
      setAutoScaling(autoScalingCreditLimit);
      setCryptoSub(cryptoSub);
      setCustomerEmail(customerEmail);

      const creditsUsage = data.creditsUsage;
      setProjectCreditsUsage(creditsUsage);

      setWebhookUsage(creditsUsage.webhookUsage);
      setRPCCounts(creditsUsage.rpcUsage + creditsUsage.rpcGPAUsage);
      await fetchProjectConfigs(p);
    } else if (autoGenerate) {
      await generateAPIKey(p);
    } else {
      setApiKeys([]);
      setApiKey(null);
    }
  }

  // specific request meant to update crypto sub details. Scoped down to avoid unnecessary re-renders
  async function fetchCryptoSubDetails(p) {
    const { data } = await axios.get(`${endpoint}/projects/${p}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` },
    });
    const { usagePlan, cryptoSub, planExpiresAt: expiration, customerEmail } = data.apiKeys.sort((k) => k.createdAt)[0];

    // got upgraded to crypto sub
    if (cryptoSub && usagePlan != SubscriptionPlansIds.Free && billingPlan != usagePlan) {
      setProcessingSub(false);
    }
    setBillingPlan(usagePlan);

    // crypto sub got extended
    if (cryptoSub && usagePlan != SubscriptionPlansIds.Free && expiration > planExpiresAt) {
      setProcessingSub(false);
    }
    setPlanExpiresAt(expiration);
    setCryptoSub(cryptoSub);
    setCustomerEmail(customerEmail);
  }

  async function setCurrentApiKey(apiKey, x, refreshProject = true) {
    localStorage.setItem(LAST_API_KEY, apiKey.keyId);
    setAllowedIPs(apiKey?.rpcAccessControl?.allowIP || []);
    setAllowedDomains(apiKey?.rpcAccessControl?.allowDomain || []);
    setAllowedCIDRs(apiKey?.rpcAccessControl?.allowCIDR || []);
    setApiKey(apiKey.keyId);
    setRefreshProjectsDetails(refreshProject ? refreshProjectsDetails + 1 : 0);
    // await fetchUsage(apiKey.keyId, x);
  }

  // async function fetchUsage(a, s) {
  //   const startPeriod = DateTime.now().minus({ days: 30 }).toUTC().toFormat('yyyy-MM-dd');
  //   const endPeriod = DateTime.now().toUTC().toFormat('yyyy-MM-dd');
  //   const { data } = await axios.post(
  //     `${endpoint}/metering_v2`,
  //     {
  //       projectId: currentProject,
  //       startPeriod: startPeriod,
  //       endPeriod: endPeriod,
  //       includeUsageMap: true,
  //     },
  //     { headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` } }
  //   );
  //   let entries = data.meteringEntries;
  //   setMeteringEntries(entries);
  // }

  const getRootURL = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramNameValue = queryParams.get('inviteCode');
    if (paramNameValue) {
      localStorage.setItem(USER_INVITE_CODE, paramNameValue);
    }
    return <Navigate to="/dashboard/app" />;
  };

  if (showCaptcha) {
    return (
      <div
        style={{
          height: '100%',
          margin: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY} onChange={handleCaptchaChange} />
      </div>
    );
  }

  return useRoutes([
    {
      path: '/stake',
      element: <Navigate to="https://helius.dial.to/stake" replace />,
    },
    {
      path: '/actions.json',
      element: () => {
        window.location.href = '/actions.json';
        return null;
      },
    },
    {
      path: '/dashboard',
      element: (
        <DashboardLayout
          rpcEndpoint={rpcEndpoint}
          endpoint={endpoint}
          setOpen={setOpen}
          open={open}
          publicKey={currentUser}
          billingPlan={billingPlan}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          rpcCounts={rpcCounts}
          createdAt={createdAt}
          isLoading={isLoading || ssoLoading}
          setLoading={setLoading}
          billingRange={billingRange}
          cryptoSub={cryptoSub}
          planExpiresAt={planExpiresAt}
          customerEmail={customerEmail}
          spherePlans={portalConfigs?.subscriptionsData?.crypto?.subscriptionPlans.sphereV2 || {}}
          processingSub={processingSub}
          setProcessingSub={setProcessingSub}
          isGenerating={isGenerating}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
        />
      ),
      children: [
        {
          path: 'app',
          element: (
            <DashboardApp
              projectsList={userProjects}
              currentUser={currentUser}
              setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
              projectConfig={projectConfig}
              portalConfigs={portalConfigs}
              isAccountVerified={isAccountVerified}
              projectCreditsUsage={projectCreditsUsage}
              autoScaling={autoScaling}
              fetchProjectDetails={fetchProjectDetails}
              billingPlan={billingPlan}
            />
          ),
        },
      ],
    },
    {
      path: '/webhooks',
      element: (
        <WebhookLayout
          endpoint={endpoint}
          setOpen={setOpen}
          open={open}
          publicKey={currentUser}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          isLoading={isLoading || ssoLoading}
          billingRange={billingRange}
          billingPlan={billingPlan}
          setLoading={setLoading}
          webhookUsage={webhookUsage}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          rpcEndpoint={rpcEndpoint}
        />
      ),
      children: [
        {
          path: 'my',
          element: <MyWebhooks projectID={currentProject} projectConfig={projectConfig} />,
        },
        { path: 'my/webhook-logs/:id', element: <WebhookLogs endpoint={endpoint} userID={currentUser} /> },
      ],
    },
    {
      path: '/dedicated-nodes',
      element: (
        <DedicatedNodesLayout
          endpoint={endpoint}
          setOpen={setOpen}
          open={open}
          publicKey={currentUser}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          isLoading={isLoading || ssoLoading}
          billingRange={billingRange}
          billingPlan={billingPlan}
          setLoading={setLoading}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          rpcEndpoint={rpcEndpoint}
        />
      ),
      children: [
        {
          path: 'my',
          element: <MyDedicatedNodes endpoint={endpoint} />,
        },
        {
          path: 'new',
          element: <NewDedicatedNode projectID={currentProject} projectConfig={projectConfig} endpoint={endpoint} />,
        },
        { path: ':id', element: <MyDedicatedNode endpoint={endpoint} userID={currentUser} /> },
      ],
    },
    {
      path: '/rpcs',
      element: (
        <RpcLayout
          setOpen={setOpen}
          endpoint={endpoint}
          open={open}
          publicKey={currentUser}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          isLoading={isLoading || ssoLoading}
          setLoading={setLoading}
          allowedDomains={allowedDomains}
          allowedCIDRs={allowedCIDRs}
          allowedIPs={allowedIPs}
          setAllowedCIDRs={setAllowedCIDRs}
          setAllowedDomains={setAllowedDomains}
          setAllowedIPs={setAllowedIPs}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          billingPlan={billingPlan}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          rpcEndpoint={rpcEndpoint}
        />
      ),
      children: [{ path: 'my', element: <MyRpc /> }],
    },
    {
      path: '/prepaid-credits',
      element: (
        <PrepaidCreditsLayout
          endpoint={endpoint}
          open={open}
          setOpen={setOpen}
          publicKey={currentUser}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          isLoading={isLoading || ssoLoading}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          billingPlan={billingPlan}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          rpcEndpoint={rpcEndpoint}
          cryptoSub={cryptoSub}
          portalConfigs={portalConfigs}
        />
      ),
      children: [{ path: 'my', element: <MyRpc /> }],
    },
    {
      path: '/usage',
      element: (
        <UsageLayout
          endpoint={endpoint}
          open={open}
          publicKey={currentUser}
          apiKey={apiKey}
          apiKeys={apiKeys}
          setOpen={setOpen}
          setCurrentApiKey={setCurrentApiKey}
          billingStart={billingStart}
          isLoading={isLoading || ssoLoading}
          loginType={loginType}
          onLogout={onLogout}
          projectID={currentProject}
          projectsList={userProjects}
          setCurrentProject={handleCurrentProjectChange}
          refetchAllProjects={fetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          billingPlan={billingPlan}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          rpcEndpoint={rpcEndpoint}
          cryptoSub={cryptoSub}
          portalConfigs={portalConfigs}
        />
      ),
      children: [{ path: 'my', element: <Usage /> }],
    },
    {
      path: '/project-verification',
      element: <PageEmailVerification />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: getRootURL() },
        { path: '/account', element: <Navigate to="/account/my" /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
