import { ComputeBudgetProgram } from '@solana/web3.js';

export const getUpgradePlansList = (currentPlanId) => {
  switch (currentPlanId) {
    case 'hacker':
    case 'developer':
    case 'hacker_v2':
    case 'startup_v2':
    case 'developer_v3':
    case 'developer_v4':
      return [
        {
          id: SubscriptionPlansIds.BusinessV4,
          name: SubscriptionPlans.business_v4,
        },
        {
          id: SubscriptionPlansIds.ProfessionalV4,
          name: SubscriptionPlans.professional_v4,
        },
      ];

    case 'business':
    case 'business_v2':
    case 'business_v3':
    case 'business_v4':
      return [
        {
          id: SubscriptionPlansIds.ProfessionalV4,
          name: SubscriptionPlans.professional_v4,
        },
      ];
    default:
      return [];
  }
};

export const isUpgradingV1ToV2 = (currentPlanId) => {
  switch (currentPlanId) {
    case SubscriptionPlansIds.Hacker:
    case SubscriptionPlansIds.Developer:
    case SubscriptionPlansIds.Business:
    case SubscriptionPlansIds.HackerV2:
    case SubscriptionPlansIds.StartupV2:
    case SubscriptionPlansIds.BusinessV2:
      return true;
    default:
      return false;
  }
};

// ID vs Plan Name
export const SubscriptionPlans = {
  free: 'Free',
  hacker: 'Hacker',
  hacker_v2: 'Hacker',
  developer: 'Developer',
  startup_v2: 'Startup',
  business: 'Business',
  business_v2: 'Business',
  enterprise: 'Enterprise',
  developer_v3: 'Developer',
  business_v3: 'Business',
  performance_v3: 'Performance',
  developer_v4: 'Developer',
  business_v4: 'Business',
  professional_v4: 'Professional',
  enterprise_v4: 'Enterprise',
};

// plan IDs
export const SubscriptionPlansIds = {
  Free: 'free',
  Hacker: 'hacker',
  HackerV2: 'hacker_v2',
  Developer: 'developer',
  StartupV2: 'startup_v2',
  Business: 'business',
  BusinessV2: 'business_v2',
  Enterprise: 'enterprise',
  DeveloperV3: 'developer_v3',
  BusinessV3: 'business_v3',
  PerformanceV3: 'performance_v3',
  DeveloperV4: 'developer_v4',
  BusinessV4: 'business_v4',
  ProfessionalV4: 'professional_v4',
  EnterpriseV4: 'enterprise_v4',
};

export const configurePriorityFee = async (tx, connection) => {
  const rpcEndpoint = `https://portal.helius-rpc.com/`;

  // Throw an error if a transaction isn't passed in
  if (!(tx instanceof Transaction)) {
    throw new Error('The tx parameter must be an instance of Transaction');
  }

  // Serialize and encode the transaction so we can use it for the priority fee estimate
  const serializedTransaction = bs58.encode(tx.serialize());

  // Fetch the priority fee estimate
  const priorityFeeEstimateResponse = await fetch(rpcEndpoint, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: '1',
      method: 'getPriorityFeeEstimate',
      params: [
        {
          transaction: serializedTransaction,
          options: {
            recommended: true,
          },
        },
      ],
    }),
  });

  const priorityFeeEstimateData = await priorityFeeEstimateResponse.json();
  const priorityFeeEstimate = priorityFeeEstimateData.result.priorityFeeEstimate;

  if (!priorityFeeEstimate) {
    throw new Error('Priority fee estimate not available');
  }

  // Construct the compute unit price instruction with the estimated fee
  const computePriceIx = ComputeBudgetProgram.setComputeUnitPrice({
    microLamports: priorityFeeEstimate,
  });

  // Create and simulate a test transaction to get the compute units used
  const testInstructions = [
    computePriceIx,
    ComputeBudgetProgram.setComputeUnitLimit({ units: 1_400_000 }),
    ...tx.instructions,
  ];

  const testTransaction = new Transaction().add(...testInstructions);
  testTransaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
  testTransaction.feePayer = tx.feePayer;

  const simulationResponse = await connection.simulateTransaction(testTransaction, {
    replaceRecentBlockhash: true,
    sigVerify: false,
  });

  if (simulationResponse.value.err) {
    console.error(`Simulation error: ${simulationResponse.value.err}`);
    throw new Error('Transaction simulation failed');
  }

  const unitsConsumed = simulationResponse.value.unitsConsumed || 0;
  let computeUnits = unitsConsumed < 1000 ? 1000 : Math.ceil(unitsConsumed * 1.1);

  // Create the compute unit limit instruction
  const computeLimitIx = ComputeBudgetProgram.setComputeUnitLimit({
    units: computeUnits,
  });

  // Prepend the new instructions to the transaction
  tx.instructions = [computePriceIx, computeLimitIx, ...tx.instructions];

  return tx;
};

export const SPHERE_API_URL = 'https://api.spherepay.co/v1/public/paymentLink';
export const SPHERE_PAY_URL = 'https://spherepay.co/pay';
