import { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  Container,
  Box,
  Stack,
  Grid,
  Card,
  CardHeader,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from '@mui/material';
import { Cancel } from '@mui/icons-material';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { useTheme } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import Chart from 'react-apexcharts';
import { DateTime } from 'luxon';
import axios from 'axios';
import { SubscriptionPlansIds } from '../utils/sphere';

export default function Usage({ apiKey, meteringEntries, projectID, billingPlan }) {
  if (apiKey == undefined) {
    return 'Please go to the home page and generate an API key';
  }

  const endpoint = process.env.REACT_APP_API_CLIENT;
  const HELIUS_AUTH_TOKEN = 'helius-auth-token';
  const dasMethods = [
    'getAsset',
    'getAssetBatch',
    'getAssetProof',
    'getAssetProofBatch',
    'getAssetsByOwner',
    'getAssetsByGroup',
    'getAssetsByCreator',
    'getAssetsByAuthority',
    'searchAssets',
    'getSignaturesForAsset',
    'get_asset_proof',
    'get_asset',
    'get_assets_by_owner',
    'get_assets_by_group',
    'get_assets_by_creator',
    'get_assets_by_authority',
    'search_assets',
  ];
  const [globalCategories, setGlobalCategories] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [tags, setTags] = useState([]);
  const [methodChartData, setMethodChartData] = useState([]);
  const [typeChartData, setTypeChartData] = useState([]);
  const [selectedChart, setSelectedChart] = useState('methods');
  const [datapoints, setDatapoints] = useState(30);
  const [usageEntries, setUsageEntries] = useState(null);
  const [threeMonthUsage, setThreeMonthUsage] = useState(null);
  const [oneMonthUsage, setOneMonthUsage] = useState(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [dashboardURL, setDashboardURL] = useState(null);
  const theme = useTheme();

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  useEffect(() => {
    setDashboardURL(null);
    const fetchDashboard = async () => {
      if(billingPlan === SubscriptionPlansIds.Free) {
        return
      }
      let url = `https://${process.env.REACT_APP_ADMIN_WORKER_DOMAIN}/dashboard/project/${localStorage.getItem(
        'helius-project'
      )}`;
      const config = {
        headers: {
          'X-Api-Key': localStorage.getItem('last-api-key'),
        },
      };
      try {
        const { data } = await axios.get(url, config);
        setDashboardURL(data?.dashboardUrl);
      } catch (e) {
        console.error(e);
      }
    };
    fetchDashboard();
  }, []);

  const handleTimeRangeChange = async (days) => {
    setDatapoints(days);
    if (days == 30) {
      let data = oneMonthUsage;
      if (data == null) {
        data = await fetch1MonthUsage();
        setOneMonthUsage(data);
      }
      setUsageEntries(data);
    }
    if (days == 90) {
      let data = threeMonthUsage;
      if (data == null) {
        data = await fetch3MonthUsage();
        setThreeMonthUsage(data);
      }
      setUsageEntries(data);
    }
  };

  async function fetch3MonthUsage() {
    const startPeriod = DateTime.now().minus({ days: 90 }).toUTC().toFormat('yyyy-MM-dd');
    const endPeriod = DateTime.now().toUTC().toFormat('yyyy-MM-dd');
    const { data } = await axios.post(
      `${endpoint}/metering_v2`,
      {
        projectId: projectID,
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        includeUsageMap: true,
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` } }
    );

    return data.meteringEntries;
  }

  async function fetch1MonthUsage() {
    const startPeriod = DateTime.now().minus({ days: 30 }).toUTC().toFormat('yyyy-MM-dd');
    const endPeriod = DateTime.now().toUTC().toFormat('yyyy-MM-dd');
    const { data } = await axios.post(
      `${endpoint}/metering_v2`,
      {
        projectId: projectID,
        startPeriod: startPeriod,
        endPeriod: endPeriod,
        includeUsageMap: true,
      },
      { headers: { Authorization: `Bearer ${localStorage.getItem(HELIUS_AUTH_TOKEN)}` } }
    );

    return data.meteringEntries;
  }

  const handleChipClick = (key) => {
    if (selectedChart === 'methods') {
      const filteredChart = methodChartData.filter((item) => item.name.toLowerCase() == key.toLowerCase());
      setChartData(filteredChart);
    } else if (selectedChart === 'usage-type') {
      const filteredChart = typeChartData.filter((item) => item.name.toLowerCase() == key.toLowerCase());
      setChartData(filteredChart);
    }
  };

  const handleChartReset = () => {
    if (selectedChart === 'methods') {
      setChartData(methodChartData);
    } else if (selectedChart === 'usage-type') {
      setChartData(typeChartData);
    }
  };

  const generateHSLColor = (index) => {
    const hue = index * 137.508; // Use the golden angle approximation for distribution
    return `hsl(${hue % 360}, 50%, 60%)`;
  };

  const handleChartChange = (event, newChart) => {
    if (newChart !== null) {
      setSelectedChart(newChart);
    }
  };

  const getCategories = () => {
    const currentTimestamp = Math.round(Date.now() / 1000);
    let categories;
    let timestamps;
    switch (datapoints) {
      case 30: // 1M
        timestamps = Array.from({ length: 30 }, (_, i) => {
          const dayStamp = currentTimestamp - i * 60 * 60 * 24;
          return new Date(dayStamp * 1000).setHours(0, 0, 0, 0) / 1000;
        });
        timestamps.reverse();
        categories = timestamps.map((timestamp) => {
          const date = new Date(timestamp * 1000);
          return `${format(date, 'do MMM')}`;
        });
        break;
      case 90: // 3M
        timestamps = Array.from({ length: 12 }, (_, i) => {
          const dayStamp = currentTimestamp - i * 60 * 60 * 24 * 7;
          return new Date(dayStamp * 1000).setHours(0, 0, 0, 0) / 1000;
        });
        timestamps.reverse();
        categories = timestamps.map((timestamp) => {
          const date = new Date(timestamp * 1000);
          return `${format(date, 'do MMM')}`;
        });
        break;
    }

    return { categories: categories, timestamps: timestamps };
  };

  const fillSeries = (timestamps, datas) => {
    let seriesLength = 30;
    if (datapoints == 90) {
      seriesLength = 12;
    }
    let singleSeries = Array(seriesLength).fill(0);
    datas.forEach((callObj) => {
      for (let i = timestamps.length - 1; i >= 0; i--) {
        if (callObj.timestamp >= timestamps[i]) {
          singleSeries[i] = singleSeries[i] + callObj.calls;
          i = -1;
        }
      }
    });
    return singleSeries;
  };

  useEffect(() => {
    if (usageEntries) {
      const { categories, timestamps } = getCategories();
      setGlobalCategories(categories);
      const callsNames = Object.keys(
        usageEntries.reduce((acc, entry) => {
          Object.keys(entry.usageMap).forEach((key) => {
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(entry.usageMap[key]);
          });
          return acc;
        }, {})
      );
      const callsData = callsNames.map((key) => ({
        name: key,
        data: usageEntries
          .filter((entry) => entry.usageMap[key])
          .map((entry) => {
            const date = parseISO(entry.period);
            const prepaidCount = entry.usageMap[key].prepaidCount || 0;
            const totalCount = prepaidCount + entry.usageMap[key].count;
            return { timestamp: Math.round(date.getTime() / 1000), calls: totalCount };
          }),
      }));
      const result = callsData.map((callData, index) => {
        const singleSeries = fillSeries(timestamps, callData.data);
        return { name: callData.name, data: singleSeries, color: generateHSLColor(index) };
      });
      const tags = result.map((item) => ({ key: item.name, color: item.color }));
      if (selectedChart !== 'usage-type') {
        setTags(tags);
        setChartData(result);
      }
      setMethodChartData(result);

      const aggregatedData = getAggregatedData(usageEntries);
      setTypeChartData(aggregatedData);
      if (selectedChart === 'usage-type') {
        const tags = aggregatedData.map((item) => ({ key: item.name, color: item.color }));
        setTags(tags);
        setChartData(aggregatedData);
      }
    }
  }, [usageEntries]);

  useEffect(() => {
    setUsageEntries(meteringEntries);
    if(projectID) {
      setDatapoints(30);
      setOneMonthUsage(null);
      setThreeMonthUsage(null);
      handleTimeRangeChange(30);
    }
  }, [projectID]);

  const getAggregatedData = (data) => {
    const result = {};

    data.forEach((entry) => {
      const period = entry.period;
      const usageMap = entry.usageMap;

      for (const key in usageMap) {
        let usageType = dasMethods.includes(key) ? 'DAS' : usageMap[key].usageType;
        const prepaidCount = usageMap[key].prepaidCount || 0;
        const count = usageMap[key].count + prepaidCount;

        if (!result[usageType]) {
          result[usageType] = { usageType, calls: [] };
        }
        const date = parseISO(period);
        const dateTimestamp = Math.round(date.getTime() / 1000);
        const existingEntry = result[usageType].calls.find((c) => c.timestamp === dateTimestamp);
        if (existingEntry) {
          existingEntry.calls += count;
        } else {
          result[usageType].calls.push({ timestamp: dateTimestamp, calls: count });
        }
      }
    });
    let finalList = Object.values(result).map((item, index) => {
      const seriesData = fillSeries(getCategories().timestamps, item.calls);
      let usageType = item.usageType;
      if (usageType == 'rpc') {
        usageType = 'RPC';
      } else if (usageType == 'api') {
        usageType = 'API';
      }
      return { name: usageType, data: seriesData, color: generateHSLColor(index) };
    });

    return finalList;
  };

  useEffect(() => {
    if (selectedChart === 'methods' && methodChartData.length > 0) {
      setChartData(methodChartData);
      const tags = methodChartData.map((item) => ({ key: item.name, color: item.color }));
      setTags(tags);
    } else if (selectedChart === 'usage-type' && typeChartData.length > 0) {
      setChartData(typeChartData);
      const tags = typeChartData.map((item) => ({ key: item.name, color: item.color }));
      setTags(tags);
    }
  }, [selectedChart]);

  return (
    <Page title="Usage">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Usage
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader
                title="Usage Breakdown"
                subheader="Hint: Click the various labels to filter by method/type. DAS usage is included in the RPC usage."
              />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center" gap={2}>
                    <ToggleButtonGroup
                      value={selectedChart}
                      exclusive
                      onChange={handleChartChange}
                      aria-label="chart selection"
                    >
                      <ToggleButton value="methods" aria-label="methods">
                        Methods
                      </ToggleButton>
                      <ToggleButton value="usage-type" aria-label="usage-type">
                        Usage type
                      </ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="range-select-label">Time range</InputLabel>
                      <Select
                        labelId="range-select-label"
                        id="range-select"
                        value={datapoints}
                        onChange={(e) => handleTimeRangeChange(e.target.value)}
                        label="Time range"
                      >
                        <MenuItem value={30}>1 month</MenuItem>
                        <MenuItem value={90}>3 months</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={handleChartReset}
                    sx={{ margin: theme.spacing(1) }}
                    startIcon={<Iconify icon="carbon:reset" />}
                  >
                    Reset filters
                  </Button>
                </Box>

                {chartData.length === 0 && (
                  <Typography sx={{ color: 'text.secondary' }}>No usage data found.</Typography>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflowX: 'auto',
                    padding: theme.spacing(1),
                    '&::after, &::before': {
                      content: '""',
                      flex: '0 0 ' + theme.spacing(1),
                    },
                  }}
                >
                  {tags.length > 0 &&
                    tags.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag.key}
                        onClick={() => handleChipClick(tag.key)}
                        deleteIcon={<Cancel />}
                        sx={{
                          backgroundColor: tag.color,
                          margin: theme.spacing(0.5),
                          textTransform: 'inherit',
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                      />
                    ))}
                </Box>
                {chartData.length > 0 && (
                  <Chart
                    options={{
                      legend: {
                        show: false,
                      },
                      chart: {
                        id: 'method-chart',
                        stacked: true,
                      },
                      xaxis: {
                        categories: globalCategories,
                      },
                      yaxis: {
                        title: {
                          text: 'Requests',
                        },
                      },
                    }}
                    series={chartData}
                    type="bar"
                    width="100%"
                    height="550"
                  />
                )}
              </Box>
            </Card>
          </Grid>
          {dashboardURL != null && billingPlan !== SubscriptionPlansIds.Free  ? (
            <Grid item xs={12} md={12} lg={12}>
              <Card>
                <CardHeader title="RPC Metrics" />
                <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                  <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                    To view your dashboard in <strong>full screen</strong>, click{' '}
                    <a target="_blank" href={dashboardURL}>
                      here
                    </a>
                    .
                  </Typography>
                </Box>
                <Box sx={{ p: 3, pb: 1 }} dir="ltr" display="flex" justifyContent="center">
                  {!iframeLoaded && <div>Loading...</div>}
                  <iframe
                    onLoad={handleIframeLoad}
                    style={{
                      display: iframeLoaded ? 'block' : 'none', // Hide iframe while loading
                    }}
                    src={dashboardURL}
                    width="100%"
                    height="1600"
                    allowFullScreen
                    frameBorder="0"
                  />
                </Box>
              </Card>
            </Grid>
          ): billingPlan === SubscriptionPlansIds.Free && <Grid item xs={12} md={12} lg={12}>
            <Card>
              <CardHeader title="RPC Metrics" />
              <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Typography sx={{ color: 'text.secondary', mb: 2 }}>
                  <Alert sx={{ mb: 3 }} severity='info'>
                    To unlock RPC metrics & insights, please upgrade to a paid plan.
                  </Alert>
                </Typography>
              </Box>
            </Card>
          </Grid>}
        </Grid>
      </Container>
    </Page>
  );
}
