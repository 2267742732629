import React, { useMemo } from 'react';
import { clusterApiUrl } from '@solana/web3.js';
import { createDefaultAuthorizationResultCache, SolanaMobileWalletAdapter } from '@solana-mobile/wallet-adapter-mobile';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import {
  GlowWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  BackpackWalletAdapter,
  CoinbaseWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import Router from './routes';
import ThemeProvider from './theme';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import { auth0Config as configAuth } from './utils/utils';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import FrontChat from './components/FrontTickets/FrontChat';
import '../src/app.css';
require('@solana/wallet-adapter-react-ui/styles.css');

// ----------------------------------------------------------------------

export default function App() {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  const auth0Config = {
    // Auth0 configuration options
    domain: configAuth.domain,
    clientId: configAuth.clientId,
    authorizationParams: {
      scope: 'openid profile email',
      redirect_uri: configAuth.redirectUri,
      prompt: 'select_account',
    },
    onRedirectCallback: onRedirectCallback,
  };

  return (
    <ThemeProvider>
      <Context>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Auth0Provider {...auth0Config}>
          <Router />
        </Auth0Provider>
        <FrontChat />
      </Context>
    </ThemeProvider>
  );
}

const Context = ({ children }) => {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      new SolanaMobileWalletAdapter({
        appIdentity: { name: 'Solana Create React App Starter App' },
        authorizationResultCache: createDefaultAuthorizationResultCache(),
      }),
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new BackpackWalletAdapter(),
      new CoinbaseWalletAdapter(),
      new SolflareWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>{children}</WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
