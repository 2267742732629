import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebar from '../dashboard/DashboardSidebar';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DedicatedNodesLayout({
  endpoint,
  publicKey,
  isLoading,
  setLoading,
  apiKey,
  apiKeys,
  billingPlan,
  billingRange,
  open,
  billingStart,
  setOpen,
  setCurrentApiKey,
  loginType,
  onLogout,
  projectID,
  projectsList,
  setCurrentProject,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
}) {
  const [dedicatedNodes, setDedicatedNodes] = useState(null);
  const [isLoadingDedicatedNodes, setLoadingDedicatedNodes] = useState(false);

  const fetchDedicatedNodes = useCallback(async () => {
    setLoadingDedicatedNodes(true);
    try {
      const { data } = await axios.get(`${endpoint}/projects/${projectID}/dedicated-nodes`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });

      setDedicatedNodes(data);
    } catch (e) {
      console.error('Failed to fetch Dedicated Nodes', e);
    } finally {
      setLoadingDedicatedNodes(false);
    }
  }, [endpoint, projectID]);

  const handleDelete = async (dedicatedNodeId) => {
    setLoadingDedicatedNodes(true);
    setDedicatedNodes(null);
    try {
      await axios.delete(`${endpoint}/dedicated-nodes/${dedicatedNodeId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
    } catch (e) {
      console.error(e);
    } finally {
      fetchDedicatedNodes();
    }
  };

  const handleMigrate = async (dedicatedNodeId) => {
    setLoadingDedicatedNodes(true);
    setDedicatedNodes(null);
    try {
      await axios.post(
        `${endpoint}/dedicated-nodes/migrate`,
        {
          hostId: dedicatedNodeId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      fetchDedicatedNodes();
    }
  };

  useEffect(() => {
    if (publicKey && apiKey) {
      fetchDedicatedNodes();
    }
  }, [publicKey, apiKey, fetchDedicatedNodes]);

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        loginType={loginType}
        onLogout={onLogout}
        projectID={projectID}
        projectsList={projectsList}
        setCurrentProject={setCurrentProject}
      />
      {apiKey && publicKey !== undefined && (
        <DashboardSidebar
          apiKey={apiKey}
          billingStart={billingStart}
          setCurrentApiKey={setCurrentApiKey}
          apiKeys={apiKeys}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          userID={publicKey}
          projectID={projectID}
          projectsList={projectsList}
          refetchAllProjects={refetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          endpoint={endpoint}
          setCurrentProject={setCurrentProject}
        />
      )}
      <MainStyle>
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <Outlet
            context={{
              endpoint,
              apiKey,
              publicKey,
              billingPlan,
              dedicatedNodes,
              setDedicatedNodes,
              handleDelete,
              handleMigrate,
              billingRange,
              isLoadingDedicatedNodes,
            }}
          />
        )}
      </MainStyle>
    </RootStyle>
  );
}
