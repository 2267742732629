import { useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import axios from 'axios';
import {
  TableRow,
  TableBody,
  TableCell,
  Card,
  Paper,
  Grid,
  Tooltip,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  CircularProgress,
  TableContainer,
  Table,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Checkbox,
  Container,
  Typography,
  Alert,
  Stack,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Autocomplete,
} from '@mui/material';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import QuestionIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import { SubscriptionPlansIds } from '../utils/sphere';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const txnTypesList = [
  'Any',
  'ACCEPT_ESCROW_ARTIST',
  'ACCEPT_ESCROW_USER',
  'ACCEPT_PROPOSAL',
  'ACCEPT_REQUEST_ARTIST',
  'ACTIVATE_TRANSACTION',
  'ACTIVATE_VAULT',
  'ADD_INSTRUCTION',
  'ADD_ITEM',
  'ADD_LIQUIDITY',
  'ADD_PAYMENT_MINT_PAYMENT_METHOD',
  'ADD_RARITIES_TO_BANK',
  'ADD_TO_POOL',
  'ADD_TO_WHITELIST',
  'ADD_TOKEN_TO_VAULT',
  'ADD_TRAIT_CONFLICTS',
  'ADMIN_SYNC_LIQUIDITY',
  'APPROVE_TRANSACTION',
  'ATTACH_METADATA',
  'AUCTION_HOUSE_CREATE',
  'AUCTION_MANAGER_CLAIM_BID',
  'AUTHORIZE_FUNDER',
  'BEGIN_TRAIT_UPDATE',
  'BEGIN_VARIANT_UPDATE',
  'BORROW_CNFT_PERPETUAL',
  'BORROW_FOX',
  'BORROW_PERPETUAL',
  'BORROW_SOL_FOR_NFT',
  'BORROW_STAKED_BANX_PERPETUAL',
  'BOT_CLAIM_SALE',
  'BOT_DELIST',
  'BOT_LIQUIDATE',
  'BOT_LIQUIDATE_SELL',
  'BOT_UNFREEZE',
  'BOUND_HADO_MARKET_TO_FRAKT_MARKET',
  'BURN',
  'BURN_NFT',
  'BURN_PAYMENT',
  'BURN_PAYMENT_TREE',
  'BUY_ITEM',
  'BUY_LOAN',
  'BUY_SUBSCRIPTION',
  'BUY_TICKETS',
  'CANCEL_ESCROW',
  'CANCEL_LOAN_REQUEST',
  'CANCEL_OFFER',
  'CANCEL_ORDER',
  'CANCEL_PROPOSAL',
  'CANCEL_REWARD',
  'CANCEL_SWAP',
  'CANCEL_TRANSACTION',
  'CANCEL_UPDATE',
  'CANDY_MACHINE_ROUTE',
  'CANDY_MACHINE_UNWRAP',
  'CANDY_MACHINE_UPDATE',
  'CANDY_MACHINE_WRAP',
  'CHANGE_COMIC_STATE',
  'CLAIM_CNFT_PERPETUAL_LOAN',
  'CLAIM_NFT',
  'CLAIM_NFT_BY_LENDER_CNFT',
  'CLAIM_NFT_BY_LENDER_PNFT',
  'CLAIM_PERPETUAL_LOAN',
  'CLAIM_REWARDS',
  'CLAIM_SALE',
  'CLEAN',
  'CLOSE_ACCOUNT',
  'CLOSE_ESCROW_ACCOUNT',
  'CLOSE_ITEM',
  'CLOSE_ORDER',
  'CLOSE_POOL',
  'CLOSE_POSITION',
  'COMPRESS_NFT',
  'COMPRESSED_NFT_BURN',
  'COMPRESSED_NFT_CANCEL_REDEEM',
  'COMPRESSED_NFT_DELEGATE',
  'COMPRESSED_NFT_MINT',
  'COMPRESSED_NFT_REDEEM',
  'COMPRESSED_NFT_SET_VERIFY_COLLECTION',
  'COMPRESSED_NFT_TRANSFER',
  'COMPRESSED_NFT_UNVERIFY_COLLECTION',
  'COMPRESSED_NFT_UNVERIFY_CREATOR',
  'COMPRESSED_NFT_UPDATE_METADATA',
  'COMPRESSED_NFT_VERIFY_COLLECTION',
  'COMPRESSED_NFT_VERIFY_CREATOR',
  'CREATE_APPRAISAL',
  'CREATE_AVATAR',
  'CREATE_AVATAR_CLASS',
  'CREATE_BET',
  'CREATE_BOND_AND_SELL_TO_OFFERS',
  'CREATE_BOND_AND_SELL_TO_OFFERS_CNFT',
  'CREATE_BOND_AND_SELL_TO_OFFERS_FOR_TEST',
  'CREATE_BOND_OFFER_STANDARD',
  'CREATE_COLLECTION',
  'CREATE_ESCROW',
  'CREATE_MASTER_EDITION',
  'CREATE_MERKLE_TREE',
  'CREATE_ORDER',
  'CREATE_PAYMENT_METHOD',
  'CREATE_PERPETUAL_BOND_OFFER',
  'CREATE_POOL',
  'CREATE_RAFFLE',
  'CREATE_STATS',
  'CREATE_STORE',
  'CREATE_TRAIT',
  'CREATE_TRANSACTION',
  'DEAUTHORIZE_FUNDER',
  'DECOMPRESS_NFT',
  'DELEGATE_MERKLE_TREE',
  'DELETE_COLLECTION',
  'DELIST_ITEM',
  'DELIST_NFT',
  'DEPOSIT',
  'DEPOSIT_FRACTIONAL_POOL',
  'DEPOSIT_GEM',
  'DEPOSIT_SOL_TO_FLASH_LOAN_POOL',
  'DEPOSIT_TO_BOND_OFFER_STANDARD',
  'DEPOSIT_TO_REWARDS_VAULT',
  'DISTRIBUTE_COMPRESSION_REWARDS',
  'EMPTY_PAYMENT_ACCOUNT',
  'EQUIP_TRAIT',
  'EQUIP_TRAIT_AUTHORITY',
  'EXECUTE_LOAN',
  'EXECUTE_MORTGAGE',
  'EXECUTE_TRANSACTION',
  'EXIT_VALIDATE_AND_SELL_TO_BOND_OFFERS_V2',
  'EXPIRE',
  'EXTEND_LOAN',
  'FILL_ORDER',
  'FINALIZE_PROGRAM_INSTRUCTION',
  'FINISH_HADO_MARKET',
  'FIX_POOL',
  'FORECLOSE_LOAN',
  'FRACTIONALIZE',
  'FREEZE',
  'FUND_REWARD',
  'FUSE',
  'INIT_AUCTION_MANAGER_V2',
  'INIT_BANK',
  'INIT_FARM',
  'INIT_FARMER',
  'INIT_RENT',
  'INIT_STAKE',
  'INIT_SWAP',
  'INIT_VAULT',
  'INITIALIZE_ACCOUNT',
  'INITIALIZE_FLASH_LOAN_POOL',
  'INITIALIZE_HADO_MARKET',
  'INSTANT_REFINANCE_PERPETUAL_LOAN',
  'KICK_ITEM',
  'LEND_FOR_NFT',
  'LIQUIDATE',
  'LIQUIDATE_BOND_ON_AUCTION_CNFT',
  'LIQUIDATE_BOND_ON_AUCTION_PNFT',
  'LIST_ITEM',
  'LIST_NFT',
  'LOAN',
  'LOAN_FOX',
  'LOCK_REWARD',
  'MAKE_PERPETUAL_MARKET',
  'MAP_BANX_TO_POINTS',
  'MERGE_STAKE',
  'MIGRATE_TO_PNFT',
  'NFT_AUCTION_CANCELLED',
  'NFT_AUCTION_CREATED',
  'NFT_AUCTION_UPDATED',
  'NFT_BID',
  'NFT_BID_CANCELLED',
  'NFT_CANCEL_LISTING',
  'NFT_GLOBAL_BID',
  'NFT_GLOBAL_BID_CANCELLED',
  'NFT_LISTING',
  'NFT_MINT',
  'NFT_MINT_REJECTED',
  'NFT_PARTICIPATION_REWARD',
  'NFT_RENT_ACTIVATE',
  'NFT_RENT_CANCEL_LISTING',
  'NFT_RENT_END',
  'NFT_RENT_LISTING',
  'NFT_RENT_UPDATE_LISTING',
  'NFT_SALE',
  'OFFER_LOAN',
  'PATCH_BROKEN_USER_STAKES',
  'PAYOUT',
  'PLACE_BET',
  'PLACE_SOL_BET',
  'PLATFORM_FEE',
  'POOL_CANCEL_PROPOSAL',
  'PROPOSE_LOAN',
  'REBORROW_SOL_FOR_NFT',
  'RECORD_RARITY_POINTS',
  'REFINANCE_FBOND_BY_LENDER',
  'REFINANCE_PERPETUAL_LOAN',
  'REFINANCE_TO_BOND_OFFERS_V2',
  'REFINANCE_TO_BOND_OFFERS_V2_CNFT',
  'REFRESH_FARMER',
  'REJECT_SWAP',
  'REJECT_TRANSACTION',
  'REMOVE_BOND_OFFER_V2',
  'REMOVE_FROM_POOL',
  'REMOVE_FROM_WHITELIST',
  'REMOVE_PERPETUAL_OFFER',
  'REMOVE_TRAIT',
  'REMOVE_TRAIT_AUTHORITY',
  'REPAY',
  'REPAY_CNFT_PERPETUAL_LOAN',
  'REPAY_COMPRESSED',
  'REPAY_FBOND_TO_TRADE_TRANSACTIONS',
  'REPAY_FBOND_TO_TRADE_TRANSACTIONS_CNFT',
  'REPAY_FLASH_LOAN',
  'REPAY_LOAN',
  'REPAY_PARTIAL_PERPETUAL_LOAN',
  'REPAY_PERPETUAL_LOAN',
  'REPAY_STAKED_BANX',
  'REPAY_STAKED_BANX_PERPETUAL_LOAN',
  'REQUEST_LOAN',
  'REQUEST_PNFT_MIGRATION',
  'RESCIND_LOAN',
  'SELL_LOAN',
  'SELL_NFT',
  'SELL_STAKED_BANX_TO_OFFERS',
  'SET_AUTHORITY',
  'SET_BANK_FLAGS',
  'SET_VAULT_LOCK',
  'SPLIT_STAKE',
  'STAKE_BANX',
  'STAKE_SOL',
  'STAKE_TOKEN',
  'START_PNFT_MIGRATION',
  'SWAP',
  'SWITCH_FOX',
  'SWITCH_FOX_REQUEST',
  'SYNC_LIQUIDITY',
  'TAKE_COMPRESSED_LOAN',
  'TAKE_FLASH_LOAN',
  'TAKE_LOAN',
  'TAKE_MORTGAGE',
  'TERMINATE_PERPETUAL_LOAN',
  'TOKEN_MINT',
  'TRANSFER',
  'TRANSFER_PAYMENT',
  'TRANSFER_PAYMENT_TREE',
  'UNFREEZE',
  'UNKNOWN',
  'UNLABELED',
  'UNSTAKE_BANX',
  'UNSTAKE_SOL',
  'UNSTAKE_TOKEN',
  'UNSUB_OR_HARVEST_WEEKS',
  'UNSUB_OR_HARVEST_WEEKS_ENHANCED',
  'UPDATE_BANK_MANAGER',
  'UPDATE_BOND_OFFER_STANDARD',
  'UPDATE_CLASS_VARIANT_AUTHORITY',
  'UPDATE_CLASS_VARIANT_METADATA',
  'UPDATE_COLLECTION',
  'UPDATE_COLLECTION_OR_CREATOR',
  'UPDATE_EXTERNAL_PRICE_ACCOUNT',
  'UPDATE_FARM',
  'UPDATE_FLOOR',
  'UPDATE_HADO_MARKET_FEE',
  'UPDATE_INTEREST_PERPETUAL_MARKET',
  'UPDATE_ITEM',
  'UPDATE_OFFER',
  'UPDATE_ORDER',
  'UPDATE_PERPETUAL_MARKET',
  'UPDATE_PERPETUAL_OFFER',
  'UPDATE_POOL',
  'UPDATE_POOL_COLLECTIONS',
  'UPDATE_POOL_MORTGAGE',
  'UPDATE_POOL_STATUS',
  'UPDATE_POOL_WHITELIST',
  'UPDATE_PRIMARY_SALE_METADATA',
  'UPDATE_RAFFLE',
  'UPDATE_RECORD_AUTHORITY_DATA',
  'UPDATE_STAKING_SETTINGS',
  'UPDATE_STATS',
  'UPDATE_TRAIT_VARIANT',
  'UPDATE_TRAIT_VARIANT_AUTHORITY',
  'UPDATE_TRAIT_VARIANT_METADATA',
  'UPDATE_USABLE_AMOUNT',
  'UPDATE_VARIANT',
  'UPDATE_VAULT_OWNER',
  'UPGRADE_FOX',
  'UPGRADE_FOX_REQUEST',
  'UPGRADE_PROGRAM_INSTRUCTION',
  'VALIDATE_SAFETY_DEPOSIT_BOX_V2',
  'VERIFY_PAYMENT_MINT',
  'VERIFY_PAYMENT_MINT_TEST',
  'WHITELIST_CREATOR',
  'WITHDRAW',
  'WITHDRAW_FROM_BOND_OFFER_STANDARD',
  'WITHDRAW_GEM',
  'WITHDRAW_LIQUIDITY',
  'WITHDRAW_REWARDS_FROM_VAULT',
  'WITHDRAW_SOL_FROM_FLASH_LOAN_POOL',
];

const TABLE_HEAD = [
  { id: 'plan', label: 'ID', alignRight: false },
  { id: 'url', label: 'URL', alignRight: false },
  { id: 'txnTypes', label: 'Txn Types', alignRight: false },
  { id: 'accounts', label: 'Accounts', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'network', label: 'Network', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

const isValidUrl = (webhookURL) => {
  return (webhookURL && webhookURL.substring(0, 8) == 'https://') || webhookURL.substring(0, 7) == 'http://';
};

export default function MyWebhook({ projectID, projectConfig }) {
  const {
    endpoint,
    webhooks,
    publicKey,
    apiKey,
    setWebhooks,
    handleDelete,
    handleTest,
    billingPlan,
    billingRange,
    webhookUsage,
  } = useOutletContext();

  const [accounts, setAccounts] = useState(['']);
  const [owners, setOwners] = useState(['']);
  let [webhookType, setWebhookType] = useState('enhanced');
  const [webhookNetwork, setWebhookNetwork] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [httpErr, setHttpErr] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [webhookURL, setWebhookURL] = useState('');
  const [authHeader, setAuthHeader] = useState('');
  const [txnTypes, setTxnTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [editedWebhookId, setEditedWebhookId] = useState('');
  const [successTxn, setSuccessTxn] = useState(true);
  const [failedTxn, setFailedTxn] = useState(true);

  const handleTxnSuccessChange = () => {
    setSuccessTxn(!successTxn);
  };
  const handleTxnFailChange = () => {
    setFailedTxn(!failedTxn);
  };

  const getAllAddresses = (webhook) => {
    let allAddresses = [];
    if (webhook.accountAddresses) {
      allAddresses = webhook.accountAddresses;
    }
    if (webhook.accountAddressOwners) {
      allAddresses = [...allAddresses, ...webhook.accountAddressOwners];
    }
    allAddresses = allAddresses.filter((str) => str !== '');
    if (allAddresses.length > 25) {
      return false;
    } else {
      return allAddresses.join(', ');
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setErrMsg(null);
    clearFields();
  };
  const handleTypeSelectChange = (event) => {
    if (event.target.value === 'account') {
      setWebhookNetwork('mainnet');
    }
    setWebhookType(event.target.value);
  };

  const handleNetworkChange = (event) => {
    setWebhookNetwork(event.target.value);
  };

  const handleWebhookChange = (e) => {
    const url = e.target.value;
    if (!isValidUrl(url)) {
      setHttpErr(true);
      setErrMsg('Webhook URLs must start with either https:// or http://');
    } else {
      setHttpErr(false);
      setErrMsg(null);
    }

    setWebhookURL(url);
  };

  const handleAuthHeaderChange = (e) => {
    setAuthHeader(e.target.value);
  };

  const handleFormChange = (index, event, type) => {
    let data = type == 'owners' ? [...owners] : [...accounts];
    data[index] = event?.target?.value;
    type == 'owners' ? setOwners(data) : setAccounts(data);
  };

  const removeAccount = (index) => {
    let data = [...accounts];
    data.splice(index, 1);
    setAccounts(data);
  };

  const removeOwner = (index) => {
    let data = [...owners];
    data.splice(index, 1);
    setOwners(data);
  };

  const addAddress = () => {
    setAccounts([...accounts, '']);
  };
  const addOwner = () => {
    setOwners([...owners, '']);
  };

  const handleEditOpen = (webhook) => {
    const [network, type] =
      webhook.webhookType.replace('Devnet', '') == webhook.webhookType
        ? ['mainnet', webhook.webhookType]
        : ['devnet', webhook.webhookType.replace('Devnet', '')];
    setAccounts(webhook.accountAddresses?.length > 0 ? webhook.accountAddresses : ['']);
    setAuthHeader(webhook.authHeader);
    setOwners(
      type == 'account' ? (webhook.accountAddressOwners?.length > 0 ? webhook.accountAddressOwners : ['']) : ['']
    );
    setTxnTypes(type == 'enhanced' || type == 'discord' ? webhook.transactionTypes : []);
    setWebhookURL(webhook.webhookURL);
    setWebhookType(type);
    if (type == 'raw') {
      if (webhook.txnStatus == 'success') {
        setFailedTxn(false);
        setSuccessTxn(true);
      } else if (webhook.txnStatus == 'failed') {
        setFailedTxn(true);
        setSuccessTxn(false);
      } else {
        setFailedTxn(true);
        setSuccessTxn(true);
      }
    }
    setWebhookNetwork(network);
    setEditedWebhookId(webhook.webhookID);
    setOpen(true);
  };

  const createWebhook = async () => {
    setErrMsg(null);
    setLoading(true);

    if (!accounts.length && webhookType != 'account') {
      setErrMsg('account addresses cant be empty!');
      setLoading(false);
      return;
    }

    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].trim() == '' && webhookType != 'account') {
        setErrMsg('account addresses cant be empty!');
        setLoading(false);
        return;
      }
    }

    if (webhookType == 'account') {
      let hasAccounts = true;
      let hasOwners = true;
      if (!accounts.length) {
        hasAccounts = false;
      }
      for (let i = 0; i < accounts.length; i++) {
        if (accounts[i].trim() == '') {
          hasAccounts = false;
        }
      }
      if (!owners.length) {
        hasOwners = false;
      }
      for (let i = 0; i < owners.length; i++) {
        if (owners[i].trim() == '') {
          hasOwners = false;
        }
      }
      if (!hasOwners && !hasAccounts) {
        setErrMsg('account addresses and owner addresses cant be both empty!');
        setLoading(false);
        return;
      }
    }

    if (!txnTypes.length && webhookType != 'raw' && webhookType != 'account') {
      setErrMsg('transaction types cant be empty!');
      setLoading(false);
      return;
    }

    if (!webhookURL) {
      setErrMsg('Webhook URL cannot be empty!');
      setLoading(false);
      return;
    }

    if (!webhookType) {
      setErrMsg('Webhook type cannot be empty!');
      setLoading(false);
      return;
    }

    if (!webhookNetwork) {
      setErrMsg('Webhook network cannot be empty!');
      setLoading(false);
      return;
    }

    let txnStatus = {};
    if (webhookType == 'raw') {
      if (!failedTxn && !successTxn) {
        setErrMsg('transaction status cant be empty!');
        setLoading(false);
        return;
      }
      if (failedTxn && successTxn) {
        txnStatus = { txnStatus: 'all' };
      } else if (!failedTxn && successTxn) {
        txnStatus = { txnStatus: 'success' };
      } else {
        txnStatus = { txnStatus: 'failed' };
      }
    }
    const transactionTypes = webhookType == 'enhanced' || webhookType == 'discord' ? txnTypes : ['Any'];
    const accountData =
      webhookType == 'account'
        ? { accountAddressOwners: owners?.length == 1 && owners[0] == '' ? [] : owners, encoding: 'jsonParsed' }
        : {};
    if (webhookNetwork == 'devnet') {
      webhookType += 'Devnet';
    }
    try {
      if (editedWebhookId === '') {
        const { data } = await axios.post(
          `${endpoint}/projects/${projectID}/webhooks`,
          {
            wallet: publicKey,
            project: projectID,
            webhookURL,
            transactionTypes,
            accountAddresses: accounts.length == 1 && accounts[0] == '' ? [] : accounts,
            webhookType: webhookType,
            authHeader: authHeader,
            ...accountData,
            ...txnStatus,
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } }
        );
        const ownersAddresses = data.accountAddressOwners
          ? { accountAddressOwners: data.accountAddressOwners.length > 0 ? data.accountAddressOwners : [''] }
          : {};
        setWebhooks([
          ...webhooks,
          {
            webhookID: data.webhookID,
            webhookURL: data.webhookURL,
            transactionTypes: data.transactionTypes,
            accountAddresses: data.accountAddresses.length > 0 ? data.accountAddresses : [''],
            webhookType: data.webhookType,
            authHeader: data.authHeader,
            ...ownersAddresses,
            ...txnStatus,
          },
        ]);
      } else {
        const { data } = await axios.put(
          `${endpoint}/projects/${projectID}/webhooks/${editedWebhookId}`,
          {
            wallet: publicKey,
            project: projectID,
            webhookURL,
            transactionTypes,
            accountAddresses: accounts.length == 1 && accounts[0] == '' ? [] : accounts,
            webhookType: webhookType,
            authHeader: authHeader,
            ...accountData,
            ...txnStatus,
          },
          { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } }
        );
        const ownersAddresses = data.accountAddressOwners
          ? { accountAddressOwners: data.accountAddressOwners.length > 0 ? data.accountAddressOwners : [''] }
          : {};
        const hookIndex = webhooks.findIndex((hook) => hook.webhookID === editedWebhookId);
        let modifiedWebhooks = webhooks;
        modifiedWebhooks[hookIndex] = {
          webhookID: data.webhookID,
          webhookURL: data.webhookURL,
          transactionTypes: data.transactionTypes,
          accountAddresses: data.accountAddresses.length > 0 ? data.accountAddresses : [''],
          webhookType: data.webhookType,
          authHeader: data.authHeader,
          ...ownersAddresses,
          ...txnStatus,
        };
        setWebhooks(modifiedWebhooks);
      }
      setOpen(false);
      clearFields();
    } catch (e) {
      if (e?.response?.data) {
        setErrMsg(e.response.data);
      } else {
        setErrMsg(
          `Something went wrong! Please try again or contact us on Discord for urgent support. Error: ${e?.response?.data}`
        );
      }
    }
    setLoading(false);
  };

  const clearFields = () => {
    setAccounts(['']);
    setOwners(['']);
    setTxnTypes([]);
    setWebhookURL('');
    setWebhookType('enhanced');
    setWebhookNetwork(null);
    setEditedWebhookId('');
    setFailedTxn(true);
    setSuccessTxn(true);
  };

  if (publicKey == undefined || apiKey == undefined) {
    return (
      <Page title="Webhook">
        <Container maxWidth="xl">
          <Alert severity="info">Please connect your wallet.</Alert>
        </Container>
      </Page>
    );
  }

  const getRemainingWebhooks = (webhooks) => {
    return projectConfig?.webhooks ? projectConfig.webhooks - webhooks?.length : 0;
  };
  const showThrottleMessage = () => billingPlan == SubscriptionPlansIds.Free && webhookUsage > 1000000;

  return (
    <Page title="Webhook">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Webhooks
        </Typography>
        <Typography sx={{ border: '1px #E84125 solid', borderRadius: '10px', padding: 2 }} variant="body1">
          <b>Webhooks let you listen to events on-chain and trigger certain actions when these events happen. </b>{' '}
          Webhook changes may take up to 2 minutes to take effect. Please contact us on{' '}
          <a href="http://discord.gg/HjummjUXgq">Discord</a> for any support! <br />
          <br />
          <br /> To work with webhooks programatically via our APIs and learn more about webhooks —{' '}
          <a href="https://docs.helius.xyz/webhooks/webhooks-api-reference"> please see our API documentation.</a>
        </Typography>

        {webhookUsage != null && billingRange != null && (
          <Grid container sx={{ mt: 2 }} spacing={3}>
            <Grid item xs={12} sm={4} md={4}>
              <AppWidgetSummary
                title="Webhook Events (Current Cycle)"
                total={webhookUsage}
                color="info"
                icon={'ant-design:dashboard-filled'}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <AppWidgetSummary
                title="Billing Cycle"
                total={billingRange}
                color="info"
                icon={'ant-design:dashboard-filled'}
              />
            </Grid>
          </Grid>
        )}

        {
          <Stack direction="row" alignItems="center" justifyContent="space-between" mt={7}>
            <Typography variant="h5" gutterBottom>
              My Webhooks
            </Typography>
            {getRemainingWebhooks(webhooks) != 0 && (
              <Button
                variant="contained"
                onClick={handleClickOpen}
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Webhook
              </Button>
            )}
          </Stack>
        }
        {getRemainingWebhooks(webhooks) == 0 && (
          <Alert sx={{ mb: 1 }} severity="warning">
            {' '}
            You have no remaining webhooks left on this plan. Please upgrade your plan to create more webhooks.
          </Alert>
        )}
        {showThrottleMessage() && (
          <Alert severity="warning">
            {' '}
            You have exceeded your quota of free webhooks calls. Please upgrade your plan.
          </Alert>
        )}
        {webhooks && webhooks.length != 0 && (
          <Card sx={{ mt: 4 }}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} size="small">
                  <UserListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {webhooks.map((x, i) => (
                      <TableRow key={x.webhookID} hover tabIndex={-1}>
                        <TableCell sx={{ maxWidth: '10vw' }} align="left">
                          {x.webhookID}
                        </TableCell>
                        <TableCell align="left">{x.webhookURL}</TableCell>
                        <TableCell align="left">{x.transactionTypes.join(', ')}</TableCell>
                        <TableCell align="left">
                          {(() => {
                            const allAddresses = getAllAddresses(x);
                            if (!allAddresses) {
                              return 'Too many addresses to be shown on UI. Please check via API.';
                            } else {
                              return allAddresses;
                            }
                          })()}
                        </TableCell>
                        <TableCell align="left">
                          {x.webhookType && x.webhookType.endsWith('Devnet')
                            ? x.webhookType.slice(0, -6)
                            : x.webhookType ?? 'enhanced'}
                        </TableCell>
                        <TableCell align="left">{x.webhookType.endsWith('Devnet') ? 'devnet' : 'mainnet'}</TableCell>
                        <TableCell align="left">
                          <ButtonGroup>
                            <Tooltip title="Delete">
                              <IconButton
                                sx={{ mt: -1, mx: 1 }}
                                onClick={() => {
                                  if (window.confirm('Are you sure you wish to delete this webhook?'))
                                    handleDelete(x.webhookID);
                                }}
                                aria-label="delete"
                                color="primary"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Edit">
                              <IconButton
                                sx={{ mt: -1, mx: 1 }}
                                onClick={() => handleEditOpen(x)}
                                aria-label="edit"
                                color="primary"
                                disabled={(() => {
                                  const allAddresses = getAllAddresses(x);
                                  if (!allAddresses) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                })()}
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Error Logs">
                              <IconButton
                                sx={{ mt: -1, mx: 1 }}
                                component={RouterLink}
                                to={`webhook-logs/${x.webhookID}`}
                                aria-label="logs"
                                color="primary"
                              >
                                <ArticleIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Send Test">
                              <IconButton
                                sx={{ mt: -1, mx: 1 }}
                                onClick={() => {
                                  if (window.confirm('Are you sure you wish to send a test transaction this webhook?'))
                                    handleTest(x.webhookID);
                                }}
                                aria-label="test"
                                color="primary"
                              >
                                <SendIcon />
                              </IconButton>
                            </Tooltip>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Container>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Configure Webhook</DialogTitle>
        {errMsg && <Alert severity="error">{errMsg}</Alert>}
        <DialogContent>
          <FormControl sx={{ mt: 1, mb: 1, ml: 1, width: '100%' }}>
            <FormLabel id="network-radio-button-label">
              Network
              <Tooltip
                arrow
                sx={{ mt: -0.5 }}
                title="This determines which network to use. Selecting devnet will listen to txns only on the devnet cluster."
              >
                <IconButton>
                  <QuestionIcon />
                </IconButton>
              </Tooltip>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="network-radio-button-label"
              name="row-2-radio-buttons-group"
              value={webhookNetwork}
              onChange={handleNetworkChange}
            >
              <FormControlLabel
                value="mainnet"
                control={<Radio disabled={webhookType == 'account' ? true : false} />}
                label="Mainnet"
              />
              <FormControlLabel
                value="devnet"
                control={<Radio disabled={webhookType == 'account' ? true : false} />}
                label="Devnet"
              />
            </RadioGroup>
          </FormControl>
          {webhookType == 'raw' && (
            <FormControl sx={{ mt: 1, mb: 1, ml: 1, width: '100%' }}>
              <FormLabel id="txn-status-radio-button-label">
                Transaction Status
                <Tooltip
                  arrow
                  sx={{ mt: -0.5 }}
                  title="This will filter the transactions you will receive based on their status."
                >
                  <IconButton>
                    <QuestionIcon />
                  </IconButton>
                </Tooltip>
              </FormLabel>
              <div role="group" aria-labelledby="txn-status-radio-button-label">
                <FormControlLabel
                  control={<Checkbox checked={successTxn} onChange={handleTxnSuccessChange} name="success" />}
                  label="Succeded"
                />
                <FormControlLabel
                  control={<Checkbox checked={failedTxn} onChange={handleTxnFailChange} name="failed" />}
                  label="Failed"
                />
              </div>
            </FormControl>
          )}
          <FormControl sx={{ width: '100%', mb: 1, mt: 2 }}>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Webhook Type
              <Tooltip
                arrow
                sx={{ mt: -0.5 }}
                title="This determines what payload the webhook is called with. Select 'enhanced' for specific parsed txn types. Select 'raw' for native Solana txns. Select Discord for Discord bots."
              >
                <IconButton>
                  <QuestionIcon />
                </IconButton>
              </Tooltip>
            </FormLabel>
            <Select
              value={webhookType}
              onChange={handleTypeSelectChange}
              inputProps={{
                name: 'mui-select-type',
                id: 'mui-select-type',
              }}
            >
              <MenuItem value="enhanced">Enhanced</MenuItem>
              <MenuItem value="raw">Raw</MenuItem>
              <MenuItem value="discord">Discord</MenuItem>
              {webhookType == 'account' && <MenuItem value="account">Account</MenuItem>}
            </Select>
            <FormHelperText>Select the type of webhook you wish to receive.</FormHelperText>
          </FormControl>

          {(webhookType == 'enhanced' || webhookType == 'discord') && (
            <FormControl fullWidth sx={{ mb: 1, mt: 2 }}>
              <Autocomplete
                value={txnTypes}
                multiple
                id="txn-types"
                options={txnTypesList}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                onChange={(event, value) => {
                  setTxnTypes(value);
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} label="Transaction Type(s)" placeholder="" />}
                limitTags={2}
              />
              <FormHelperText>
                Select the type(s) of transactions to capture. Hint: select "Any" if interested in all transactions.
              </FormHelperText>
            </FormControl>
          )}

          <FormControl fullWidth>
            <TextField
              value={webhookURL}
              onChange={handleWebhookChange}
              required
              sx={{ mb: 1 }}
              margin="normal"
              id="name"
              label="Webhook URL"
              helperText="Enter the URL of your webhook. This could be a lambda, a custom API endpoint, etc."
              type="text"
              fullWidth
              autoComplete="off"
            />
          </FormControl>
          {(webhookType == 'enhanced' || webhookType == 'raw' || webhookType == 'account') && (
            <FormControl fullWidth>
              <TextField
                value={authHeader}
                onChange={handleAuthHeaderChange}
                sx={{ mb: 1 }}
                margin="normal"
                id="name"
                label="Authentication Header"
                helperText="Enter an authentication header to pass into the post requests to your webhook."
                type="text"
                fullWidth
                autoComplete="off"
              />
            </FormControl>
          )}
          {accounts.map((v, i) => {
            return (
              <Stack key={i} direction="row" spacing={2}>
                <FormControl fullWidth required>
                  <TextField
                    value={v}
                    onChange={(e) => handleFormChange(i, e, 'accounts')}
                    required={webhookType == 'account' ? false : true}
                    sx={{ mb: 1 }}
                    margin="normal"
                    id="name"
                    label="Account Address"
                    type="text"
                    fullWidth
                  />
                </FormControl>
                {(i !== 0 || accounts.length != 1) && (
                  <Button onClick={() => removeAccount(i)} endIcon={<DeleteIcon />}></Button>
                )}
              </Stack>
            );
          })}
          <Button sx={{ mt: 1 }} variant="contained" onClick={addAddress} endIcon={<AddIcon />}>
            Add Addresses
          </Button>

          {webhookType == 'account' &&
            owners.map((v, i) => {
              return (
                <Stack key={i} direction="row" spacing={2}>
                  <FormControl fullWidth required>
                    <TextField
                      value={v}
                      onChange={(e) => handleFormChange(i, e, 'owners')}
                      required={false}
                      sx={{ mb: 1 }}
                      margin="normal"
                      id="name"
                      label="Owner Address"
                      type="text"
                      fullWidth
                    />
                  </FormControl>
                  {(i !== 0 || owners.length != 1) && (
                    <Button onClick={() => removeOwner(i)} endIcon={<DeleteIcon />}></Button>
                  )}
                </Stack>
              );
            })}
          {webhookType == 'account' && (
            <Button sx={{ mt: 1 }} variant="contained" onClick={addOwner} endIcon={<AddIcon />}>
              Add Owners
            </Button>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '24px',
            paddingLeft: '24px',
          }}
        >
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={createWebhook} disabled={httpErr}>
            {isLoading && <CircularProgress />}
            {!isLoading && 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
