import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Typography, Container, Box, LinearProgress, Snackbar } from '@mui/material';

// components
import Page from '../components/Page';
import axios from 'axios';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export const PageEmailVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [progress, setProgress] = useState(3);

  useEffect(() => {
    const endpoint = process.env.REACT_APP_API_CLIENT;
  
    const queryParams = new URLSearchParams(window.location.search);
    const verificationToken = queryParams.get('token');
    
    if(endpoint && verificationToken) {
      verifyEmailToken(endpoint, verificationToken).then().catch((e) => {})
    }
  }, []);

  useEffect(() => {
    if(successMessage) {
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 1) {
            clearInterval(timer);
            redirectToDashboard();
            return 1;
          }
          return Math.max(oldProgress - 1, 1);
        });
      }, 1000); // Update progress every 1000ms

      return () => {
        clearInterval(timer);
      };
    }
  }, [successMessage]);

  const verifyEmailToken =  async (endpoint, verificationToken) => {
    setIsLoading(true);
    setErrorMsg(null);
    setSuccessMessage(null);
    try {
      await axios.post(`${endpoint}/email-verification`, {
        verificationToken
      });
      setSuccessMessage("Your project verified successfully.")
    } catch(e) {
      if(e.response?.status !== 500 && e.response?.data) {
        setErrorMsg(e.response.data);
      } else {
        setErrorMsg('There was an error verifying project, try again later or contact support');
      }
    } finally {
      setIsLoading(false);
    }
  }
  
  const redirectToDashboard = () => {
    window.location.href = `${window.location.origin}/dashboard/app`
  }

  return (
    <Page title="Project Verification">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography width={'100%'}>
            { isLoading ? <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box> : null }
            {
              errorMsg && <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity='error'>
                  {errorMsg}
                </Alert>
              </Snackbar>
            }

            {
              successMessage && <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert severity='success'>
                  {successMessage}
                </Alert>
              </Snackbar>
            }
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  );
}
