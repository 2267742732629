import LinearProgress from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import DashboardNavbar from '../dashboard/DashboardNavbar';
import DashboardSidebar from '../dashboard/DashboardSidebar';
import PrepaidCredits from 'src/pages/PrepaidCredits';
import { useAuth0 } from "@auth0/auth0-react";
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
export default function PrepaidCreditsLayout({
  publicKey,
  endpoint,
  isLoading,
  apiKey,
  open,
  setOpen,
  apiKeys,
  setCurrentApiKey,
  billingStart,
  loginType,
  onLogout,
  projectID,
  projectsList,
  setCurrentProject,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
  billingPlan,
  setIsProcessingSSOCryptoSub,
  rpcEndpoint,
  cryptoSub,
  portalConfigs,
}) {
  const { isAuthenticated: isUsingAuth0 } = useAuth0();
  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        loginType={loginType}
        onLogout={onLogout}
        projectID={projectID}
        projectsList={projectsList}
        setCurrentProject={setCurrentProject}
      />
      {apiKey != undefined && publicKey != undefined && (
        <DashboardSidebar
          billingStart={billingStart}
          setCurrentApiKey={setCurrentApiKey}
          apiKeys={apiKeys}
          apiKey={apiKey}
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          userID={publicKey}
          projectID={projectID}
          projectsList={projectsList}
          refetchAllProjects={refetchAllProjects}
          projectConfig={projectConfig}
          fetchProjectDetails={fetchProjectDetails}
          
          currentPlan={billingPlan}
          isSSOUser={isUsingAuth0}
          setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
          currentUser={publicKey}
          rpcEndpoint={rpcEndpoint}
          endpoint={endpoint}
          setCurrentProject={setCurrentProject}
        />
      )}
      <MainStyle>
        {isLoading && <LinearProgress />}
        {!isLoading && (
          <PrepaidCredits
            endpoint={endpoint}
            projectID={projectID}
            currentPlan={billingPlan}
            isSSOUser={isUsingAuth0}
            setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
            currentUser={publicKey}
            rpcEndpoint={rpcEndpoint}
            cryptoSub={cryptoSub}
            portalConfigs={portalConfigs}
          />
        )}
      </MainStyle>
    </RootStyle>
  );
}
