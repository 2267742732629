import {useState} from 'react';
import {Box, Alert, Button, TextField, FormControl, CircularProgress, Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const StyledAlert = styled(Alert)({
  '& .MuiAlert-icon': {
    padding: '14px 0', // Adjust your icon size here
  },
  '& .MuiAlert-message': {
    width: '100%'
  }
});

export const SendEmailVerification = ({endpoint, projectId}) => {
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, sendOpenModal] = useState(false);
  const [email, setEmail] = useState('');

  const handleTextChange = (event) => {
    setEmail(event.target.value);
  };

  const onCloseModal = () => {
    setSuccessMessage(null);
    setErrorMsg(null);
    sendOpenModal(false);
    setEmail('');
  }
  
  const sendEmail = async () => {
    setErrorMsg(null);
    setSuccessMessage(null);
    setIsLoading(true);
    try {
      await axios.post(
        `${endpoint}/projects/${projectId}/verification-email`,
        {
          emailId: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setSuccessMessage("We've sent a verification email to your inbox. Please click the link provided in the email to complete verification and unlock full access.")
    } catch(e) {
      if(e.response?.status !== 500 && e.response?.data) {
        setErrorMsg(e.response.data);
      } else {
        setErrorMsg('There was an error sending verification email, try again later or contact support');
      }
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <Grid item xs={12} md={12} lg={12} style={{
      paddingTop: '1px!important',
      marginTop: '1px!important'
    }}>
      <Box sx={{ width: '100%' }}>
        <StyledAlert severity="error">
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}>
            <span>Your free project is not verified. Please link your project to a verified email address to unlock higher TPS limits.</span>
            <Button variant="contained"  onClick={() => sendOpenModal(true)}>Add Email</Button>
          </div>
        </StyledAlert>    
        <Dialog sx={{ overflowY: 'hidden' }} open={openModal} onClose={onCloseModal} fullWidth={true} maxWidth="xs">
          <DialogTitle>Project Verification</DialogTitle>
          <DialogContent>
            {errorMsg ? (
              <Alert sx={{ mb: 3 }} severity='error'>
                {errorMsg}
              </Alert>
            ) : (
              <div></div>
            )}
            {successMessage ? (
              <Alert sx={{ mb: 3 }} severity='success'>
                {successMessage}
              </Alert>
            ) : (
              <div></div>
            )}
            <FormControl style={{
              width: '100%',
            }}>
              <TextField
                autoFocus
                label='Email'
                variant='standard'
                value={email}
                onChange={handleTextChange}
                required
                disabled={!!successMessage}
              />
            </FormControl>
          </DialogContent>
          <DialogActions>
            {
              isLoading ? <CircularProgress size={'20px'} /> : <Button disabled={!!successMessage} onClick={sendEmail} color='secondary'>
                Send
              </Button>
            }
            <Button onClick={onCloseModal}>{successMessage ? 'Close' : 'Cancel'}</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Grid>
  );
}
