import { PostHogProvider } from 'posthog-js/react';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';

export const PostHogProviderWrapper = ({ children }) => {
  if (isDev) return <>{children}</>;
  return (
    <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
};
