import { Grid } from '@mui/material';
import {Banner} from './Banner';

export const CardPayBanner = ({plansCardData}) => {
  return (
    <div>
      <Grid container spacing={2}>
        {
          plansCardData.map((dataObj, j) =>  <Grid item xs={12} sm={6} md={4} lg={4} key={j}>
            <Banner
              dataObj={dataObj}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
