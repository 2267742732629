import * as React from 'react';
import { useState } from 'react';
import { Link as RouterLink, useOutletContext } from 'react-router-dom';
import {
  Grid,
  TableRow,
  TableBody,
  TableCell,
  Card,
  Paper,
  Tooltip,
  IconButton,
  TableContainer,
  Table,
  Container,
  Typography,
  Stack,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Chip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Scrollbar from '../components/Scrollbar';
import { UserListHead } from '../sections/@dashboard/user';
import DeleteIcon from '@mui/icons-material/Delete';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import BarChartIcon from '@mui/icons-material/BarChart';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EmailIcon from '@mui/icons-material/Email';
import WarningIcon from '@mui/icons-material/Warning';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import axios from 'axios';

const TABLE_HEAD = [
  { id: 'order_id', label: 'Order ID', alignRight: false },
  { id: 'host_id', label: 'Host ID', alignRight: false },
  { id: 'RPC url', label: 'URL', alignRight: false },
  { id: 'gRPC url', label: 'gRPC url', alignRight: false },
  { id: 'gRPC token', label: 'gRPC token', alignRight: false },
  { id: 'region', label: 'Region', alignRight: false },
  { id: 'solana_version', label: 'Solana version', alignRight: false },
  { id: 'geyser_type', label: 'Geyser type', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'subscriptionExpiresAt', label: 'Subscription expires at', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

export default function MyDedicatedNodes({ endpoint }) {
  const { dedicatedNodes, apiKey, handleDelete, isLoadingDedicatedNodes, handleMigrate } = useOutletContext();

  const [open, setOpen] = useState(false);
  const [openMigrate, setOpenMigrate] = useState(false);
  const [dedicatedNode, setDedicatedNode] = useState(null);
  const [creatingExtendLink, setCreatingExtendLink] = useState(null);

  const isPurchaseNodeEnabled = true;

  const maximumDedicatedNodes = 3;

  const statusen = {
    Ordering:
      "The dedicated node you ordered is on backorder. Rest assured, we're working to fulfil your order as quickly as possible, which may take up to 4-14 days. We appreciate your patience and will keep you updated on the progress.",
    Deploying: 'Your node is currently being deployed and will be available shortly.',
    Active:
      'Congratulations! Your dedicated node is active and ready to use. Details on how to access your node have been sent to your registered email.',
    Failed:
      'Your server deployment was not successful. Please rest assured, our team is already working on resolving the problem. Your patience and understanding are greatly appreciated.',
  };

  const handleClickOpen = (dedicatedNode) => {
    setDedicatedNode(dedicatedNode);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenMigrate = (dedicatedNode) => {
    setDedicatedNode(dedicatedNode);
    setOpenMigrate(true);
  };

  const handleCloseMigrate = () => {
    setOpenMigrate(false);
  };

  const handlerExtendNodePlan = async (currentNode) => {
    if (currentNode) {
      setCreatingExtendLink(currentNode.order_id);
      try {
        const { data } = await axios.get(`${endpoint}/dedicated-nodes/${currentNode.order_id}/extend-plan-link`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
        });
        if (data && data.url) {
          window.location.replace(data.url);
        }
      } catch (e) {
      } finally {
        setCreatingExtendLink(null);
      }
    }
  };

  if (apiKey == undefined) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid sx={{ mt: -30 }} item xs={3}>
          <Typography variant="h6">Sign in to start building with Helius.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Page title="Dedicated Nodes">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Dedicated Node</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            This action <strong>cannot</strong> be undone and will permanently delete the Dedicated Node{' '}
            <Typography
              component="code"
              color="primary"
              sx={{
                backgroundColor: '#F6F6F6',
                border: '1px solid #DADADA',
                borderRadius: '4px',
                padding: '2px 4px 0 4px',
              }}
            >
              {dedicatedNode?.dns}
            </Typography>
            . Any remaining period will <strong>not</strong> be refunded.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleDelete(dedicatedNode?.order_id);
              setOpen(false);
            }}
            autoFocus
            sx={{ textTransform: 'none' }}
          >
            I understand the consequences, delete this dedicated node
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openMigrate}
        onClose={handleCloseMigrate}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Upgrade Dedicated Node</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseMigrate}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-description">
            This action <strong>cannot</strong> be undone and will permanently upgrade the Dedicated Node{' '}
            <Typography
              component="code"
              color="primary"
              sx={{
                backgroundColor: '#F6F6F6',
                border: '1px solid #DADADA',
                borderRadius: '4px',
                padding: '2px 4px 0 4px',
              }}
            >
              {dedicatedNode?.dns}
            </Typography>
            .
            <List>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <WarningIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Before you upgrade, please consider the following:"
                  secondary={
                    <React.Fragment>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>The upgrade requires 2 hours of downtime.</li>
                        <li>The upgrade will create a new RPC URL requiring an API key.</li>
                        <li>The upgrade will create a new gRPC URL requiring an x-token header API key.</li>
                      </ul>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <RocketLaunchIcon />
                </ListItemIcon>
                <ListItemText
                  primary="New features after the upgrade:"
                  secondary={
                    <React.Fragment>
                      <ul style={{ marginLeft: '20px' }}>
                        <li>Usage dashboard to monitor your node.</li>
                        <li>Automatic failover for RPC and Yellowstone gRPC (Jito gRPC coming soon)</li>
                        <li>Zero down upgrades RPC and Yellowstone gRPC (Jito gRPC coming soon)</li>
                      </ul>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="You will receive an email notification once the upgrade is complete." />
              </ListItem>
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleMigrate(dedicatedNode?.order_id);
              setOpenMigrate(false);
            }}
            autoFocus
            sx={{ textTransform: 'none' }}
          >
            I understand, upgrade this dedicated node
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Dedicated Nodes
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Manage your Dedicated Nodes here. Please contact us on <a href="https://discord.gg/HjummjUXgq">Discord</a> for
          support.
        </Typography>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={7}>
          <Typography variant="h5" gutterBottom>
            My Dedicated Nodes
          </Typography>

          {isPurchaseNodeEnabled &&
            dedicatedNodes &&
            dedicatedNodes.length < maximumDedicatedNodes &&
            dedicatedNodes.length !== 0 && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/dedicated-nodes/new"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                New Dedicated Node
              </Button>
            )}
        </Stack>

        {dedicatedNodes && dedicatedNodes.length >= maximumDedicatedNodes && (
          <Alert sx={{ mt: 4 }} severity="warning">
            {' '}
            You have reached the maximum number of dedicated nodes. For additional nodes, please contact us on{' '}
            <a href="https://discord.gg/HjummjUXgq">Discord</a>.
          </Alert>
        )}

        {dedicatedNodes && dedicatedNodes.length !== 0 ? (
          <Card sx={{ mt: 4 }}>
            <Scrollbar>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} size="small">
                  <UserListHead headLabel={TABLE_HEAD} />
                  <TableBody>
                    {dedicatedNodes.map((dedicatedNode) => (
                      <TableRow key={dedicatedNode.order_id} hover tabIndex={-1}>
                        <TableCell sx={{ maxWidth: '10vw' }} align="left">
                          {dedicatedNode.order_id}
                        </TableCell>
                        <TableCell sx={{ maxWidth: '10vw' }} align="left">
                          {dedicatedNode.host_id}
                        </TableCell>
                        <TableCell align="left">{`https://${
                          dedicatedNode.dns + (dedicatedNode.api_key ? `?api-key=${dedicatedNode.api_key}` : '')
                        }`}</TableCell>
                        <TableCell align="left">
                          {dedicatedNode.geyser_url ? `https://${dedicatedNode.geyser_url}` : ''}
                        </TableCell>
                        <TableCell align="left">{dedicatedNode.geyser_x_token}</TableCell>
                        <TableCell align="left" sx={{ textTransform: 'uppercase' }}>
                          {dedicatedNode.region}
                        </TableCell>
                        <TableCell align="left">{dedicatedNode.solana_version}</TableCell>
                        <TableCell align="left">{dedicatedNode.geyser_type}</TableCell>
                        <TableCell align="left">
                          <Tooltip title={statusen[dedicatedNode.status]}>
                            <Chip label={dedicatedNode.status} size="small" variant="outlined" />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="left">
                          {dedicatedNode.cryptoSub && new Date(dedicatedNode.subscriptionExpiresAt).toLocaleString()}
                        </TableCell>
                        <TableCell align="left">
                          <ButtonGroup>
                            {dedicatedNode.cryptoSub && creatingExtendLink !== dedicatedNode.order_id && (
                              <Tooltip title="Extend Plan">
                                <IconButton
                                  onClick={() => handlerExtendNodePlan(dedicatedNode)}
                                  aria-label="delete"
                                  color="primary"
                                >
                                  <CurrencyExchangeIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                            {creatingExtendLink === dedicatedNode.order_id && (
                              <LoadingButton loading={true} variant="text" />
                            )}

                            {dedicatedNode.dns?.includes('-dedicated') && (
                              <Tooltip title="Metrics">
                                <IconButton
                                  component={RouterLink}
                                  to={`/dedicated-nodes/${dedicatedNode.host_id}`}
                                  aria-label="metrics"
                                  color="primary"
                                >
                                  <BarChartIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            {!dedicatedNode.dns?.includes('-dedicated') && dedicatedNode.status !== 'Ordering' && (
                              <Tooltip title="Upgrade">
                                <IconButton
                                  onClick={() => {
                                    handleClickOpenMigrate(dedicatedNode);
                                  }}
                                  aria-label="Upgrade"
                                  color="primary"
                                >
                                  <UpgradeIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  handleClickOpen(dedicatedNode);
                                }}
                                aria-label="delete"
                                color="primary"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        ) : (
          <Card
            sx={{
              gap: 1,
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 10,
            }}
          >
            {isLoadingDedicatedNodes ? (
              <CircularProgress />
            ) : isPurchaseNodeEnabled ? (
              <>
                <Typography variant="h4" gutterBottom>
                  No Instances
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  align="center"
                  fontWeight={400}
                  sx={{ maxWidth: '600px' }}
                  gutterBottom
                >
                  Need the lowest possible latency, high performance requirements, or additional security? Order a
                  Dedicated Node with an optional Geyser plugin at any of our worldwide datacenter locations.
                </Typography>
                <Button
                  size="large"
                  variant="contained"
                  component={RouterLink}
                  to="/dedicated-nodes/new"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New Dedicated Node
                </Button>
                <Typography variant="caption" display="block" gutterBottom>
                  advanced users only
                </Typography>
              </>
            ) : (
              <>
                Ordering new nodes is currently unavailable as we fulfil existing orders. Please check back again in a
                few hours.
              </>
            )}
          </Card>
        )}
      </Container>
    </Page>
  );
}
