import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Alert, Box, Button, Drawer, List, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import ProjectsInfo from '../../components/ProjectsInfo';
import Referrals from '../../components/Referrals';
import NavSection, { NavItem } from '../../components/NavSection';
import navConfig, { getIcon } from './NavConfig';
import { hasAccess, DRAWER_WIDTH } from '../../utils/utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import APIKeys from 'src/pages/APIKeys';
import Projects from 'src/pages/Projects';
import axios from 'axios';

const endpoint = process.env.REACT_APP_API_CLIENT;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  billingStart,
  setCurrentApiKey,
  apiKeys,
  apiKey,
  userID,
  projectID,
  projectsList,
  refetchAllProjects,
  projectConfig,
  fetchProjectDetails,
  endpoint,
  setCurrentProject,
}) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [openChangeProject, setOpenChangeProject] = useState(false);
  const [keys, setKeys] = useState(apiKeys);
  const [errorMsg, setErrorMsg] = useState();
  const [deleteKeyErrorMsg, setDeleteKeyErrorMsg] = useState();
  const [openConfirm, setOpenConfirm] = useState();
  const [deletingKey, setDeletingKey] = useState();
  const [isDeletingKey, setIsDeletingKey] = useState(false);
  const [isUpdatingKey, setIsUpdatingKey] = useState(false);
  const [openReferrals, setOpenReferrals] = useState(false);

  const canAddNewKey = () => {
    if(projectConfig?.apiKeys > 1) {
      return true
    }
    return false
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickInvite = () => {
    setOpenInvite(true);
  };

  const handleClickChangeProject = () => {
    setOpenChangeProject(true);
  };

  const handleReferrals = () => {
    setOpenReferrals(!openReferrals);
  };

  const handleProjectChange = (id) => {
    setOpenChangeProject(false);
    setCurrentProject(id);
  };

  const handleClose = () => {
    setErrorMsg('');
    setOpen(false);
    setOpenInvite(false);
    setOpenChangeProject(false);
  };


  const handleCloseConfirm = () => {
    setDeleteKeyErrorMsg('');
    setOpenConfirm(false);
    setDeletingKey(null);
    setIsDeletingKey(false);
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  useEffect(() => {
    setKeys(apiKeys);
  }, [apiKeys]);

  const addAPIKey = async () => {
    try {
      setErrorMsg('');
      await axios.post(
        `${endpoint}/projects/${projectID}/add-key`,
        {
          userId: userID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      await fetchProjectDetails(projectID, false);
    } catch (e) {
      console.error('Failed to generate api key');
      if(e?.response?.data?.error) {
        setErrorMsg(e.response.data.error);
      } else {
        setErrorMsg('There was an error generating a new key, try again later or contact support');
      }
    }
  };

  const updateAPIKey = async (keyId, keyName) => {
    try {
      setErrorMsg('');
      setIsUpdatingKey(true);
      await axios.put(
        `${endpoint}/projects/${projectID}/keys/${keyId}`,
        {
          keyName: keyName,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      await fetchProjectDetails(projectID, false);
    } catch (e) {
      console.error('Failed to update api key');
      if (e?.response?.data?.error) {
        setErrorMsg(e.response.data.error);
      } else {
        setErrorMsg('There was an error updating api key, try again later or contact support');
      }
    } finally {
      setIsUpdatingKey(false);
    }
  };

  const handleDeleteKey = async () => {
    try {
      if(!deletingKey) {
        return
      }
      setDeleteKeyErrorMsg('');
      setIsDeletingKey(true);
      const { data } = await axios.delete(
        `${endpoint}/api-keys/${deletingKey}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      await fetchProjectDetails(projectID, false);
      handleCloseConfirm();
    } catch (e) {
      console.error('Failed to delete api key');
      if(e?.response?.data?.error) {
        setDeleteKeyErrorMsg(e.response.data.error);
      } else {
        setDeleteKeyErrorMsg('There was an error deleting api key, try again later or contact support');
      }
    } finally {
      setIsDeletingKey(false);
    }
  };
  
  const getNavConfig = () => {
    const config = navConfig;
    if(!config.find((o) => o.path === '/prepaid-credits')) {
      const project = projectsList.find((proj) => proj.id === projectID);
      // allow to buy prepaid credits to crypto subscriptions
      if(project?.subscription?.cryptoSub) {
        config.splice(config.length - 1, 0, {
          title: 'Prepaid Credits',
          path: '/prepaid-credits',
          icon: getIcon('ion:card-outline'),
          gated: true,
        });
      }
    }
    return config
  }
  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <List disablePadding sx={{ pl: 1, mb: -1 }} onClick={handleClickOpen}>
        <NavItem
          key={'api-key'}
          active={() => false}
          item={{ title: 'API Keys', path: '#', icon: getIcon('material-symbols:vpn-key'), gated: true }}
        />
      </List>

      <NavSection navConfig={getNavConfig()} />

      {/* <List disablePadding sx={{ pl: 1, mt: -1 }} onClick={handleReferrals}>
        <NavItem
          key={'api-key'}
          active={() => false}
          item={{ title: 'Referrals', path: '#', icon: getIcon('tdesign:money'), gated: true }}
        />
      </List> */}

      {
        hasAccess(projectsList, projectID, userID) && <List disablePadding sx={{ pl: 1 }} onClick={handleClickInvite}>
          <NavItem
            key={'api-key'}
            active={() => false}
            item={{ title: 'Project Settings', path: '#', icon: getIcon('ic:baseline-settings'), gated: true }}
          />
        </List>
      }

      {
        <List disablePadding sx={{ pl: 1 }} onClick={handleClickChangeProject}>
          <NavItem
            key={'api-key'}
            active={() => false}
            item={{ title: 'Switch Project', path: '#', icon: getIcon('ic:baseline-sync'), gated: true }}
          />
        </List>
      }

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Button href="https://docs.helius.xyz" target="_blank" variant="contained">
            API Docs
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      <div>
        <Dialog sx={{ overflowY: 'hidden' }} open={open} onClose={handleClose} fullWidth maxWidth="lg">
          <DialogTitle>API Keys</DialogTitle>
          <DialogContent>
            {errorMsg ? (
              <Alert sx={{ mb: 3 }} severity="error">
                {errorMsg}
              </Alert>
            ) : (
              <div></div>
            )}
            <DialogContentText>
              To view metrics and configure settings for other API keys, please click <b>"Switch To This Key"</b> beside
              the requested key.
            </DialogContentText>
            
            {canAddNewKey() ? (
              <Button onClick={addAPIKey} sx={{ mt: 4 }} variant="contained">
                add new key
              </Button>
            ): ""}
          </DialogContent>
          <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <APIKeys
              apiKeys={keys}
              currentApiKey={apiKey}
              setCurrentApiKey={setCurrentApiKey}
              billingStart={billingStart}
              setDeletingKey={setDeletingKey}
              setOpenConfirm={setOpenConfirm}
              hasDeleteKeyAccess={hasAccess(projectsList, projectID, userID)}
              updateAPIKey={updateAPIKey}
              isUpdatingKey={isUpdatingKey}
            />
          </div>

          <DialogActions sx={{ mt: 5 }}>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog sx={{ overflowY: 'hidden' }} open={openConfirm} onClose={handleCloseConfirm}>
          <DialogTitle></DialogTitle>
          <DialogContent>
            This will permanently delete your {deletingKey} API key. This cannot be undone. Are you sure?
            {deleteKeyErrorMsg ? (
              <Alert sx={{ mb: 3 }} severity='error'>
                {deleteKeyErrorMsg}
              </Alert>
            ) : (
              <div></div>
            )}
          </DialogContent>
          <DialogActions sx={{ mt: 5 }}>
            <LoadingButton
              onClick={handleDeleteKey}
              size="small"
              loading={isDeletingKey}
              loadingPosition="end"
              variant="contained"
            >
                Confirm
            </LoadingButton>
            <Button onClick={handleCloseConfirm}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog sx={{ overflowY: 'hidden' }} open={openInvite} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Project Settings</DialogTitle>
          <DialogContent>
            <ProjectsInfo projectsList={projectsList} projectID={projectID} endpoint={endpoint} userID={userID} refetchAllProjects={refetchAllProjects} projectConfig={projectConfig} />
          </DialogContent>
          <DialogActions sx={{ mt: 5 }}>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog sx={{ overflowY: 'hidden' }} open={openReferrals} onClose={handleReferrals} fullWidth maxWidth="sm">
          <DialogTitle>Referrals</DialogTitle>
          <DialogContent>
            <Referrals projectID={projectID} endpoint={endpoint}  />
          </DialogContent>
          <DialogActions sx={{ mt: 5 }}>
            <Button onClick={handleReferrals}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

      <div>
        <Dialog sx={{ overflowY: 'hidden' }} open={openChangeProject} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle>Projects</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please click <b>"Switch To This Project"</b> to switch the project.
            </DialogContentText>
          </DialogContent>
            <div style={{ overflowY: 'auto', maxHeight: '500px' }}>
            <Projects 
              projects={projectsList}
              projectID={projectID}
              handleProjectChange={handleProjectChange}
            />
            </div>
          <DialogActions sx={{ mt: 5 }}>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

    </RootStyle>
  );
}
