import { useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Button,
  Box,
  Card,
  Grid,
  Container,
  Typography,
  Alert,
  Stack,
  Slider,
  CircularProgress,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { AppTrafficBySite, AppWidgetSummary } from '../sections/@dashboard/app';
import { CardPayBanner } from '../components/CardPay/CardPayBanner';
import { CryptoExtendSub } from '../components/CryptoPay/ExtendSub';
import { SendEmailVerification } from '../components/SendVerificationEmail';
import { Faucet } from '../components/Faucet';
import { hasAccess } from '../utils/utils';
import {
  getUpgradePlansList,
  SubscriptionPlans,
  SubscriptionPlansIds,
  isUpgradingV1ToV2,
  SPHERE_PAY_URL,
} from '../utils/sphere';

const max = 200000000;

export default function DashboardApp({
  projectsList = [],
  currentUser,
  setIsProcessingSSOCryptoSub,
  projectConfig,
  portalConfigs,
  isAccountVerified,
  projectCreditsUsage = {},
  autoScaling,
  fetchProjectDetails,
  billingPlan,
}) {
  const header = { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } };
  const {
    rpcEndpoint,
    apiKey,
    endpoint,
    publicKey,
    billingRange,
    cryptoSub,
    planExpiresAt,
    customerEmail,
    spherePlans,
    processingSub,
    setProcessingSub,
    projectID,
    dedicatedNodes,
  } = useOutletContext();
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev';
  const { isAuthenticated: isUsingAuth0 } = useAuth0();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(max);
  const [err, setErr] = useState(null);
  const [errManageSubs, setErrManageSubs] = useState(null);
  const [saving, setSaving] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [buyingNewPlan, setBuyingNewPlan] = useState(false);
  const [numOfRemainingPrepaidCredits, setNumOfRemainingPrepaidCredits] = useState(0);

  //FRONT Integration
  useEffect(() => {
    const fetchAndSetup = async () => {
      const userHashData = await fetchUserHash(projectID);
      if (userHashData?.userHash) {
        window.FrontChat('init', {
          chatId: 'ee2659919b90800b8256bf13bfa2d954',
          useDefualtLauncher: true,
          onInitCompleted: () => setupFrontIdentity(userHashData),
        });
      }
    };

    fetchAndSetup();
  }, [projectID]);

  const setupFrontIdentity = async (userHashData) => {
    const modifiedValue = `${projectID.slice(0, 3)}-${projectID.slice(-3)}`;

    window.FrontChat('identity', {
      // The user ID is set to the project ID and is used in the userHash to verify customers.
      userId: projectID,
      userHash: userHashData.userHash,
      name: modifiedValue,

      contact: {
        email: customerEmail,
        customFields: {
          ProjectID: projectID || 'no project ID',
          PlanType: SubscriptionPlans[billingPlan] || SubscriptionPlans.free,
          DedicatedNode: userHashData.hasDedicatedNodes ? 'Yes' : 'No',
        },
      },
    });
  };
  // fetch the users hash. This hash is made up of the Project ID and verification code
  const fetchUserHash = async (projectID) => {
    try {
      const response = await axios.post(`${endpoint}/projects/${projectID}/chat-hash`, {}, header);
      return response.data;
    } catch (error) {
      console.log('Error fetching hash');
    }
  };
  useEffect(() => {
    if (autoScaling !== -1) {
      setValue(autoScaling);
    }
    if (billingPlan === SubscriptionPlansIds.Free) {
      setBuyingNewPlan(true);
    }
  }, [autoScaling]);

  useEffect(() => {
    if (projectsList && projectID) {
      setIsOwner(hasAccess(projectsList, projectID, publicKey.toString()));
    }
  }, [projectsList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleManageMySubscription = () => {
    setErrManageSubs(null);
    axios
      .get(`${endpoint}/projects/${projectID}/stripe-customer-portal`, header)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data.url) {
          window.location.href = data.url;
        } else {
          setErrManageSubs('No mange my subscription URL found.');
        }
      })
      .catch((error) => {
        console.error('Error fetching the url:', error);
        setErrManageSubs('Some error occurred while fetching manage subscription URL.');
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    if (typeof newValue === 'number') {
      setValue(newValue);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const autoScalingLimit = value == max ? -1 : value;
      await axios.put(`${endpoint}/projects/${projectID}/autoscaling`, { autoScalingLimit }, header);
      handleClose();
    } catch (e) {
      if (e?.response?.data) {
        setErr(e.response.data);
      } else {
        setErr('There was an error saving autoscaling, try again later or contact support');
      }
    } finally {
      setSaving(false);
    }
  };

  const showExceedPanel = () => {
    if (buyingNewPlan) {
      return false;
    }
    switch (billingPlan) {
      case SubscriptionPlansIds.Business:
      case SubscriptionPlansIds.Developer:
      case SubscriptionPlansIds.Hacker:
      case SubscriptionPlansIds.HackerV2:
      case SubscriptionPlansIds.StartupV2:
      case SubscriptionPlansIds.BusinessV2:
      case SubscriptionPlansIds.DeveloperV3:
      case SubscriptionPlansIds.BusinessV3:
      case SubscriptionPlansIds.PerformanceV3:
      case SubscriptionPlansIds.DeveloperV4:
      case SubscriptionPlansIds.BusinessV4:
      case SubscriptionPlansIds.ProfessionalV4:
      case SubscriptionPlansIds.Enterprise:
      case SubscriptionPlansIds.EnterpriseV4:
        return true;
      default:
        return false;
    }
  };

  const canManageAutoscaling = () => {
    switch (billingPlan) {
      case SubscriptionPlansIds.Hacker:
      case SubscriptionPlansIds.Enterprise:
      case SubscriptionPlansIds.EnterpriseV4:
        return false;
      default:
        return true;
    }
  };

  const showRemainingCredits = () => {
    if (projectCreditsUsage?.remainingCredits && projectConfig?.credits?.includedCredits) {
      // Track if the remaining credits are less than 10% of the total credits. Inform the user to purchase prepaid credits
      const isLessThan10Percent = projectCreditsUsage?.remainingCredits / projectConfig.credits.includedCredits <= 0.1;

      if (projectCreditsUsage?.remainingCredits <= 0 && cryptoSub) {
        return (
          <Alert sx={{ mb: 1 }} severity="error">
            You have no credits remaining for this billing cycle. Purchase prepaid credits{' '}
            <a className="underline hover:opacity-60" href="/prepaid-credits">
              here
            </a>{' '}
            to resume service or upgrade your plan.
          </Alert>
        );
      }

      return isLessThan10Percent && cryptoSub ? (
        <Alert sx={{ mb: 1 }} severity="warning">
          You have <b>{(projectCreditsUsage?.remainingCredits || 0).toLocaleString('en-US')}</b> credits remaining until
          the next billing period. Click{' '}
          <a className="underline hover:opacity-60" href="/prepaid-credits">
            here
          </a>{' '}
          to buy additional prepaid credits.
        </Alert>
      ) : (
        <Alert sx={{ mb: 1 }} severity="info">
          You have <b>{(projectCreditsUsage?.remainingCredits || 0).toLocaleString('en-US')}</b> credits remaining until
          the next billing period.
        </Alert>
      );
    }
  };

  const showOverageChargeNotification = () => {
    if (!cryptoSub) {
      let overagePrice = 0;
      if (projectConfig?.credits) {
        overagePrice = projectConfig.credits.overageUsagePrice / projectConfig.credits.creditGroupSize;
      }

      const overageCredits = projectCreditsUsage.overageCreditsUsed;

      if (overagePrice && overageCredits > 0) {
        return (
          <Alert sx={{ mb: 1 }} severity="warning">
            You have exceeded your monthly credit limit by {overageCredits}. Reminder that we charge ${overagePrice} for
            each additional credit in overages. Your current overages are:{' '}
            <b>${(overageCredits * overagePrice).toFixed(2).toLocaleString('en-US')}</b>. Consider upgrading your plan
            to get better unit costs and save money!
          </Alert>
        );
      }
    }
    return null;
  };

  const tierQueryParam = `client_reference_id=${projectID}&success_url=https://${
    isDev ? `${window.location.host}` : `dev.helius.xyz`
  }`;

  const stripeDeveloperPlanLink = `https://buy.stripe.com/${portalConfigs?.subscriptionsData?.stripe?.subscriptionPlans?.developer_v4}?${tierQueryParam}`;
  const stripeBusinessPlanLink = `https://buy.stripe.com/${portalConfigs?.subscriptionsData?.stripe?.subscriptionPlans?.business_v4}?${tierQueryParam}`;
  const stripeProfessionalPlanLink = `https://buy.stripe.com/${portalConfigs?.subscriptionsData?.stripe?.subscriptionPlans?.professional_v4}?${tierQueryParam}`;

  const sphereQueryParam = `reference_id=${projectID}`;
  const sphereDeveloperPlanLink = `${SPHERE_PAY_URL}/${portalConfigs?.subscriptionsData?.crypto?.subscriptionPlans?.sphereV2?.developer_v4}?${sphereQueryParam}`;
  const sphereBusinessPlanLink = `${SPHERE_PAY_URL}/${portalConfigs?.subscriptionsData?.crypto?.subscriptionPlans?.sphereV2?.business_v4}?${sphereQueryParam}`;
  const sphereProfessionalPlanLink = `${SPHERE_PAY_URL}/${portalConfigs?.subscriptionsData?.crypto?.subscriptionPlans?.sphereV2?.professional_v4}?${sphereQueryParam}`;

  const plansCardData = [
    {
      tab: 'Developer',
      title: 'Developer — $49/month',
      save: '100',
      desc: 'Ideal for small projects',
      src: '/static/hacker.jpg',
      listItems: ['10M Credits', '50 RPC Requests Per Second', '3 API Keys', '5 Webhooks', 'Chat Support'],
      planLink: stripeDeveloperPlanLink,
      spherePlanLink: sphereDeveloperPlanLink,
    },
    {
      tab: 'Business',
      title: 'Business — $499/month',
      save: '1000',
      desc: 'Ideal for mid-size projects',
      src: '/static/business.jpg',
      listItems: ['100M Credits', '200 RPC Requests Per Second', '5 API Keys', '25 Webhooks', 'Priority Chat Support'],
      planLink: stripeBusinessPlanLink,
      spherePlanLink: sphereBusinessPlanLink,
    },
    {
      tab: 'Professional',
      title: 'Professional - $999/month',
      save: '2000',
      desc: 'Ideal for teams operating at scale',
      src: '/static/business.jpg',
      listItems: [
        '200M Credits',
        '500 RPC Requests Per Second',
        '15 API Keys',
        '50 Webhooks',
        'Slack + Telegram Support',
      ],
      planLink: stripeProfessionalPlanLink,
      spherePlanLink: sphereProfessionalPlanLink,
    },
  ];

  useEffect(() => {
    async function fetchPrepaidCredits(p) {
      const { data } = await axios.get(`${endpoint}/projects/${p}/prepaid-credits`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
      });
      const { prepaidCredits } = data;

      setNumOfRemainingPrepaidCredits(prepaidCredits);
    }

    fetchPrepaidCredits(projectID);
  }, [projectID]);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              sx={{ height: '90%' }}
              title={`Credits Used (Current Cycle)`}
              total={projectCreditsUsage?.totalCreditsUsed?.toLocaleString() || '0'}
              color="info"
              icon={'ant-design:dashboard-filled'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              sx={{ height: '90%' }}
              title="Subscription Plan"
              total={SubscriptionPlans[billingPlan] || SubscriptionPlans.free}
              color="info"
              icon={'ant-design:dashboard-filled'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <AppWidgetSummary
              sx={{ height: '90%' }}
              title="Current Billing Cycle (UTC)"
              total={billingRange || 'N/A'}
              color="info"
              icon={'ant-design:dashboard-filled'}
            />
          </Grid>

          {isAccountVerified ? null : <SendEmailVerification endpoint={endpoint} projectId={projectID} />}

          {SubscriptionPlans[billingPlan] && (
            <>
              <Grid item xs={12} md={12} lg={12} sx={{ mb: 5 }}>
                <Box>
                  <Typography variant="h4" gutterBottom>
                    My {cryptoSub && 'Crypto '}Subscription
                  </Typography>

                  {showRemainingCredits()}

                  {billingPlan == SubscriptionPlansIds.Free && (
                    <>
                      You are currently on our <strong>Free</strong> plan — which features 500k credits, 1 webhook, 10
                      RPC requests per second, 2 API requests per second. Visit our{' '}
                      <a
                        href="https://docs.helius.dev/welcome/pricing-and-rate-limits"
                        target="_blank"
                        variant="text"
                        style={{
                          color: '#E84125',
                        }}
                      >
                        docs
                      </a>{' '}
                      to learn more about credits and pricing.
                      <div style={{ display: 'flow-root', margin: '1rem 0rem' }}>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          style={{
                            float: 'left',
                          }}
                        >
                          <Typography variant="h5">Power Up</Typography>
                          <br />
                        </Stack>
                      </div>
                      {/* <ApplyReferralCode projectID={projectID} endpoint={endpoint} header={header} /> */}
                      {/* <br /> */}
                      <CardPayBanner plansCardData={plansCardData} />
                    </>
                  )}

                  {showExceedPanel() ? (
                    <>
                      {showOverageChargeNotification()}
                      {isOwner ? (
                        cryptoSub ? (
                          <>
                            <Grid item xs={12} sm={12} md={12}>
                              <CryptoExtendSub
                                planExpiresAt={planExpiresAt}
                                endpoint={endpoint}
                                rpcEndpoint={rpcEndpoint}
                                processingSub={processingSub}
                                setProcessingSub={setProcessingSub}
                                defaultEmail={customerEmail}
                                name={`${SubscriptionPlans[billingPlan]} Plan`}
                                id={spherePlans[billingPlan]}
                                isSSOUser={isUsingAuth0}
                                currentUser={currentUser}
                                setIsProcessingSSOCryptoSub={setIsProcessingSSOCryptoSub}
                                projectID={projectID}
                                upgradePlansList={getUpgradePlansList(billingPlan)}
                                isUpgradingV1ToV2={isUpgradingV1ToV2(billingPlan)}
                                fetchProjectDetails={fetchProjectDetails}
                                prepaidCredits={numOfRemainingPrepaidCredits}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            {errManageSubs && <Alert severity="error">{errManageSubs}</Alert>}
                            <Button variant="text" onClick={handleManageMySubscription}>
                              Manage my subscription
                            </Button>
                            {canManageAutoscaling() ? (
                              <Grid sx={{ mt: 2 }} item xs={12} sm={12} md={12}>
                                <Button variant="text" onClick={handleClickOpen}>
                                  Manage Autoscaling
                                </Button>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </>
                        )
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      component={Card}
                      padding={4}
                      marginTop={4}
                      borderRadius={2}
                      width={1}
                      height={1}
                      data-aos={'fade-up'}
                    >
                      <Box display={'flex'} flexDirection={'column'}>
                        <Box
                          component={Avatar}
                          width={50}
                          height={50}
                          marginBottom={2}
                          bgcolor={'#E84125'}
                          color={'#f2f2f2'}
                        >
                          <svg
                            height={24}
                            width={24}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M13 10V3L4 14h7v7l9-11h-7z"
                            />
                          </svg>
                        </Box>
                        <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                          Enterprise Plan — Custom
                        </Typography>
                        <Typography color="text.secondary" gutterBottom>
                          Need a tailored plan for your company? Our enterprise plan contains custom SLAs, 24/7 priority
                          support, access to all features, unlimited webhooks, unlimited rate limits, private &
                          high-performance RPC nodes, and much more.
                        </Typography>
                        <Button
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`https://form.typeform.com/to/KiacmxpZ`}
                        >
                          Contact Us
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Autoscaling Limit</DialogTitle>
            {err && <Alert severity="error">{err}</Alert>}
            <DialogContent>
              <DialogContentText>
                Autoscaling lets you scale based on usage once you exceed your monthly credit limit.
              </DialogContentText>

              <Slider sx={{ mt: 5 }} value={value} min={0} step={1000} max={max} onChange={handleSliderChange} />

              {value == 0 && `Do not auto-scale. Cap my usage when I go over the alloted monthly usage.`}
              {projectConfig?.credits &&
                value != max &&
                value != 0 &&
                `Cap my usage at ${value.toLocaleString()} extra credits (equivalent to $${
                  (value * projectConfig.credits.overageUsagePrice) / projectConfig.credits.creditGroupSize
                })`}
              {value == max && `Do not cap my usage (unlimited scaling).`}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={handleSave}>
                {saving && <CircularProgress />}
                {!saving && 'Save'}
              </Button>
            </DialogActions>
          </Dialog>

          <Faucet apiKey={apiKey} />

          <Grid item xs={12} md={12} lg={12}>
            <AppTrafficBySite
              title="Support"
              list={[
                {
                  name: 'Join our Discord',
                  icon: <Iconify icon={'eva:email-fill'} color="#7289DA" width={32} height={32} />,
                  path: 'https://discord.gg/HjummjUXgq',
                  target: '_blank',
                },
                {
                  name: 'Read our API Docs',
                  icon: <Iconify icon={'eva:book-open-outline'} color="#E84125" width={32} height={32} />,
                  path: 'https://docs.helius.xyz',
                  target: '_blank',
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
