import {useState, useEffect} from 'react';
import {Box, Alert, Button, Typography, Stack, TextField, FormControl, IconButton, Tooltip, CircularProgress, Grid, Select, MenuItem, InputLabel} from '@mui/material';
import {TableContainer, Table, TableHead, TableBody, TableRow, TableCell} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from './Iconify';
import axios from 'axios';
import { UserRoles } from '../utils/utils'

export default function ProjectsInfo({ endpoint, userID, projectID, projectsList = [], refetchAllProjects, projectConfig }) {
  const [errorMsg, setErrorMsg] = useState();
  const [inviteCode, setInviteCode] = useState(null);
  const [invitedUser, setInvitedUser] = useState('');
  const [invitesLeft, setInvitesLeft] = useState(0);
  const [currentProject, setCurrentProject] = useState(null);
  const [canSendInvite, setCanSendInvite] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [openConfirmation, setOenConfirmation] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [projectName, setProjectName] = useState(null);
  const [isLoading, setIsLoading] = useState('');
  const [changingRoleUserId, setChangingRoleUserId] = useState(null);
  const [changingRole, setChangingRole] = useState('');
  const [inviteUserRole, setInviteUserRole] = useState(UserRoles.Admin);

  const loadingTypes = {
    updateName: 'updateName',
    getInvite: 'getInvite',
    removeUser: 'removeUser',
    changeRole: 'changeRole'
  }

  const handleInputChange = (event) => {
    setInvitedUser(event.target.value);
  };

  const handleTextChange = (event) => {
    setProjectName(event.target.value);
  };

  const getInviteLink = async () => {
    if(canSendInvite) {
      try {
        setIsLoading(loadingTypes.getInvite);
        setErrorMsg('');
        setInviteCode(null);
        const { data } = await axios.post(
          `${endpoint}/projects/${projectID}/invite`, {
            userId: invitedUser,
            role: inviteUserRole,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        setInviteCode(data.token);
      } catch (e) {
        console.error('Failed to get invite URL');
        if(e?.response?.data) {
          setErrorMsg(e.response.data);
        } else {
          setErrorMsg('There was an error generating invite link, try again later or contact support');
        }
      } finally {
        setIsLoading('');
      }
    }
  };

  const onRemoveClick = (userId) => {
    setIsLoading('');
    setOenConfirmation(true);
    setUserToRemove(userId);
  }

  const onEditClick = () => {
    setErrorMsg('');
    setIsLoading('');
    setIsEditing(true);
    setProjectName(currentProject?.name || '');
  }

  const onCancelClick = () => {
    setIsLoading('');
    setErrorMsg('');
    setIsEditing(false);
    setProjectName(currentProject?.name || '');
  }

  const onSaveClick =  async () => {
    setErrorMsg('');
    try {
      setIsLoading(loadingTypes.updateName);
      await axios.put(
      `${endpoint}/projects/${currentProject.id}`, {
        name: projectName,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      setIsEditing(false);
      await refetchAllProjects(userID);
    } catch (e) {
      console.error('Failed update project');
      if(e?.response?.data) {
        setErrorMsg(e.response.data);
      } else {
        setErrorMsg('There was an error updating name, try again later or contact support');
      }
    } finally {
      setIsLoading('');
    }
  }

  const onChangeRoleClick = (user) => {
    setChangingRoleUserId(user.id);
    setChangingRole(user.role);
  }
  const onCancelRoleChangeClick = () => {
    setChangingRoleUserId(null);
    setChangingRole('');
  }
  const handleRoleChange = (event) => {
    setChangingRole(event.target.value);
  };
  const handleInviteUserRoleChange = (event) => {
    setInviteUserRole(event.target.value);
  };
  const saveRoleChange = async () => {
    setIsLoading(loadingTypes.changeRole);
    setErrorMsg('');
    try {
      await axios.put(
        `${endpoint}/projects/${projectID}/role`, {
          userId: changingRoleUserId,
          role: changingRole,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      setChangingRole('');
      setChangingRoleUserId(null);
      await refetchAllProjects(userID);
    } catch (e) {
      console.error('Failed to change user role');
      if(e?.response?.data) {
        setErrorMsg(e.response.data);
      } else {
        setErrorMsg('There was an error changing user role, try again later or contact support');
      }
    } finally {
      setIsLoading('');
    }
  }

  const onConfirmClose = () => {
    setOenConfirmation(false);
    setUserToRemove(null);
  }

  const removeUser = async () => {
    if(userToRemove) {
      try {
        setErrorMsg('');
        setIsLoading(loadingTypes.removeUser);
        await axios.delete(
          `${endpoint}/projects/${projectID}/user/${userToRemove}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}`,
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        setOenConfirmation(false);
        setUserToRemove(null);
        await refetchAllProjects(userID);
      } catch (e) {
        console.error('Failed to remove user');
        if(e?.response?.data) {
          setErrorMsg(e.response.data);
        } else {
          setErrorMsg('There was an error removing user, try again later or contact support');
        }
      } finally {
        setIsLoading('');
      }
    }
  };

  useEffect(() => {
    if(projectID) {
      const project = projectsList.find((proj) => proj.id === projectID);
      if(project) {
        setCurrentProject(project);
        setProjectName(project.name);
        const totalInvitedUsers = project.users && project.users.length ? project.users.filter((user) => user.role !== 'Owner') : [];
        const limit = projectConfig?.users || 0;
        const leftInvites = limit - totalInvitedUsers.length;

        const projUser =  project.users && project.users.length && project.users.find((user) => user.id === userID.toString());

        setInvitesLeft(leftInvites);
        setCanSendInvite(leftInvites > 0 && projUser && projUser.role !== UserRoles.ReadOnly);
        setIsOwner(projUser && projUser.role === UserRoles.Owner);
      }
    }
  }, [projectID, projectsList])

  return (
    <Box sx={{ width: '100%' }}>
      {errorMsg ? (
        <Alert sx={{ mb: 3 }} severity='error'>
          {errorMsg}
        </Alert>
      ) : (
        <div></div>
      )}
      <Grid container spacing={2} style={{
        marginBottom: '10px'
      }}>
        <Grid item xs={8} style={{margin: 'auto'}}>
          {
            currentProject && <Stack direction='row' spacing={4} >
              <strong style={{margin: 'auto 0px'}}>Project Name:</strong>
              {isEditing ? (
                <FormControl sx={{ mb: 1, mt: 2 }} style={{
                  width: '50%',
                  margin: 'auto 0px auto 10px'
                }}>
                  <TextField
                    autoFocus
                    variant='standard'
                    value={projectName || ''}
                    onChange={handleTextChange}
                  />
                </FormControl>
              ) : (
                <div style={{margin: 'auto 10px'}}>{projectName}</div>
              )}
              <div style={{
                marginLeft: '0'
              }}>
              {
                isOwner && (
                  isEditing ? <>
                    {
                      isLoading === loadingTypes.updateName ? <CircularProgress size={'20px'} style={{'margin': '-6px 0px'}} /> : <IconButton
                        size='medium'
                        color="secondary"
                        onClick={() => onSaveClick()}
                      >
                        <Iconify icon='iconamoon:check-fill' />
                      </IconButton>
                    }
                    <IconButton
                      size='medium'
                      color="error"
                      onClick={() => onCancelClick()}
                      style={{marginLeft: '0px'}}
                    >
                      <Iconify icon='iconoir:cancel' />
                    </IconButton>
                  </> : <IconButton
                    size='small'
                    color="error"
                    onClick={() => onEditClick()}
                    style={{marginLeft: '10px'}}
                  >
                    <Iconify icon='iconamoon:edit' />
                  </IconButton>
                )
              }
              </div>
            </Stack>
          }
        </Grid>
        <Grid item xs={4} style={{margin: 'auto'}}>
          <Stack direction={'row'}>
            <div style={{margin: 'auto 0 auto auto'}}>
              # Total Invites Left :&nbsp;<strong>{invitesLeft > 0 ? invitesLeft : 0 }</strong>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{
        marginBottom: '10px'
      }}>
        <Grid item xs={8}>
          {
            currentProject && <Stack direction='row' spacing={4} >
              <strong style={{margin: 'auto 0px'}}>Project Id:</strong>
              <div style={{margin: 'auto 0 auto 10px'}}>{currentProject.id}</div>
              <Tooltip title='Copy' placement="top">
                <IconButton
                  size='small'
                  color="info"
                  onClick={() => {
                    navigator.clipboard.writeText(currentProject.id);
                  }}
                  style={{
                    marginLeft: '12px'
                  }}
                >
                  <Iconify icon='clarity:copy-solid' />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        </Grid>
      </Grid>
      <Grid container spacing={2}  style={{
        marginBottom: '10px',
      }}>
        <Grid item xs={12}>
          {
            canSendInvite ? <Stack direction={'row'}>
              <FormControl sx={{ mb: 1, mt: 2 }} style={{
                width: '70%',
              }}>
                <TextField
                  autoFocus
                  label='User Email/Wallet ID'
                  variant='standard'
                  value={invitedUser}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl sx={{ mb: 1, mt: 3, ml: 2 }}>
                <InputLabel id="select-role-label">Role</InputLabel>
                <Select
                  size='small'
                  labelId="select-role-label"
                  value={inviteUserRole}
                  onChange={handleInviteUserRoleChange}
                  style={{
                    width: '120px'
                  }}
                  label='Role'
                >
                  <MenuItem key={UserRoles.Admin} value={UserRoles.Admin}>{UserRoles.Admin}</MenuItem>
                  <MenuItem key={UserRoles.ReadOnly} value={UserRoles.ReadOnly}>{UserRoles.ReadOnly}</MenuItem>
                </Select>
              </FormControl>
              <div style={{
                margin: '25px 0 auto auto',
              }}>
                {
                  isLoading === loadingTypes.getInvite ? <CircularProgress size={'25px'} /> :
                    <Button
                      size='small'
                      onClick={() => getInviteLink()}
                      variant='contained'
                      disabled={!invitedUser.length}
                    >
                      Get Invite Link
                    </Button>
                }
              </div>
            </Stack> : <></>
          }
        </Grid>
      </Grid>
      {
        inviteCode && <Grid container spacing={2} >
          <Grid item xs={12}>
            <Stack direction='row' sx={{ border: '1px #E84125 solid', borderRadius: '10px', marginBottom: '20px', padding: 2 }} >
              <Typography variant='subtitle1' style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
                <strong>Invite URL - </strong>{`${window.location.origin}/?inviteCode=${inviteCode}`}
              </Typography>
              <Button
                size='small'
                sx={{ ml: 2, mt: -0.5 }}
                onClick={() => {
                  navigator.clipboard.writeText(`${window.location.origin}/?inviteCode=${inviteCode}`);
                }}
                variant='contained'
                to='#'
                startIcon={<Iconify icon='clarity:copy-solid' />}
              >
                Copy
              </Button>
            </Stack>
          </Grid>
        </Grid>
      }
      <Grid container spacing={2} >
        <Grid item xs={12}>
          {
            currentProject && <TableContainer>
              <strong>Current Users</strong>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={'40%'}>User</TableCell>
                    <TableCell width={'20%'}>Role</TableCell>
                    <TableCell width={'40%'}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentProject.users?.map((user) => (
                    <TableRow key={user.id} hover tabIndex={-1}>
                      <TableCell>{user.id}</TableCell>
                      <TableCell>
                        {
                          changingRoleUserId === user.id  ? <Select
                            size='small'
                            labelId="select-label"
                            value={changingRole}
                            onChange={handleRoleChange}
                            style={{
                              width: '120px'
                            }}
                          >
                            <MenuItem key={UserRoles.Admin} value={UserRoles.Admin}>{UserRoles.Admin}</MenuItem>
                            <MenuItem key={UserRoles.ReadOnly} value={UserRoles.ReadOnly}>{UserRoles.ReadOnly}</MenuItem>
                          </Select> : user.role
                        }
                      </TableCell>
                      <TableCell>
                        {
                          changingRoleUserId === user.id  ? <>
                            {
                              isLoading === loadingTypes.changeRole ? <CircularProgress size={'20px'} style={{'margin': '-6px 0px -6px 10px'}} /> : <IconButton
                                size='medium'
                                color="secondary"
                                onClick={() => saveRoleChange()}
                              >
                                <Iconify icon='iconamoon:check-fill' />
                              </IconButton>
                            }
                            <IconButton
                              size='medium'
                              color="error"
                              onClick={() => onCancelRoleChangeClick()}
                              style={{marginLeft: '0px'}}
                            >
                              <Iconify icon='iconoir:cancel' />
                            </IconButton>
                          </> : <>
                            {
                              user.role !== UserRoles.Owner && isOwner ? <>
                                <Tooltip title='Change Role' placement="top">
                                  <IconButton
                                    size='small'
                                    color="error"
                                    onClick={() => onChangeRoleClick(user)}
                                    style={{marginRight: '10px'}}
                                  >
                                    <Iconify icon='iconamoon:edit' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title='Remove User' placement="top">
                                  <IconButton
                                    size='medium'
                                    color="error"
                                    onClick={() => onRemoveClick(user.id)}
                                    style={{padding: 0}}
                                  >
                                    <Iconify icon='typcn:delete' />
                                  </IconButton>
                                </Tooltip>
                              </> : ''
                            }
                          </>
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Grid>
      </Grid>
      <Dialog sx={{ overflowY: 'hidden' }} open={openConfirmation} onClose={onConfirmClose} maxWidth="sx">
        <DialogTitle></DialogTitle>
        <DialogContent>
          Are sure, you want to remove user <strong>{userToRemove}</strong> from project <strong>{currentProject ? currentProject.name : ''}?</strong>
        </DialogContent>
        <DialogActions sx={{ mt: 5 }}>
          {
            isLoading === loadingTypes.removeUser ? <CircularProgress size={'20px'} /> : <Button onClick={removeUser} color='secondary'>
              Yes
            </Button>
          }
          <Button onClick={onConfirmClose}>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
