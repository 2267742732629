// @mui
import PropTypes from 'prop-types';
import { Box, Card, Paper, Typography, CardHeader, CardContent, Link } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

AppTrafficBySite.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTrafficBySite({ title, subheader, link, list, ...other }) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: 'repeat(2, 1fr)',
          }}
        >
          {list.map((site) => (
            <Link key={site.name} href={site.path} target={site.target}>
              <Paper
                className="flex flex-col items-center gap-2"
                key={site.name}
                variant="outlined"
                sx={{ py: 2.5, textAlign: 'center' }}
              >
                <Box sx={{ mb: 0.5 }}>{site.icon}</Box>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {site.name}
                </Typography>
              </Paper>
            </Link>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
