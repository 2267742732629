import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import Iconify from '../../components/Iconify';
import Auth0LoginButton from '../../components/Auth0Buttons/Auth0LoginButton';
import { LoginTypes, DRAWER_WIDTH } from '../../utils/utils'

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar, loginType, onLogout }) {  
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {
            loginType !== LoginTypes.None && <Button href="https://docs.helius.xyz" target="_blank" variant="contained" style={{
              marginRight: '16px'
            }}>
              Documentation
            </Button>
          }
          {
            loginType === LoginTypes.None || loginType === LoginTypes.Auth0 ? <Auth0LoginButton onLogout={onLogout} loginType={loginType} /> : null
          }
          {
            loginType === LoginTypes.None || loginType === LoginTypes.Wallet ? <WalletMultiButton /> : null
          }
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
