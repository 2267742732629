import React, {useState, useEffect} from 'react';
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
  Tooltip,
  CircularProgress,
  FormControl,
  TextField,
  IconButton,
} from '@mui/material';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { UserListHead } from '../sections/@dashboard/user';
import { styled } from '@mui/material/styles';

const ContentStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  zIndex: '100',
}));

const TABLE_HEAD = [
  { id: 'keyName', label: 'Key Name', alignRight: false },
  { id: 'apiKey', label: 'Key Id', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'actions', label: 'Actions', alignRight: false },
];

export default function APIKeys({ apiKeys, setCurrentApiKey, currentApiKey, billingStart, setDeletingKey, setOpenConfirm, hasDeleteKeyAccess, updateAPIKey, isUpdatingKey}) {
  const [callingSetCurrent, setCallingSetCurrent] = useState(false)
  const [editingKey, setEditingKey] = useState(null)
  const [keyName, setKeyName] = useState('')
  const [showAPIKey, setShowAPIKey] = useState('')
  async function onSwitchKeyHandle(apiKey, x, refreshProject) {
    setCallingSetCurrent(true);
    try {
      await setCurrentApiKey(apiKey, x, refreshProject);
    } catch {
    } finally {
      setCallingSetCurrent(false);
    }
  };

  const handleInputChange = (event) => {
    setKeyName(event.target.value);
  };

  const handleShowKeyClick = (keyId) => {
    setShowAPIKey(keyId);
  };

  const onEditKeyClick = (keyId) => {
    const key = apiKeys.find((o) => o.keyId === keyId)
    setKeyName(key.keyName)
    setEditingKey(keyId)
  };

  useEffect(() => {
    setKeyName('')
    setEditingKey(null)
  }, [apiKeys])

  return (
    <Container>
      <Card>
        <Scrollbar>
          <TableContainer>
            {
              callingSetCurrent && <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </ContentStyle>
            }
            <Table>
              <UserListHead headLabel={TABLE_HEAD} />
              <TableBody>
                {apiKeys.map((x) => (
                  <TableRow key={x.keyId} hover tabIndex={-1} selected={x.keyId == currentApiKey}>
                    <TableCell align="left" width={'200px'}>
                      {
                        editingKey === x.keyId ? <FormControl sx={{ mb: 0, mt: 0 }}>
                          <TextField
                            autoFocus
                            label=''
                            variant='standard'
                            value={keyName}
                            onChange={handleInputChange}
                          />
                        </FormControl> : x.keyName
                      }
                    </TableCell>
                    <TableCell align="left" width={'400px'}>
                      <span>
                        {showAPIKey === x.keyId ? x.keyId : '********-****-****-****-************'}
                      </span>
                      {'  '}
                      { 
                        showAPIKey === x.keyId ? <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            setShowAPIKey('');
                          }}
                        >
                          <Iconify icon="bx:hide" />
                        </IconButton> : <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            setShowAPIKey(x.keyId);
                          }}
                        >
                          <Iconify icon="bx:show" />
                        </IconButton>
                      }
                      <Tooltip title='Copy' placement="top">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => {
                            navigator.clipboard.writeText(`${x.keyId}`);
                          }}
                        >
                          <Iconify icon="clarity:copy-solid" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="left">{new Date(x.createdAt).toLocaleString()}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex' }}>
                        {x.keyId != currentApiKey && (
                          <Button onClick={() => onSwitchKeyHandle(x, billingStart, false)} sx={{ pl: 0 }} variant="text">
                            Switch to this key
                          </Button>
                        )}
                        {x.keyId == currentApiKey && (
                          <Button disabled sx={{ pl: 0 }} variant="text">
                            Currently selected
                          </Button>
                        )}
                        {
                          hasDeleteKeyAccess && editingKey !== x.keyId && <>
                            <Tooltip title='Edit Key' placement="top">
                              <IconButton
                                size='medium'
                                color="error"
                                onClick={() => {
                                  onEditKeyClick(x.keyId);
                                }}
                              >
                                <Iconify icon='fe:edit' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Delete Key' placement="top">
                              <IconButton
                                size='medium'
                                color="error"
                                onClick={() => {
                                  setDeletingKey(x.keyId);
                                  setOpenConfirm(true);
                                }}
                              >
                                <Iconify icon='ic:baseline-delete' />
                              </IconButton>
                            </Tooltip>
                          </>
                        }
                        {
                          hasDeleteKeyAccess && editingKey === x.keyId && <>
                            <Tooltip title='Cancel' placement="top">
                              <IconButton
                                size='medium'
                                color="error"
                                onClick={() => {
                                  setEditingKey(null);
                                  setKeyName('');
                                }}
                              >
                                <Iconify icon='fe:close' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title='Save' placement="top">
                              {
                                isUpdatingKey ? <CircularProgress style={{
                                  padding: '8px'
                                }} /> : <IconButton
                                  size='medium'
                                  color="error"
                                  onClick={() => {
                                    updateAPIKey(x.keyId, keyName)
                                  }}
                                >
                                  <Iconify icon='fe:check' />
                                </IconButton>
                              }
                            </Tooltip>
                          </>
                        }
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}
