import React, { useEffect } from 'react';

function FrontChat() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://chat-assets.frontapp.com/v1/chat.bundle.js';
    script.id = 'front-chat-script';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <></>;
}

export default FrontChat;
