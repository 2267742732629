import { Typography, Card, CardContent, CardActionArea, Box, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function SelectCard({ type, id, selectedId, onChange, title, description, label, icon, hidden }) {
  if (hidden) {
    return null;
  }
  switch (type) {
    case 'small':
      return (
        <Card
          sx={{
            border: 2,
            borderColor: id === selectedId ? '#e84125' : 'none',
            backgroundColor: '#F1F1F1',
          }}
        >
          <CardActionArea onClick={onChange(id)} sx={{ height: '100%' }}>
            {id === selectedId && (
              <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                <CheckCircleIcon htmlColor="#e84125" fontSize="small" />
              </Box>
            )}
            <CardContent sx={{ px: 3, py: 2 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
                <Typography variant="h6" component="h3" sx={{ flexGrow: 1 }}>
                  {title}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  {label}
                </Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );

    case 'large':
    default:
      return (
        <Card
          sx={{
            border: 2,
            borderColor: id === selectedId ? '#e84125' : 'none',
            backgroundColor: '#F1F1F1',
          }}
        >
          <CardActionArea onClick={onChange(id)} sx={{ height: '100%' }}>
            {id === selectedId && (
              <Box sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                <CheckCircleIcon htmlColor="#e84125" fontSize="small" />
              </Box>
            )}
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {icon && icon}
                <Typography gutterBottom variant="subtitle1" component="div" color="text.secondary">
                  {label}
                </Typography>
              </Box>
              <Typography gutterBottom variant="h5" component="h3">
                {title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {description}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      );
  }
}
