import { useState, useEffect } from 'react';
import {
    Avatar,
    Button,
    Box,
    Card,
    Grid,
    Typography,
    FormControl,
    TextField,
    Alert,
    CircularProgress,
} from '@mui/material';
import { useWallet } from '@solana/wallet-adapter-react';
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';

export const Faucet = ({
    apiKey
}) => {
    const { publicKey } = useWallet();

    const [address, setAddress] = useState(publicKey);
    const [msg, setMsg] = useState(null);
    const [status, setStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [displayMessage, setDisplayMessage] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [cooldownActive, setCooldownActive] = useState(false);

    // Check if provided address is a valid Solana address
    const isValidSolanaAddress = (address) => {
        return /^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address);
    };

    const handleAirdrop = async () => {
        if (isValidSolanaAddress(address)) {
            // Initiate loading state
            setIsLoading(true);
            setCooldownActive(true);
            try {
                // Establish connection and form the public key
                const connection = new Connection(`https://devnet.helius-rpc.com/?api-key=${apiKey}`, 'confirmed');
                const pubkey = new PublicKey(address);

                // Send and confirm transaction
                const transaction = await connection.requestAirdrop(pubkey, 1 * LAMPORTS_PER_SOL);
                await connection.getTransaction(transaction, {
                    maxSupportedTransactionVersion: 0
                });

                setMsg(`Airdrop Succesful: ${transaction}`);
                setStatus("success");
                setDisplayMessage(true);
            } catch (error) {
                setMsg("Request failed. Please try again later");
                setStatus("error");
                setDisplayMessage(true);
            }
            setIsLoading(false);
            setAddress("");
        } else {
            setMsg("Invalid Solana Address");
            setAddress("");
            setStatus("error");
            setDisplayMessage(true);
        }
    };

    // Initiate cooldown sequence whenever airdrop is executed
    useEffect(() => {
        let countdownInterval;

        if (cooldownActive) {
            countdownInterval = setInterval(() => {
                setCountdown((prevCountdown) => {
                    if (prevCountdown === 1) {
                        clearInterval(countdownInterval);
                        setStatus(null); // Reset status
                        setCountdown(3); // Reset the countdown
                        setCooldownActive(false);
                        return 3; // Reset countdown
                    }
                    return prevCountdown - 1;
                });
            }, 1_000);
        }

        return () => {
            clearInterval(countdownInterval); // Clean up interval on component unmount
        };
    }, [cooldownActive]);

    return (
        <>
            <Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
                <Box
                    component={Card}
                    padding={4}
                    borderRadius={2}
                    width={1}
                    height={1}
                    data-aos={'fade-up'}
                >
                    <Box display={'flex'} flexDirection={'column'} sx={{ mb: 5 }}>
                        <Box
                            component={Avatar}
                            width={50}
                            height={50}
                            marginBottom={2}
                            bgcolor={'#E84125'}
                            color={'#f2f2f2'}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={1.5} height={33} width={33}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                            </svg>
                        </Box>

                        <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                            Request Devnet SOL
                        </Typography>

                        <Typography color="text.secondary" gutterBottom>
                            Kick off your Solana development journey with some Devnet SOL from your friends at Helius.
                            Just paste in a Solana wallet address and we&apos;ll take care of the rest. Devnet SOL
                            has no financial value and is solely used for development purposes.
                        </Typography>

                        <FormControl fullWidth>
                            <TextField
                                onChange={event => setAddress(event.target.value)}
                                required
                                sx={{ mb: 1 }}
                                margin="normal"
                                id="address"
                                label="Wallet Address"
                                helperText="Enter the address of your Solana wallet."
                                type="text"
                                fullWidth
                                autoComplete="off"
                                value={address}
                            />
                        </FormControl>

                        <Button
                            style={{ width: '100%', marginTop: '30px' }}
                            variant='contained'
                            onClick={handleAirdrop}
                            disabled={isLoading || cooldownActive}
                        >
                            {
                                isLoading
                                    ? <CircularProgress size={24} />
                                    : cooldownActive
                                        ? `Make another request in ${countdown} seconds`
                                        : "Airdrop 1 SOL (Devnet)"
                            }
                        </Button>
                    </Box>

                    {displayMessage && ["error", "success"].includes(status) &&
                        <Alert severity={status} onClose={() => setDisplayMessage(false)}>{msg}</Alert>}
                </Box>
            </Grid>
        </>
    );
};