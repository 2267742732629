import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

import { LoginTypes } from '../../utils/utils'
import ProfileButton from './ProfileButton';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffffff',
  padding: '0px 5px',
  height: '50px',
  width: '200px',
  fontSize: '14px',
  color: '#000000',
  '&:hover': {
    backgroundColor: '#ffffff',
  }
}));

const Auth0LoginButton = ({ loginType, onLogout }) => {
  const { isAuthenticated: isUsingAuth0, user, loginWithRedirect } = useAuth0();

  const handleLogout = () => {
    onLogout();
  }

  return (<>
    {isUsingAuth0 && loginType === LoginTypes.Auth0 ? <ProfileButton userProfile={user} onLogout={handleLogout} /> : <>
      <StyledButton
        onClick={() => loginWithRedirect({
          authorizationParams: { connection: 'google-oauth2' },
          appState: {
            returnTo: window.location.pathname
          }
        })}
        variant="contained"
        size='small'
        startIcon={
          <Avatar
            alt="Image Icon"
            src="/static/icons/g-logo.png"
            style={{
              height: '30px',
              width: '30px'
            }}
          />
        }
      >
        Sign in with Google
      </StyledButton>

      <StyledButton
        onClick={() => loginWithRedirect({
          authorizationParams: { connection: 'github' },
          appState: {
            returnTo: window.location.pathname
          }
        })}
        variant="contained"
        size='small'
        startIcon={
          <Avatar
            alt="Image Icon"
            src="/static/icons/github-logo.png"
            style={{
              height: '38px',
              width: '38px'
            }}
          />
        }
      >
        Sign in with Github
      </StyledButton>
    </>}
  </>
  );
};

export default Auth0LoginButton;
