import React, { useState, useEffect } from 'react';

import Iconify from './Iconify';

import { Alert, Box, IconButton, CircularProgress } from '@mui/material';
import axios from 'axios';

export default function Referrals({ projectID, endpoint }) {
  const [projectReferral, setProjectReferral] = useState(null);
  const [fetchingCode, setFetchingCode] = useState(true);
  const [errMsg, setErrMsg] = useState(null);

  const header = {
    headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
  }

  const getReferralCode = async () => {
    errMsg && setErrMsg(null);
    setFetchingCode(true);
    axios.get(`${endpoint}/projects/${projectID}/referral-code`, header).then((data) => {
      setProjectReferral(data.data);
    }).catch((e) => {
      if(e.response?.status !== 404) {
        setErrMsg('Some Error Occurred');
      }
    }).finally(() => {
      setFetchingCode(false);
    });
  }

  useEffect(() => {
    getReferralCode();
  }, []);

  return (
    <Box sx={{ width: '100%' }}>
      {errMsg ? (
        <Alert sx={{ mb: 3 }} severity='error'>
          {errMsg}
        </Alert>
      ) : (
        <div></div>
      )}
      <div style={{display: 'flex', lineHeight: '25px'}}>
        <span style={{
          marginRight: '5px'
        }}>Referral Code:</span>
        {
          fetchingCode ? <CircularProgress size={'1.5rem'} /> : <div>
            {
              projectReferral && projectReferral?.referralCode && <div>
                <strong style={{ color: '#E84125' }}> {projectReferral.referralCode}</strong>
                <IconButton
                  size='small'
                  color="info"
                  onClick={() => {
                    navigator.clipboard.writeText(`${projectReferral.referralCode}`);
                  }}
                  style={{
                    marginLeft: '5px'
                  }}
                >
                  <Iconify icon='clarity:copy-solid' />
                </IconButton>
              </div>
            }
          </div>
        }
      </div>
      {
        projectReferral && projectReferral.referralCode && <div style={{marginTop: '10px'}}>
          Your code has been redeemed <strong style={{ color: '#E84125' }}> {projectReferral?.CodeUsedCount.toLocaleString()}</strong> {projectReferral?.CodeUsedCount === 1 ? 'time' : 'times'}.
        </div>
      }
      {
        projectReferral && <div style={{marginTop: '10px'}}>
          Prepaid Credits Earned: <strong style={{ color: '#E84125' }}> {projectReferral?.prepaidCredits.toLocaleString()}</strong>
        </div>
      }
    </Box>
  );
}
