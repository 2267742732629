import React, {useEffect, useState} from 'react';
import { Button, CircularProgress, Grid, Typography, Container } from '@mui/material';
import { SubscriptionPlansIds, SPHERE_PAY_URL } from '../utils/sphere';
import Page from '../components/Page';
import axios from 'axios';

export default function PrepaidCredits({
  endpoint,
  projectID,
  currentPlan,
  isSSOUser,
  setIsProcessingSSOCryptoSub,
  currentUser,
  cryptoSub,
  portalConfigs,
}) {
  if (!currentUser || currentUser === '') {
    return <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid sx={{ mt: -30 }} item xs={3}>
          <Typography variant="h6">Sign in to start building with Helius.</Typography>
        </Grid>
      </Grid>
    </>
  }
  const [credits, setCredits] = useState(0)
  const [buyingType, setBuyingType] = useState('crypto')
  const [isFetching, setIsFetching] = useState(false)

  const getCryptoProductID = () => {
    const spherePlans = portalConfigs?.subscriptionsData?.crypto?.prepaidCreditsPlans.sphereV2
    switch(currentPlan) {
      case SubscriptionPlansIds.HackerV2:
      case SubscriptionPlansIds.Developer:
      case SubscriptionPlansIds.DeveloperV3:
        return spherePlans.developer_v3
      case SubscriptionPlansIds.StartupV2:
      case SubscriptionPlansIds.BusinessV3:
        return spherePlans.business_v3
      case SubscriptionPlansIds.BusinessV2:
      case SubscriptionPlansIds.Business:
      case SubscriptionPlansIds.PerformanceV3:
        return spherePlans.performance_v3
      case SubscriptionPlansIds.DeveloperV4:
        return spherePlans.developer_v4
      case SubscriptionPlansIds.BusinessV4:
        return spherePlans.business_v4
      case SubscriptionPlansIds.ProfessionalV4:
        return spherePlans.professional_v4
      default:
        return spherePlans.free
    }
  }

  const showBuyPrepaidCredits = () => {
    if(cryptoSub) {
      switch(currentPlan) {
        case SubscriptionPlansIds.Hacker:
        case SubscriptionPlansIds.HackerV2:
        case SubscriptionPlansIds.Developer:
        case SubscriptionPlansIds.StartupV2:
        case SubscriptionPlansIds.BusinessV2:
        case SubscriptionPlansIds.Business:
        case SubscriptionPlansIds.DeveloperV3:
        case SubscriptionPlansIds.BusinessV3:
        case SubscriptionPlansIds.PerformanceV3:
        case SubscriptionPlansIds.DeveloperV4:
        case SubscriptionPlansIds.BusinessV4:
        case SubscriptionPlansIds.ProfessionalV4:
          return true
        default:
          return false
      }
    }
    return false
  }

  // specific request meant to update crypto sub details. Scoped down to avoid unnecessary re-renders
  async function fetchPrepaidCredits(p) {
    const { data } = await axios.get(`${endpoint}/projects/${p}/prepaid-credits`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` },
    });
    const { prepaidCredits } = data;

    if(credits === null) {
      setCredits(prepaidCredits);
    } else if(prepaidCredits > credits) {
      setCredits(prepaidCredits);
    }
  }

  const CryptoPrepaidCreditsCard = () => {
    const prodID = getCryptoProductID()
    return (
      <div>
        <Button
          style={{ marginTop: '20px' }}
          href={`${SPHERE_PAY_URL}/${prodID}?reference_id=${projectID}`}
          variant='contained'
        >
          Buy Prepaid Credits
        </Button>
      </div>
    )
  }

  // const StripePrepaidCreditsCard = () => {
  //   const prepaidCreditsLinkIds = portalConfigs?.subscriptionsData?.stripe?.prepaidCreditsPlans;

  //   const tierQueryParam = `client_reference_id=${projectID}&success_url=https://${isDev ? `${window.location.host}` : `dev.helius.xyz`}` 
  //   let stripeProduct = ''
  //   let price = 0
  //   switch(currentPlan) {
  //     case SubscriptionPlansIds.HackerV2:
  //     case SubscriptionPlansIds.Developer:
  //       price = 7;
  //       stripeProduct = prepaidCreditsLinkIds?.hacker_v2
  //       break
  //     case SubscriptionPlansIds.StartupV2:
  //       price = 6;
  //       stripeProduct = prepaidCreditsLinkIds?.startup_v2
  //       break
  //     case SubscriptionPlansIds.BusinessV2:
  //     case SubscriptionPlansIds.Business:
  //       price = 4;
  //       stripeProduct = prepaidCreditsLinkIds?.business_v2
  //       break
  //     default:
  //       price = 10;
  //       stripeProduct = prepaidCreditsLinkIds?.free
  //   }
  //   const stripeLink =  `https://buy.stripe.com/${stripeProduct}?${tierQueryParam}`;
  //   return (
  //     <div>
  //       <Box display={'flex'} flexDirection={'column'}>
  //         <div>
  //           <div style={{
  //             float: 'left',
  //             display: 'flex'
  //           }}>
  //             <div>
  //               <Box
  //                 component={Avatar}
  //                 width={50}
  //                 height={50}
  //                 marginBottom={2}
  //                 bgcolor={'#E84125'}
  //                 color={'#f2f2f2'}
  //               >
  //                 <svg
  //                   height={24}
  //                   width={24}
  //                   xmlns="http://www.w3.org/2000/svg"
  //                   fill="none"
  //                   viewBox="0 0 24 24"
  //                   stroke="currentColor"
  //                 >
  //                   <path
  //                     strokeLinecap="round"
  //                     strokeLinejoin="round"
  //                     strokeWidth={2}
  //                     d="M13 10V3L4 14h7v7l9-11h-7z"
  //                   />
  //                 </svg>
  //               </Box>
  //             </div>
  //             <div style={{
  //               marginLeft: '20px'
  //             }}>
  //               <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
  //                 {`$${price}/million credits`}
  //               </Typography>
  //               <Button
  //                 href={stripeLink}
  //               >
  //                 Buy Credits
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </Box>
  //     </div>
  //   )
  // }

  useEffect(() => {
    if(isSSOUser) {
      setIsProcessingSSOCryptoSub(true);
    } else {
      setIsProcessingSSOCryptoSub(false);
    }
  }, [isSSOUser]);

  useEffect(() => {
    if(projectID) {
      setIsFetching(true);
      fetchPrepaidCredits(projectID).finally(() => setIsFetching(false));
    }
  }, [projectID])


  return (
    <Page title="Prepaid Credits">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 2 }}>
          Prepaid Credits
        </Typography>
        <div style={{
          marginBottom: '10px',
        }}>
          <div style={{display: 'flow-root', width: '100%'}}>
            <div style={{float: 'left'}}>
              <Typography variant={'h6'} gutterBottom sx={{ fontWeight: 500 }}>
                Available Prepaid Credits: 
                {
                  isFetching ? <CircularProgress size={'1.5rem'} style={{marginLeft: '1rem'}} /> : <strong style={{ color: '#E84125' }}> {credits.toLocaleString()}</strong>
                }
              </Typography>
            </div>
          </div>
          {
            showBuyPrepaidCredits() && <Typography color="text.secondary">
              Prepaid credits give you the flexibility to only pay for what you use.
              Unlike normal credits, prepaid credits <b>do not expire</b> at the end of the month, so you can carry them over with peace of mind!
              <br />
              {
                currentPlan !== SubscriptionPlansIds.Free && <span>Prepaid credits are <strong style={{ color: '#E84125' }}>~$15%</strong> cheaper than overages,
                For detailed pricing, please click <a href={`https://www.helius.dev/pricing`} target="_blank" style={{ color: 'blue', textDecoration: 'underline' }}> here. </a></span>
              }
            </Typography>
          }
        </div>
        {
          showBuyPrepaidCredits() && buyingType && <Grid item xs={12} sm={12} md={12}>
            {
              buyingType === 'crypto' && <CryptoPrepaidCreditsCard />
            }
            {/* {
              buyingType === 'card' && <Box component={Card} padding={4} borderRadius={2} width={1} height={1} data-aos={'fade-up'}>
                <StripePrepaidCreditsCard />
              </Box>
            } */}
          </Grid>
        }
      </Container>
    </Page>
  )
}
