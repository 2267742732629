
import { Card, CardActions, CardContent, Button, Typography, List, ListItem } from '@mui/material';

export const Banner = ({dataObj }) => {
  return (
    <Card sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100%' // This makes the card take up 100% of the grid item's height
    }}>
      <CardContent sx={{ flexGrow: 1 }} style={{
        paddingBottom: 0
      }}>
        <Typography variant={'h5'} gutterBottom sx={{ fontWeight: 500 }}>
          {dataObj.title}{' '}
        </Typography>
        <Typography color="text.secondary">
          {dataObj.desc}
        </Typography>
        <Typography sx={{ mt: 2 }} variant="subtitle1">
          Features:
        </Typography>

        <List>
          <Typography variant="body1">
            {
              dataObj.listItems.map((item, index) => <ListItem key={`item_${index}`}> - {item} </ListItem>)
            }
          </Typography>
        </List>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-center', mt: 'auto' }}>
        <div style={{
          width: '100%',
          padding: '0 20px 20px 20px'
        }}>
          <Button
            style={{ width: '100%', marginTop: '20px' }}
            href={dataObj.planLink}
            variant='contained'
          >
            Buy With Card
          </Button>
          <div style={{ width: '100%', marginTop: '10px', textAlign: 'center' }}><b>OR</b></div>
          <Button
            style={{ width: '100%', marginTop: '20px' }}
            href={dataObj.spherePlanLink}
            variant='contained'
          >
            Buy With Crypto
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};
