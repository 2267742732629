
import axios from 'axios';

const sendLog = (projectId, type, message, error, walletID) => {
  try{
    const header = { headers: { Authorization: `Bearer ${localStorage.getItem('helius-auth-token')}` } };
    axios.post(`${process.env.REACT_APP_API_CLIENT}/logger/${projectId}`, {
      type,
      message,
      data: {
        timestamp: new Date().getTime(),
        error: JSON.stringify(error.stack || error),
        walletID: walletID,
      }
    }, header).catch((error) => {
      console.error('Error sending log data:', error)
    });
  } catch(error) {
    console.error('Error sending log data:', error)
  }
}

export const info = (projectId, message, error, walletID) => {
  sendLog(projectId, 'info', message, error, walletID);
}

export const warn = (projectId, message, error, walletID) => {
  sendLog(projectId, 'warning', message, error, walletID);
}

export const error = (projectId, message, error, walletID) => {
  sendLog(projectId, 'error', message, error, walletID);
}
